import React, { useEffect, useState } from "react";
import Header from "../../../common/header";
import Breadcrumb from "../../../common/breadcrumb";
import Spinner from "../../../common/spinner";
import "../../crop/view/index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TextBox from "../../../common/textBox";
import TextArea from "../../../common/textArea";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { getVideoLocale } from "../../../components/lib/getVideoLocale";
import { GetToOrganisation } from "../../lib/getToOrganisation";

import {
  listUsageRequest,
  registerUsageRequest,
  getCategoryListRequest,
  deleteUsageRequest,
  versionListRequest,
} from "../action";

import FileType from "../../../common/fileType";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const Index = (props) => {
  var image_type = ["image/png", "image/jpeg"];
  const [modalIsOpen, setIsOpen] = React.useState(false);

  var languageListLength =
    props.language_list.length > 0 ? props.language_list.length : 1;
  var languageLength = [];

  for (let i = 0; i < languageListLength; i++) {
    languageLength.push(i);
  }

  const [category, setCategory] = useState("");
  const [title, setTitle] = useState([]);
  const [agricoachVersion, setAgricoachVersion] = useState([]);
  const [addBefore, setAddBefore] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [code, setCode] = useState("");

  const [iconError, setIconError] = useState(false);
  const [iconSizeError, setIconSizeError] = useState(false);
  const [icon, setIcon] = useState("");

  const [titleError, setTitleError] = useState(false);
  const [codeError, setCodeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [versionError, setVersionError] = useState(false);

  const [selectedUsage, setSelectedUsage] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [mediaText, setMediaText] = useState([]);

  useEffect(() => {
    setCategory("");
    setTitle([]);
    setAddBefore(0);
    setSelectedId("");
    setIcon("");
    setCode("");
    setSelectedUsage([]);
    setAgricoachVersion([]);
    setMediaText([]);
  }, [
    props.usage_list_loading,
    props.usage_register_loading,
    props.usage_delete_loading,
  ]);

  useEffect(() => {
    props.actions.listUsageRequest({ org_id: props.organisation_id });
    props.actions.getCategoryListRequest();
    props.actions.versionListRequest();
  }, []);

  useEffect(() => {
    if (selectedId === "") {
      let add = getLastId() === 1 ? 1 : Object.values(filteredData).length + 1;
      setAddBefore(add);
    }
  }, [filteredData]);

  const checkLanguageExists = (offset) => {
    return Object.values(offset).findIndex((tmp) => {
      return tmp.locale.includes(props.lang);
    });
  };

  const textChange = (name, text) => {
    switch (name) {
      case "mediaType": {
        let check = checkLanguageExists(mediaText);
        let data0 = check !== -1 ? mediaText[check] : [];

        let mediaType =
          "mediaType" in data0
            ? { mediaType: data0.mediaType }
            : { mediaType: "NULL" };
        let mediatext_id =
          "mediatext_id" in data0
            ? { mediatext_id: data0.mediatext_id }
            : { mediatext_id: "" };

        let data1 = { ...data0, ...mediaType, ...mediatext_id };

        data1[name] = text;

        let language = { locale: languageContent };

        let data2 = { ...data1, ...language };

        let data3 = {
          [check === -1 ? Object.values(Array(mediaText)[0]).length : check]:
            data2,
        };

        let data4 = { ...mediaText, ...data3 };

        setMediaText(data4);
        break;
      }
      case "category":
        let tmp_filtered_data = { ...props.usage_list };
        let tmp_data = Object.values(tmp_filtered_data).filter((tmp) => {
          return tmp.category_id == text;
        });
        setFilteredData(tmp_data);
        setCategoryError(false);
        setCategory(text);
        break;
      case "title":
        setTitleError(false);
        let data1 = { [name]: text };
        let data2 = getLanguage() in title ? title[getLanguage()] : [];
        let language = { locale: languageContent };
        let videolocale = {
          videolocale: getVideoLocale(props.language_list, languageContent),
        };

        let data3 = { ...data2, ...data1, ...language, ...videolocale };

        let language1 = Object.values(title).findIndex((tmp) => {
          return tmp.locale.includes(props.lang) === true;
        });

        if (language1 !== -1) {
          var language2 = language1;
        } else {
          var language2 = Object.values(title).length;
        }

        let data4 = { [language2]: data3 };
        let data = { ...title, ...data4 };

        setTitle(data);
        break;
      case "description":
        let tmp_data1 = { [name]: text };
        let tmp_data2 = getLanguage() in title ? title[getLanguage()] : [];
        let tmp_language = { locale: languageContent };
        let tmp_data3 = { ...tmp_data2, ...tmp_data1, ...tmp_language };

        let tmp_language1 = Object.values(title).findIndex((tmp) => {
          return tmp.locale.includes(props.lang) === true;
        });

        if (tmp_language1 !== -1) {
          var tmp_language2 = tmp_language1;
        } else {
          var tmp_language2 = Object.values(title).length;
        }

        let tmp_data4 = { [tmp_language2]: tmp_data3 };
        let tmp1_data = { ...title, ...tmp_data4 };

        setTitle(tmp1_data);
        break;
      case "version":
        setVersionError(false);
        if (agricoachVersion.includes(text) === false) {
          let tmp_version = agricoachVersion;
          tmp_version.push(text);
          setAgricoachVersion(tmp_version);
        } else {
          let tmp_version = agricoachVersion;
          let index = agricoachVersion.findIndex((tmp) => {
            return tmp === text;
          });
          if (index !== -1) {
            tmp_version.splice(index, 1);
            setAgricoachVersion(tmp_version);
          }
        }
        break;
      case "code":
        setCodeError(false);
        setCode(text);
        break;
      case "add_before":
        setAddBefore(text);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    if (image_type.includes(event.target.files[0].type) === true) {
      setIconError(false);
      if (event.target.files[0].size <= 100000) {
        setIconSizeError(false);
        toDataURL(
          URL.createObjectURL(event.target.files[0]),
          function (dataUrl) {
            let temp_data_url = dataUrl.split(",");
            setIcon(temp_data_url[1]);
          }
        );
      } else {
        setIconSizeError(true);
        setIcon("");
      }
    } else {
      setIconSizeError(true);
      setIcon("");
    }
  };

  const toDataURL = (src, callback, outputFormat) => {
    let image = new Image();
    image.crossOrigin = "Anonymous";
    image.onload = function () {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    image.src = src;
    if (image.complete || image.complete === undefined) {
      image.src =
        "data:image/gif;base64, R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      image.src = src;
    }
  };

  const getMediaTypeFromLocale = (locale) => {
    if (Object.values(mediaText).length > 0) {
      let index = Object.keys(mediaText).findIndex((t) => {
        return mediaText[t].locale === locale;
      });

      if (index != -1) return mediaText[index].mediaType;
      else return null;
    } else {
      return null;
    }
  };

  const onSaveButtonClick = () => {
    setCodeError(false);
    setTitleError(false);
    setCategoryError(false);
    setVersionError(false);

    if (code === "") {
      setCodeError(true);
    }
    if (Object.values(title).length === 0) {
      setTitleError(true);
    }
    if (category === "" || category === "Choose") {
      setCategoryError(true);
    }

    if (Object.values(title).length > 0 && category !== "") {
      let tmp_title = [];

      Object.values(title).map((tmp) => {
        if ("description" in tmp === true) {
          tmp_title.push(tmp);
        } else {
          let tmp_description = { description: "" };
          let tmp_data = { ...tmp_description, ...tmp };
          tmp_title.push(tmp_data);
        }
      });

      var add_version = [];
      var delete_version = [];

      if (selectedId === "") {
        add_version = agricoachVersion;
      } else {
        if ("version" in selectedUsage) {
          var diff = selectedUsage.version.filter(
            (x) => !agricoachVersion.includes(x)
          );
          delete_version = diff;

          let add = agricoachVersion.filter(
            (x) => !selectedUsage.version.includes(x)
          );
          add_version = add;
        } else {
          add_version = agricoachVersion;
        }
      }

      let tmp_usage = [];
      if (tmp_title.length > 0) {
        tmp_title.map((tmp) => {
          tmp_usage.push({
            ...tmp,
            ...{ media_type: getMediaTypeFromLocale(tmp.locale) },
          });
        });
      }

      let data = {
        org_id: props.organisation_id,
        id: selectedId !== "" ? selectedId : "",
        add_version: add_version,
        delete_version: delete_version,
        category_id: category,
        icon: icon,
        sorting: addBefore,
        code: code,
        usageText: JSON.stringify(tmp_usage),
      };

      if (add_version.length === 0 && selectedId === "") {
        setVersionError(true);
      } else {
        props.actions.registerUsageRequest(data);
      }
    }
  };

  const getLanguage = () => {
    let data = Object.values(title).findIndex((data) => {
      return data.locale === languageContent;
    });
    return data;
  };

  const getLanguage1 = () => {
    let data = Object.values(title).findIndex((data) => {
      return data.locale === "en_EN";
    });
    return data;
  };

  const getLangContent = (language) => {
    let data = props.language_list.findIndex((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data !== -1) {
      return data;
    } else {
      return 0;
    }
  };

  const getLanguageContent = (language) => {
    let data = props.language_list.filter((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data.length > 0) {
      return data[0].locale;
    } else {
      return "en_EN";
    }
  };

  var langContent = getLangContent(props.lang);
  var languageContent = getLanguageContent(props.lang);

  const getLastId = () => {
    if (Object.keys(props.usage_list).length === 0) {
      return 1;
    } else {
      return Object.keys(props.usage_list).length;
    }
  };

  const getUsageLanguage = (text) => {
    if (Object.values(text).length > 0) {
      let data = Object.values(text).findIndex((tmp) => {
        return tmp.locale.includes(props.lang) === true;
      });
      return data;
    } else {
      return -1;
    }
  };

  const getEnglishUsageLanguage = (text) => {
    if (Object.values(text).length > 0) {
      let data = Object.values(text).findIndex((tmp) => {
        return tmp.locale.includes("en_EN") === true;
      });
      return data;
    } else {
      return -1;
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  var usage_list = [];

  const countOccurnce = (id) => {
    return usage_list.reduce((a, v) => (v === id ? a + 1 : a), 0);
  };

  const getCategoryNameFromUsageList = (id) => {
    let data = Object.values(props.category_list).filter((tmp) => {
      return tmp.id === id;
    });
    if (data.length > 0) {
      return data[0].name;
    }
  };

  const getCategoryImageFromUsageList = (id) => {
    let data = Object.values(props.category_list).filter((tmp) => {
      return tmp.id === id;
    });
    if (data.length > 0) {
      return data[0].category_icon;
    }
  };

  const convertVersionName = (version) => {
    let data = version.split(".");
    let final_data =
      data[0].charAt(0).toUpperCase() +
      data[0].slice(1) +
      " " +
      data[1].charAt(0).toUpperCase();
    return final_data;
  };

  let to_org = GetToOrganisation(props.mapping_list, props.auth);

  const renderModal = () => {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          closeModal();
        }}
        style={customStyles}
      >
        <h4>
          Do you really want to delete{" "}
          {getUsageLanguage(selectedUsage.usagetext) !== -1
            ? selectedUsage.usagetext[getUsageLanguage(selectedUsage.usagetext)]
                .title
            : "selected usage"}{" "}
          ?
        </h4>
        <div className="modalBox">
          <div className="leftModal">
            <div
              className="modalButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.actions.deleteUsageRequest({
                  id: selectedUsage.id,
                  org_id: props.organisation_id,
                });
                closeModal();
              }}
            >
              Yes
            </div>
          </div>

          <div className="rightModal">
            <div
              className="modalButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeModal();
              }}
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const getMediaContextData = () => {
    let index = Object.values(mediaText).findIndex((tmp) => {
      return tmp.locale.includes(props.lang);
    });

    return index;
  };

  const renderData = () => {
    return (
      <div>
        <div className="TextBoxContainer">
          <div className="leftSelectLabelContainer1">
            <span>Category</span>
          </div>

          <div className="rightSelectLabelContainer1">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {categoryError === true && (
                <span className="errorText">{"Category is required."}</span>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  value={category}
                  id="category"
                  className="selectInput"
                  name="category"
                  onChange={(e) => {
                    textChange("category", e.target.value);
                  }}
                >
                  <option value={"Choose"}>{"Choose"}</option>
                  {props.category_list !== undefined &&
                    Object.values(props.category_list).length > 0 &&
                    props.category_list.map((tmp, key) => {
                      return (
                        <option key={key} value={tmp.id}>
                          {tmp.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>

        {languageLength.map((lang, key) => {
          return (
            langContent === lang && (
              <div key={key}>
                {Object.values(selectedUsage).length > 0 ? (
                  <TextBox
                    label="Title"
                    name="title"
                    id="title"
                    onTextChange={textChange}
                    value={
                      getLanguage() in title === true
                        ? title[getLanguage()].title
                        : undefined
                    }
                    value1={
                      languageContent !== "en_EN"
                        ? getLanguage1() in title
                          ? title[getLanguage1()].title
                          : undefined
                        : undefined
                    }
                  />
                ) : (
                  <TextBox
                    label="Title"
                    name="title"
                    id="title"
                    onTextChange={textChange}
                    value={
                      getLanguage() in title
                        ? title[getLanguage()].title
                        : undefined
                    }
                    value1={
                      languageContent !== "en_EN"
                        ? getLanguage1() in title
                          ? title[getLanguage1()].title
                          : undefined
                        : undefined
                    }
                    error={titleError === true ? "Title is required." : ""}
                  />
                )}
              </div>
            )
          );
        })}

        {languageLength.map((lang, key) => {
          return (
            langContent === lang && (
              <div key={key}>
                {Object.values(selectedUsage).length > 0 ? (
                  <TextArea
                    label="Description"
                    name="description"
                    id="description"
                    onTextChange={textChange}
                    value={
                      getLanguage() in title === true
                        ? title[getLanguage()].description
                        : undefined
                    }
                    value1={
                      languageContent !== "en_EN"
                        ? getLanguage1() in title
                          ? title[getLanguage1()].description
                          : undefined
                        : undefined
                    }
                  />
                ) : (
                  <TextArea
                    label="Description"
                    name="description"
                    id="description"
                    onTextChange={textChange}
                    value={
                      getLanguage() in title
                        ? title[getLanguage()].description
                        : undefined
                    }
                    value1={
                      languageContent !== "en_EN"
                        ? getLanguage1() in title
                          ? title[getLanguage1()].description
                          : undefined
                        : undefined
                    }
                  />
                )}
              </div>
            )
          );
        })}

        <div className="TextBoxContainer">
          <div className="leftSelectLabelContainer1">
            <span>Versions</span>
          </div>

          <div className="rightSelectLabelContainer1">
            <div style={{ display: "flex", flexDirection: "column" }}>
              {versionError === true && (
                <span className="errorText">
                  {"One of the version needs to be selected."}
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {Object.values(props.version_list).length &&
                  Object.values(props.version_list).map((version, key) => {
                    return agricoachVersion.includes(version.version) ===
                      true ? (
                      <label className="container" key={key}>
                        {convertVersionName(version.version)}
                        <input
                          id={"version" + key}
                          onChange={(e) => textChange("version", e.target.name)}
                          type="checkbox"
                          name={version.version}
                          value={version.version}
                          defaultChecked={true}
                        />
                        <span className="checkmark"></span>
                      </label>
                    ) : (
                      <label className="container" key={key}>
                        <input
                          id={"version" + key}
                          type="checkbox"
                          name={version.version}
                          value={version.version}
                          onChange={(e) => textChange("version", e.target.name)}
                        />
                        <span className="checkmark"></span>
                        {convertVersionName(version.version)}
                      </label>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>

        <div className="leftFormCard">
          <FileType
            label={"Icon"}
            handleChange={handleChange}
            error={
              iconSizeError === true
                ? "Maximum limit size exceeds 100 KB"
                : undefined
            }
            error1={
              iconError === true
                ? ".jpeg and .png file type are only allowed."
                : undefined
            }
            file={
              Object.keys(selectedUsage).length > 0
                ? icon === ""
                  ? selectedUsage.icon !== null &&
                    selectedUsage.icon !== "" &&
                    selectedUsage.icon
                  : icon
                : icon
            }
          />
        </div>

        <div className="TextBoxContainer">
          <div className="leftSelectLabelContainer1">
            <span>Add before</span>
          </div>

          <div className="rightSelectLabelContainer1">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  value={addBefore}
                  id="add_before"
                  className="selectInput"
                  name="add_before"
                  onChange={(e) => {
                    textChange("add_before", e.target.value);
                  }}
                >
                  {Object.values(filteredData).map((tmp, key) => {
                    return (
                      <option key={key} value={tmp.sorting}>
                        {getUsageLanguage(tmp.usagetext) !== -1
                          ? tmp.usagetext[getUsageLanguage(tmp.usagetext)].title
                          : getEnglishUsageLanguage(tmp.usagetext) !== -1
                          ? tmp.usagetext[
                              getEnglishUsageLanguage(tmp.usagetext)
                            ].title
                          : ""}
                      </option>
                    );
                  })}
                  <option
                    value={
                      getLastId() === 1
                        ? 0
                        : Object.values(filteredData).length + 1
                    }
                  >
                    {"Last"}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <TextBox
          label="Code"
          name="code"
          id="code"
          value={code}
          error={codeError === true && "Code is required."}
          onTextChange={textChange}
        />

        <TextBox
          id="thumbnail_link"
          name="thumbnail_link"
          label="Thumbnail Link"
          disabled={true}
          value={
            Object.values(selectedUsage).length > 0
              ? selectedUsage.thumbnail_link
              : ""
          }
        />

        <TextBox
          id="video_link"
          name="video_link"
          label="Video Link"
          onTextChange={textChange}
          disabled={true}
          value={
            Object.values(selectedUsage).length > 0
              ? getUsageLanguage(selectedUsage.usagetext) !== -1
                ? selectedUsage.usagetext[
                    getUsageLanguage(selectedUsage.usagetext)
                  ].videolink
                : ""
              : ""
          }
        />

        {languageLength.map((lang, key) => {
          return (
            langContent === lang && (
              <div key={key}>
                {
                  <div className="TextBoxContainer">
                    <div className="leftSelectLabelContainer1">
                      <span>Media Type</span>
                    </div>

                    <div className="rightSelectLabelContainer1">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <select
                          className="selectInput"
                          value={
                            Object.values(mediaText).length > 0
                              ? mediaText[getMediaContextData()] !== undefined
                                ? mediaText[getMediaContextData()].mediaType !==
                                    "" &&
                                  mediaText[getMediaContextData()].mediaType !==
                                    null
                                  ? mediaText[getMediaContextData()].mediaType
                                  : "NULL"
                                : "NULL"
                              : "NULL"
                          }
                          value1={
                            mediaText.length > 0
                              ? mediaText[getMediaContextData()] !== undefined
                                ? mediaText[getMediaContextData()].mediaType !==
                                    "" &&
                                  mediaText[getMediaContextData()].mediaType !==
                                    null
                                  ? mediaText[getMediaContextData()].mediaType
                                  : "NULL"
                                : "NULL"
                              : "NULL"
                          }
                          name="mediaType"
                          onChange={(e) => {
                            textChange(e.target.name, e.target.value);
                          }}
                        >
                          <option value={"image/jpeg"}>{`image/jpeg`}</option>
                          <option value={"video/mp4"}>{`video/mp4`}</option>
                          <option value={"NULL"}>{`NULL`}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )
          );
        })}
      </div>
    );
  };

  return (
    <div className="characteristicsContainer">
      <Header header={false} />

      <Breadcrumb
        save={
          props.user_role_type !== "Viewer" && props.organisation === to_org
            ? true
            : props.user_role_type !== "Viewer" &&
              props.umva_id === props.superuser
            ? true
            : false
        }
        onSaveButtonClick={onSaveButtonClick}
      />

      <div className="generalBody" style={{ flexDirection: "column" }}>
        <ToastContainer />

        {props.usage_list_loading === true ||
        props.usage_register_loading === true ||
        props.usage_delete_loading === true ? (
          <Spinner />
        ) : (
          <div
            className="formCard"
            style={{ flexDirection: "column", flex: 1 }}
          >
            {
              <div style={{ flexDirection: "row", flex: 1 }}>
                {props.user_role_type !== "Viewer" &&
                props.organisation === to_org
                  ? renderData()
                  : props.user_role_type !== "Viewer" &&
                    props.umva_id === props.superuser &&
                    renderData()}
              </div>
            }

            <table
              style={{ width: "100%" }}
              className="tableContainer"
              cellSpacing="0"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Title</th>
                  <th>Sorting</th>
                  <th>Versions</th>
                  <th>Thumbnail Link</th>
                  <th>Video Link</th>
                  {props.user_role_type !== "Viewer" &&
                  props.organisation === to_org ? (
                    <>
                      <th>
                        <span>Edit</span>
                      </th>
                      <th>
                        <span>Delete</span>
                      </th>
                    </>
                  ) : (
                    props.user_role_type !== "Viewer" &&
                    props.umva_id === props.superuser && (
                      <>
                        <th>
                          <span>Edit</span>
                        </th>
                        <th>
                          <span>Delete</span>
                        </th>
                      </>
                    )
                  )}
                </tr>
              </thead>
              {props.usage_list.length > 0 &&
                props.usage_list.map((usage, key) => {
                  usage_list.push(usage.category_id);
                  return (
                    <tbody
                      key={key}
                      className={
                        Object.values(selectedUsage).length > 0
                          ? usage.id === selectedUsage.id
                            ? "selectedRow"
                            : ""
                          : ""
                      }
                    >
                      {countOccurnce(usage.category_id) === 1 && (
                        <tr className="activityName">
                          <td colSpan={8}>
                            {["null", "", null].includes(
                              getCategoryImageFromUsageList(usage.category_id)
                            ) === false ? (
                              <img
                                src={`data:image/png;base64, ${getCategoryImageFromUsageList(
                                  usage.category_id
                                )}`}
                                alt="icon"
                                className="headerImage"
                                style={{
                                  backgroundColor: "#4ab575",
                                  objectFit: "scale-down",
                                }}
                              />
                            ) : (
                              <div
                                className="headerImage"
                                style={{ alignItems: "center" }}
                              ></div>
                            )}
                            {
                              <span
                                style={{
                                  position: "absolute",
                                  marginTop: 10,
                                  paddingLeft: 10,
                                }}
                              >
                                {getCategoryNameFromUsageList(
                                  usage.category_id
                                )}
                              </span>
                            }
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          {"icon" in usage === true &&
                          usage.icon !== null &&
                          usage.icon !== "" ? (
                            <img
                              src={`data:image/png;base64, ${usage.icon}`}
                              alt="icon"
                              className="headerImage"
                            />
                          ) : (
                            <div
                              className="headerImage"
                              style={{ alignItems: "center" }}
                            ></div>
                          )}
                        </td>
                        <td>
                          {getUsageLanguage(usage.usagetext) !== -1
                            ? usage.usagetext[getUsageLanguage(usage.usagetext)]
                                .title
                            : ""}
                        </td>
                        <td>{usage.sorting}</td>
                        <td
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {["null", undefined, "", null].includes(
                            usage.version
                          ) === false &&
                            usage.version.length > 0 &&
                            usage.version.map((tmp, key) => {
                              return (
                                <span key={key}>
                                  {convertVersionName(tmp)}
                                  {key !== usage.version.length - 1 ? ", " : ""}
                                </span>
                              );
                            })}
                        </td>
                        <td>{usage.thumbnail_link}</td>
                        <td>
                          {getUsageLanguage(usage.usagetext) !== -1
                            ? usage.usagetext[getUsageLanguage(usage.usagetext)]
                                .videolink
                            : ""}
                        </td>
                        {props.user_role_type !== "Viewer" &&
                        props.organisation === to_org ? (
                          <>
                            <td
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={require("../../../images/edit.png")}
                                alt="edit"
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  document.getElementById("title").value = "";
                                  document.getElementById("title").value =
                                    [null, "", undefined, "null"].includes(
                                      usage.usagetext[
                                        getUsageLanguage(usage.usagetext)
                                      ]
                                    ) === false
                                      ? usage.usagetext[
                                          getUsageLanguage(usage.usagetext)
                                        ].title
                                      : "";

                                  document.getElementById("description").value =
                                    "";
                                  document.getElementById("description").value =
                                    [null, "", undefined, "null"].includes(
                                      usage.usagetext[
                                        getUsageLanguage(usage.usagetext)
                                      ]
                                    ) === false
                                      ? usage.usagetext[
                                          getUsageLanguage(usage.usagetext)
                                        ].description
                                      : "";

                                  document.getElementById("code").value = "";
                                  document.getElementById("code").value =
                                    usage.code;

                                  let temp_usage_version =
                                    [null, "", undefined, "null"].includes(
                                      usage.version
                                    ) === false
                                      ? [...usage.version]
                                      : [];
                                  let tmp_filtered_data = props.usage_list;
                                  let tmp_data = Object.values(
                                    tmp_filtered_data
                                  ).filter((tmp) => {
                                    return (
                                      tmp.category_id === usage.category_id
                                    );
                                  });

                                  let tmp_media_text = [];
                                  usage.usagetext.map((tmp) => {
                                    if (
                                      ![null, undefined, ""].includes(
                                        tmp.media_type
                                      )
                                    ) {
                                      tmp_media_text.push({
                                        mediatext_id: "",
                                        mediaType: tmp.media_type,
                                        locale: tmp.locale,
                                      });
                                    }
                                  });
                                  setMediaText(tmp_media_text);
                                  setFilteredData(tmp_data);

                                  setCode(usage.code);
                                  setCategory(usage.category_id);
                                  setTitle(usage.usagetext);
                                  setAddBefore(usage.sorting);
                                  setSelectedUsage(usage);
                                  setSelectedId(usage.id);
                                  setIcon(usage.icon);

                                  setAgricoachVersion([...temp_usage_version]);
                                  window.scrollTo(0, 0);
                                }}
                              />
                            </td>
                            <td
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={require("../../../images/delete.png")}
                                alt="delete"
                                style={{
                                  width: 20,
                                  height: 20,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  openModal();
                                  setSelectedUsage(usage);
                                }}
                              />
                            </td>
                          </>
                        ) : (
                          props.user_role_type !== "Viewer" &&
                          props.umva_id === props.superuser && (
                            <>
                              <td
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={require("../../../images/edit.png")}
                                  alt="edit"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    document.getElementById("title").value = "";
                                    document.getElementById("title").value =
                                      [null, "", undefined, "null"].includes(
                                        usage.usagetext[
                                          getUsageLanguage(usage.usagetext)
                                        ]
                                      ) === false
                                        ? usage.usagetext[
                                            getUsageLanguage(usage.usagetext)
                                          ].title
                                        : "";

                                    document.getElementById(
                                      "description"
                                    ).value = "";
                                    document.getElementById(
                                      "description"
                                    ).value =
                                      [null, "", undefined, "null"].includes(
                                        usage.usagetext[
                                          getUsageLanguage(usage.usagetext)
                                        ]
                                      ) === false
                                        ? usage.usagetext[
                                            getUsageLanguage(usage.usagetext)
                                          ].description
                                        : "";

                                    document.getElementById("code").value = "";
                                    document.getElementById("code").value =
                                      usage.code;

                                    let temp_usage_version =
                                      [null, "", undefined, "null"].includes(
                                        usage.version
                                      ) === false
                                        ? [...usage.version]
                                        : [];
                                    let tmp_filtered_data = props.usage_list;
                                    let tmp_data = Object.values(
                                      tmp_filtered_data
                                    ).filter((tmp) => {
                                      return (
                                        tmp.category_id === usage.category_id
                                      );
                                    });
                                    setFilteredData(tmp_data);

                                    let tmp_media_text = [];
                                    usage.usagetext.map((tmp) => {
                                      if (
                                        ![null, undefined, ""].includes(
                                          tmp.media_type
                                        )
                                      ) {
                                        tmp_media_text.push({
                                          mediatext_id: "",
                                          mediaType: tmp.media_type,
                                          locale: tmp.locale,
                                        });
                                      }
                                    });
                                    setMediaText(tmp_media_text);

                                    setCode(usage.code);
                                    setCategory(usage.category_id);
                                    setTitle(usage.usagetext);
                                    setAddBefore(usage.sorting);
                                    setSelectedUsage(usage);
                                    setSelectedId(usage.id);
                                    setIcon(usage.icon);

                                    setAgricoachVersion([
                                      ...temp_usage_version,
                                    ]);
                                    window.scrollTo(0, 0);
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={require("../../../images/delete.png")}
                                  alt="delete"
                                  style={{
                                    width: 20,
                                    height: 20,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    openModal();
                                    setSelectedUsage(usage);
                                  }}
                                />
                              </td>
                            </>
                          )
                        )}
                      </tr>
                      {getUsageLanguage(usage.usagetext) !== -1 &&
                        ["", null, undefined].includes(
                          usage.usagetext[getUsageLanguage(usage.usagetext)]
                            .description
                        ) === false && (
                          <tr className="activityName">
                            <td colSpan={8} style={{ textAlign: "center" }}>
                              {
                                <span>
                                  {getUsageLanguage(usage.usagetext) !== -1
                                    ? usage.usagetext[
                                        getUsageLanguage(usage.usagetext)
                                      ].description
                                    : ""}
                                </span>
                              }
                            </td>
                          </tr>
                        )}
                    </tbody>
                  );
                })}
            </table>
          </div>
        )}
      </div>

      {props.user_role_type !== "Viewer" && props.organisation === to_org
        ? Object.values(selectedUsage).length > 0 && renderModal()
        : props.user_role_type !== "Viewer" &&
          props.umva_id === props.superuser &&
          Object.values(selectedUsage).length > 0 &&
          renderModal()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation_id: state.auth.uid,
    lang: state.lang.language,
    language_list: state.lang.language_list,
    usage_list_loading: state.usage.usage_list_loading,
    usage_register_loading: state.usage.usage_register_loading,
    usage_list: state.usage.usage_list,
    category_list: state.usage.category_list,
    usage_delete_loading: state.usage.usage_delete_loading,
    version_list: state.usage.version_list,
    user_role_type: state.auth.user_role_type,
    mapping_list: state.organization.mapping_list,
    auth: state.auth.organization,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    umva_id: state.auth.umva_id,
  };
};

function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        listUsageRequest,
        registerUsageRequest,
        getCategoryListRequest,
        deleteUsageRequest,
        versionListRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Index);
