import React, { useEffect, useState } from "react";
import Header from "../../../common/header";
import Breadcrumb from "../../../common/breadcrumb";
import "./index.css";
import { cropListRequest } from "../action";
import { seasonListRequest, unitConfigRequest } from "../../season/action";
import { growAreaListRequest } from "../../growArea/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CropContainer from "../view/crop/cropContainer";
import Spinner from "../../../common/spinner";
import { removeSelectedGenericActivity } from "./activity/action";

const Index = (props) => {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [cropImageVisibility, setCropImageVisibility] = useState(false);

  useEffect(() => {
    getUnitConfig();
    if (props.crop_list.length === 0) {
      if (Object.values(props.organization_list).length > 0) {
        let index = props.organization_list.findIndex((tmp) => {
          return tmp.organisation === props.organization;
        });

        let params = {
          organisation: props.organization,
          crop_id: props.selected_crop_id,
        };
        if (index !== -1) {
          const { latitude, longitude } = props.organization_list[index];

          if (latitude == null && longitude == null) {
            params = {
              ...params,
              ...{ latitude: -3.3731, longitude: 29.9189 },
            };
          } else {
            params = {
              ...params,
              ...{ latitude: latitude, longitude: longitude },
            };
          }
        } else {
          params = { ...params, ...{ latitude: -3.3731, longitude: 29.9189 } };
        }

        props.actions.growAreaListRequest({ organisation: props.organization });
        props.actions.seasonListRequest(params);

        props.actions.cropListRequest({ org_name: props.organization });
      }
    }
  }, [props.organization_list_loading]);

  useEffect(() => {
    props.actions.removeSelectedGenericActivity();
  }, []);

  const getUnitConfig = async () => {
    let country = localStorage.getItem("country");
    props.actions.unitConfigRequest({ country: country.toUpperCase() });
  };

  const displayCropImageClick = () => {
    setCropImageVisibility(!cropImageVisibility);
  };

  useEffect(() => {
    if (
      Object.values(props.grow_area_list).length !== 0 &&
      Object.values(props.season_list).length !== 0
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [props.grow_area_list, props.season_list]);

  return (
    <div>
      <Header header={true} displayLanguage={true} modalIsOpen={modalIsOpen} />

      <Breadcrumb
        displayCropImage={true}
        cropImageVisibility={cropImageVisibility}
        onDisplayCropImageClick={displayCropImageClick}
      />

      <div className="cropContainer">
        <div className="cropBodyContainer">
          {!props.grow_area_list_loading && !props.season_list_loading ? (
            <>
              <div>
                <span>Generic Crop</span>
              </div>

              <>
                <div className="cropBody">
                  <CropContainer generic={false} />
                </div>

                <div>
                  <span>Specific Crop</span>
                </div>

                {!modalIsOpen ? (
                  <div className="cropBody">
                    <h2>Please add Season and Grow Area.</h2>
                  </div>
                ) : (
                  <div className="cropBody">
                    {props.loading ||
                    props.selected_crop_detail_loading ||
                    props.publish_unpublish_loading ? (
                      <Spinner />
                    ) : (
                      props.crop_list.length > 0 &&
                      props.crop_list.map((crop, key) => {
                        return (
                          <CropContainer
                            generic={true}
                            key={key}
                            data={crop}
                            cropImageVisibility={cropImageVisibility}
                          />
                        );
                      })
                    )}
                  </div>
                )}
              </>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.auth.error,
    crop_list: state.crop.crop_list,
    loading: state.crop.loading,
    selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
    publish_unpublish_loading: state.crop.publish_unpublish_loading,
    organization: state.auth.organization,
    organization_list_loading: state.organization.organization_list_loading,
    organization_list: state.organization.organization_list,
    selected_crop_id: state.crop.selected_crop_id,
    grow_area_list: state.growArea.grow_area_list,
    season_list: state.season.season_list,
    grow_area_list_loading: state.growArea.grow_area_list_loading,
    season_list_loading: state.season.season_list_loading,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cropListRequest,
        seasonListRequest,
        growAreaListRequest,
        removeSelectedGenericActivity,
        unitConfigRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Index);
