import Api from "../../config/api";
import errorParse from "../lib/error";
import axios from "axios";

function baseCropRequest() {
  const url = process.env.REACT_APP_NEPAL_AGRICOACH_BASE_URL + "/cropList";
  const token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: token ? `Bearer ${token}` : "",
    "x-country": "bi",
  };

  const res = axios
    .get(url, { headers: headers })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });

  return res;

  // const res = Api.get('/cropList').then((response)=>{
  //     let data = response.data;
  //     return data;
  // }).catch((error)=>{
  //     return errorParse(error);
  // });
  // return res;
}

function cropRequest(params) {
  const res = Api.get("/cropListByOrg", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropDetailRequest(params) {
  const res = Api.get("/specificCrop", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function seasonListRequest(params) {
  const res = Api.get("/seasonList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function growAreaRequest(params) {
  const res = Api.get("/growAreaList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function pestDiseasesListRequest(params) {
  const res = Api.get("/cropPestDiseaseList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropMediaListRequest(params) {
  const res = Api.get("/cropMediaList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function pestDiseasesMediaListRequest(params) {
  const res = Api.get("/pestMediaList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropRegister(params) {
  const res = Api.post("/cropData", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function indicatorRegister(params) {
  const res = Api.post("/cropIndicators", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function characteristicsRegister(params) {
  const res = Api.post("/cropCharacteristics ", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function deleteCropMedia(params) {
  const res = Api.get("/deleteselectedMedia", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function deleteVarietyMedia(params) {
  const res = Api.get("/deleteCropVariety", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropDescriptionRegister(params) {
  const res = Api.post("/cropGeneral", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function registerVariety(params) {
  const res = Api.post("/cropVariety", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function registerDescriptionMedia(params) {
  const res = Api.post("/cropMedia", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function registerPestDiseasesMedia(params) {
  const res = Api.post("/pestMedia", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function registerPestDiseases(params) {
  const res = Api.post("/cropPestDisease", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function registerFertilizer(params) {
  const res = Api.post("/cropFertilizerData", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function deletePest(params) {
  const res = Api.get("/deletePest", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function deleteFertilizer(params) {
  const res = Api.get("/deleteFertilizer", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropPublishUnPublish(params) {
  const res = Api.get("/status", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

function cropSettings(params) {
  const res = Api.get("/cropSettings", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
}

const varietyMediaList = (params) => {
  const res = Api.get("/varietyMediaList", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const deleteVarietyMediaData = (params) => {
  const res = Api.get("/deleteVarietyMedia", { params })
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

const addVarietyMedia = (params) => {
  const res = Api.post("/addVarietyMedia", params)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      return errorParse(error);
    });
  return res;
};

export const CropService = {
  cropRequest,
  cropDetailRequest,
  seasonListRequest,
  growAreaRequest,
  pestDiseasesListRequest,
  cropMediaListRequest,
  pestDiseasesMediaListRequest,
  cropRegister,
  indicatorRegister,
  characteristicsRegister,
  deleteCropMedia,
  deleteVarietyMedia,
  cropDescriptionRegister,
  registerVariety,
  registerDescriptionMedia,
  registerPestDiseasesMedia,
  registerPestDiseases,
  registerFertilizer,
  deletePest,
  deleteFertilizer,
  cropPublishUnPublish,
  cropSettings,
  baseCropRequest,
  varietyMediaList,
  deleteVarietyMediaData,
  addVarietyMedia,
};
