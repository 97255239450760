import CropActionTypes from "./actionTypes";

import { select, fork, take, call, put, delay } from "redux-saga/effects";

import { CropService } from "./service";

import history from "../../config/history";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getCrop = (state) => state.crop;
const getOrganisation = (state) => state.auth;
const seasonList = (state) => state.season;

export function* cropRequest() {
  while (true) {
    const { organization } = yield take(CropActionTypes.CROP_REQUEST);
    let data1 = {};

    if (organization.org_name === "") {
      organization.org_name = JSON.parse(
        localStorage.getItem("user")
      ).organization;
    }
    data1.organisation = JSON.parse(localStorage.getItem("user")).organization;

    var crop = yield call(CropService.cropRequest, {
      org_name: organization.org_name,
    });

    //var season_list = yield call(CropService.seasonListRequest, data1);

    //var grow_area_list = yield call(CropService.growAreaRequest, data1);

    if (crop) {
      yield put({
        type: CropActionTypes.CROP_SUCCESS,
        crop_list: crop,
        //season_list: season_list,
        //grow_area_list: grow_area_list,
        loading: false,
      });
    } else {
      yield put({
        type: CropActionTypes.CROP_FAILURE,
      });
    }
  }
}

export function* baseOrgCropList() {
  while (true) {
    yield take(CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_REQUEST);

    //var crop = yield call(CropService.baseCropRequest, {"org_name": 'Auxfin Burundi'});
    var crop = yield call(CropService.baseCropRequest);

    if (crop) {
      yield put({
        type: CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_SUCCESS,
        crop_list: crop,
      });
    } else {
      yield put({
        type: CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_SUCCESS,
        crop_list: [],
      });
    }
  }
}

export function* particularCropDetailRequest() {
  while (true) {
    const { params } = yield take(
      CropActionTypes.PARTICULAR_CROP_DETAIL_REQUEST
    );

    let allSeasonList = yield select(seasonList);
    // let org = yield select(getOrganisation);
    // let { season_list } = allSeasonList;

    if (params.crop_id === 0) {
      yield put({
        type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
        selected_crop_detail: [],
        selected_crop_id: params.crop_id,
      });

      history.push(`/crop/activity_media`);
    } else {
      var crop_detail = yield call(CropService.cropDetailRequest, params);

      if ("error" in crop_detail === false) {
        if (crop_detail.length > 0) {
          // let diff = getDifference(season_list, crop_detail[0].crop_season, "key", "season_key");
          // let diff_season = [];

          // if(diff.length > 0){
          //     diff.map((tmp)=>{
          //         diff_season.push({
          //             crop_id: params.crop_id,
          //             cropseason_id: "",
          //             defaultPlantDate: "",
          //             org_id: org.uid,
          //             season_key: tmp.key,
          //             sorting: 0,
          //             suitability: "0",
          //         })
          //     })
          // }

          // crop_detail[0].crop_season = [...crop_detail[0].crop_season, ...diff_season];

          if ("crop_season" in crop_detail[0]) {
            crop_detail[0].crop_season.sort((a, b) =>
              a.season_key > b.season_key ? 1 : -1
            );
          }
        }

        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: crop_detail,
          selected_crop_id: params.crop_id,
        });
      } else {
        toast.success(crop_detail.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: [],
          selected_crop_id: params.crop_id,
        });
      }

      history.push(`/crop/detail`);
    }
  }
}

export function* registerDescription() {
  while (true) {
    const { data } = yield take(CropActionTypes.REGISTER_DESCRIPTION_REQUEST);

    let crop_general_data = [];
    Object.values(data.cropGeneral).map((data) => {
      crop_general_data.push(data);
    });

    let data1 = {
      crop_id: data.crop_id,
      cropGeneral: JSON.stringify(crop_general_data),
      organisation: data.organisation,
    };

    var crop_general = yield call(
      CropService.cropDescriptionRegister,
      JSON.stringify(data1)
    );

    if (crop_general === "Data has been saved") {
      yield put({
        type: CropActionTypes.REGISTER_DESCRIPTION_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      if (selected_crop_id) {
        toast.success("Data has been added/updated successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop_id,
          selected_crop_id: crop_id.selected_crop_id,
        });
      }
    } else {
      yield put({
        type: CropActionTypes.REGISTER_DESCRIPTION_FAILURE,
      });
    }
  }
}

export function* registerDescriptionMedia() {
  while (true) {
    const { data } = yield take(
      CropActionTypes.REGISTER_DESCRIPTION_MEDIA_REQUEST
    );

    let media_text = [];
    Object.values(data.mediaText).map((data) => {
      media_text.push({ ...data });
    });
    var data1 = {
      media_id: data.media_id,
      crop_id: data.crop_id,
      sorting: data.sorting,
      code: data.code,
      mediaText: JSON.stringify(media_text),
      organisation: data.organisation,
    };

    var register_description = yield call(
      CropService.registerDescriptionMedia,
      JSON.stringify(data1)
    );

    if (register_description === "Crop Media Saved") {
      yield put({
        type: CropActionTypes.REGISTER_DESCRIPTION_MEDIA_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      var media_list = yield call(CropService.cropMediaListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      toast.success("Data has been added/updated successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
      yield put({
        type: CropActionTypes.CROP_MEDIA_LIST_SUCCESS,
        media_list: media_list,
      });
    } else {
      yield put({
        type: CropActionTypes.REGISTER_DESCRIPTION_MEDIA_FAILURE,
      });
    }
  }
}

export function* registerCharacteristics() {
  while (true) {
    const { data } = yield take(
      CropActionTypes.REGISTER_CHARACTERISTICS_DATA_REQUEST
    );

    let crop_text = [];
    var unit_crop = "";
    Object.values(data.cropText).map((data) => {
      if ("of_unit_pocket" in data === false) {
        let data1 = { of_unit_pocket: unit_crop };
        crop_text.push({ ...data, ...data1 });
      } else {
        unit_crop = data.of_unit_pocket;
        crop_text.push({ ...data });
      }
    });

    let cropData = JSON.stringify(crop_text);
    data.cropText = cropData;

    let data1 = {
      Comms: "Comms" in data ? data.Comms : "",
      crop_id: "crop_id" in data ? data.crop_id : "",
      deep_rooting: "deep_rooting" in data ? data.deep_rooting : "",
      heavy_feeder: "heavy_feeder" in data ? data.heavy_feeder : "",
      maxCycleLenght: "maxCycleLenght" in data ? data.maxCycleLenght : "",
      maxOptPrecipition:
        "maxOptPrecipition" in data ? data.maxOptPrecipition : "",
      maxOptTemperature:
        "maxOptTemperature" in data ? data.maxOptTemperature : "",
      maxPocketDistance:
        "maxPocketDistance" in data ? data.maxPocketDistance : "",
      maxPrecipition: "maxPrecipition" in data ? data.maxPrecipition : "",
      maxRowDistance: "maxRowDistance" in data ? data.maxRowDistance : "",
      maxSlope: "maxSlope" in data ? data.maxSlope : "",
      maxTemperature: "maxTemperature" in data ? data.maxTemperature : "",
      maxYeild: "maxYeild" in data ? data.maxYeild : "",
      minCycleLength: "minCycleLength" in data ? data.minCycleLength : "",
      minOptPrecipition:
        "minOptPrecipition" in data ? data.minOptPrecipition : "",
      minOptTemperature:
        "minOptTemperature" in data ? data.minOptTemperature : "",
      minPocketDistance:
        "minPocketDistance" in data ? data.minPocketDistance : "",
      minPrecipition: "minPrecipition" in data ? data.minPrecipition : "",
      minRowDistance: "minRowDistance" in data ? data.minRowDistance : "",
      minSlope: "minSlope" in data ? data.minSlope : "",
      minTemperature: "minTemperature" in data ? data.minTemperature : "",
      min_return_time: "min_return_time" in data ? data.min_return_time : "",
      netonext_growing_stage:
        "netonext_growing_stage" in data ? data.netonext_growing_stage : "",
      of_heaps_are: "of_heaps_are" in data ? data.of_heaps_are : "",
      of_kg_are: "of_kg_are" in data ? data.of_kg_are : "",
      of_unit_pocket: "of_unit_pocket" in data ? data.of_unit_pocket : "",
      rotating_group: "rotating_group" in data ? data.rotating_group : "",
      weight_of_1000_seeds:
        "weight_of_1000_seeds" in data ? data.weight_of_1000_seeds : "",
      organisation: data.organisation,
    };
    let data2 = { ...data, ...data1 };

    var characteristics_data = yield call(
      CropService.characteristicsRegister,
      data2
    );

    if (characteristics_data === "Crop characteristics is saved") {
      toast.success("Data has been added/updated successfully", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield put({
        type: CropActionTypes.REGISTER_CHARACTERISTICS_DATA_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      yield put({
        type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
        selected_crop_detail: selected_crop_id,
        selected_crop_id: crop_id.selected_crop_id,
      });

      yield delay(2000);

      history.push(`/crop/general`);
      history.push(`/crop/characteristics`);
    } else {
      if ("error" in characteristics_data) {
        toast.error(characteristics_data.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      yield put({
        type: CropActionTypes.REGISTER_CHARACTERISTICS_DATA_FAILURE,
      });
    }
  }
}

export function* registerIndicator() {
  while (true) {
    const { data } = yield take(
      CropActionTypes.REGISTER_INDICATOR_DATA_REQUEST
    );

    var indicator_data = yield call(CropService.indicatorRegister, data);

    if (
      indicator_data === "Crop Indicator data is saved." ||
      indicator_data === "Crop Indicator data is updated."
    ) {
      yield put({
        type: CropActionTypes.REGISTER_INDICATOR_DATA_SUCCESS,
      });

      let crop = yield select(getCrop);

      let selected_crop = yield call(CropService.cropDetailRequest, {
        crop_id: crop.selected_crop_id,
        organisation: data.organisation,
      });

      if (selected_crop) {
        toast.success("Data has been added/updated successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop,
          selected_crop_id: crop.selected_crop_id,
        });

        yield delay(2000);

        history.push(`/crop/general`);
        history.push(`/crop/indicator`);
      }
    } else {
      if ("error" in indicator_data) {
        toast.error(indicator_data.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }

      yield put({
        type: CropActionTypes.REGISTER_INDICATOR_DATA_FAILURE,
      });
    }
  }
}

export function* registerPestDiseases() {
  while (true) {
    const { data } = yield take(
      CropActionTypes.REGISTER_PEST_DISEASES_DATA_REQUEST
    );

    let pest_diseases_text = [];
    Object.values(data.pestDiseaseTextjson).map((temp_data) => {
      let data0 = {};
      if ("displayName" in temp_data === false) {
        let data1 = { displayName: "" };
        data0 = { ...data0, data1 };
      }
      // if("treatment" in temp_data === false){
      //     let data1 = {"treatment": ""};
      //     data0 = {...data0, ...data1};
      // }
      if ("description" in temp_data === false) {
        let data1 = { description: "" };
        data0 = { ...data0, ...data1 };
      }
      if ("prevention" in temp_data === false) {
        let data1 = { prevention: "" };
        data0 = { ...data0, ...data1 };
      }
      if ("monitoring" in temp_data === false) {
        let data1 = { monitoring: "" };
        data0 = { ...data0, ...data1 };
      }
      if ("green_measures" in temp_data === false) {
        let data1 = { green_measures: "" };
        data0 = { ...data0, ...data1 };
      }
      if ("yellow_measures" in temp_data === false) {
        let data1 = { yellow_measures: "" };
        data0 = { ...data0, ...data1 };
      }
      data0 = { ...data0, ...temp_data };
      pest_diseases_text.push(data0);
    });

    var data1 = {
      crop_id: data.crop_id,
      pest_id: data.pest_id,
      scientific_name:
        "scientificName" in data === false ? "" : data.scientificName,
      pestDiseaseTextjson: JSON.stringify(pest_diseases_text),
      organisation: data.organisation,
    };

    var pest_diseases_register = yield call(
      CropService.registerPestDiseases,
      JSON.stringify(data1)
    );

    if (pest_diseases_register === "Pest and Disease is added") {
      yield put({
        type: CropActionTypes.REGISTER_PEST_DISEASES_DATA_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      var crop_detail = yield call(CropService.pestDiseasesListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      yield put({
        type: CropActionTypes.PEST_DISEASES_LIST_SUCCESS,
        list: crop_detail,
      });
      toast.success("Pest and diseases has been added/updated successfully.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield delay(2000);

      history.push(`/crop/general`);
      history.push(`/crop/pest_diseases`);
    } else {
      yield put({
        type: CropActionTypes.REGISTER_PEST_DISEASES_DATA_FAILURE,
      });
    }
  }
}

export function* pestDiseasesListRequest() {
  while (true) {
    const { crop_id, organisation } = yield take(
      CropActionTypes.PEST_DISEASES_LIST_REQUEST
    );

    var crop_detail = yield call(CropService.pestDiseasesListRequest, {
      crop_id: crop_id,
      organisation: organisation,
    });

    yield put({
      type: CropActionTypes.PEST_DISEASES_LIST_SUCCESS,
      list: crop_detail,
    });
  }
}

export function* cropMediaList() {
  while (true) {
    const { crop_id, organisation } = yield take(
      CropActionTypes.CROP_MEDIA_LIST_REQUEST
    );

    var media_list = yield call(CropService.cropMediaListRequest, {
      crop_id: crop_id,
      organisation: organisation,
    });

    yield put({
      type: CropActionTypes.CROP_MEDIA_LIST_SUCCESS,
      media_list: media_list,
    });
  }
}

export function* cropRegister() {
  while (true) {
    const { data } = yield take(CropActionTypes.CROP_REGISTER_REQUEST);

    let crop_text = [];
    let growArea = [];
    let season = [];
    Object.values(data.cropText).map((text) => {
      crop_text.push(text);
    });
    Object.values(data.growArea).map((text) => {
      if ("growAreaId" in text) {
        if (
          text.growAreaId !== undefined &&
          text.growAreaId !== "" &&
          text.growAreaId !== null
        ) {
          growArea.push(text);
        }
      }
    });
    Object.values(data.season).map((text) => {
      let data = {};
      data.crop_id = "crop_id" in text ? text.crop_id : data.crop_id;
      data.season_key = text.season_key;
      data.season_suitability =
        "season_suitability" in text
          ? text.season_defaultPlantDate === undefined ||
            text.season_defaultPlantDate === "0"
            ? "1"
            : text.season_suitability
          : 0;
      data.season_defaultPlantDate =
        text.season_defaultPlantDate === "" ||
        text.season_defaultPlantDate === undefined
          ? 0
          : text.season_defaultPlantDate;
      data.cropseason_id = text.cropseason_id;
      data.sorting =
        "sorting" in text
          ? text.sorting === "" || !text.sorting
            ? 0
            : text.sorting
          : 0;
      season.push(data);
    });

    let data1 = {
      crop_id: data.crop_id,
      code: data.code,
      crop_species_name: data.crop_species_name,
      crop_level: data.crop_level,
      crop_type: data.crop_type,
      crop_group: data.crop_group,
      variety_iso_code: data.variety_iso_code,
      image: data.image,
      crop_url: data.crop_url,
      organisation: data.organisation,
      cropText: JSON.stringify(crop_text),
      growArea: JSON.stringify(growArea),
      season: JSON.stringify(season),
    };

    var crop_register = yield call(
      CropService.cropRegister,
      JSON.stringify(data1)
    );

    if (crop_register === "Data has been saved") {
      yield put({
        type: CropActionTypes.CROP_REGISTER_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      if (crop_id.selected_crop_id === "") {
        toast.success("Data has been added/updated successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        var crop = yield call(CropService.cropRequest, {
          org_name: data.organisation,
        });

        let data1 = {};

        data1.organisation = JSON.parse(
          localStorage.getItem("user")
        ).organization;

        var season_list = yield call(CropService.seasonListRequest, data1);

        var grow_area_list = yield call(CropService.growAreaRequest, data1);

        if (crop) {
          yield put({
            type: CropActionTypes.CROP_SUCCESS,
            crop_list: crop,
            season_list: season_list,
            grow_area_list: grow_area_list,
            loading: false,
          });
        } else {
          yield put({
            type: CropActionTypes.CROP_FAILURE,
          });
        }

        yield delay(2000);

        history.push("/crop");
      } else {
        let selected_crop_id = yield call(CropService.cropDetailRequest, {
          crop_id: crop_id.selected_crop_id,
          organisation: data.organisation,
        });

        if (selected_crop_id) {
          yield put({
            type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
            selected_crop_detail: selected_crop_id,
            selected_crop_id: crop_id.selected_crop_id,
            selected_crop_detail_loading: false,
          });
        }

        //var crop = yield call(CropService.cropRequest);

        var cropData = yield select(getCrop);
        var crop = cropData.crop_list;
        var season_list = cropData.season_list;

        var grow_area_list = cropData.grow_area_list;

        if (crop) {
          toast.success("Data has been added/updated successfully.", {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          });

          yield put({
            type: CropActionTypes.CROP_SUCCESS,
            crop_list: crop,
            season_list: season_list,
            grow_area_list: grow_area_list,
            loading: false,
          });

          yield delay(2000);

          history.push(`/crop/general`);
          history.push(`/crop/detail`);
        } else {
          yield put({
            type: CropActionTypes.CROP_FAILURE,
          });
        }
      }
    } else {
      if ("error" in crop_register) {
        toast.error(crop_register.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      yield put({
        type: CropActionTypes.CROP_REGISTER_FAILURE,
      });

      yield delay(2000);

      history.push(`/crop/general`);
      history.push(`/crop/detail`);
    }
  }
}

export function* pestDiseasesMediaRequest() {
  while (true) {
    const { crop_id } = yield take(CropActionTypes.PEST_DISEASES_MEDIA_REQUEST);

    var data = yield call(CropService.pestDiseasesMediaListRequest, {
      crop_id: crop_id,
    });

    yield put({
      type: CropActionTypes.PEST_DISEASES_MEDIA_SUCCESS,
      data: data,
    });
  }
}

export function* registerPestDiseasesMedia() {
  while (true) {
    const { data } = yield take(
      CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_REQUEST
    );

    let pest_diseases_text = [];
    Object.values(data.mediaText).map((text_pest) => {
      pest_diseases_text.push({ ...text_pest });
    });

    var data1 = {
      crop_id: data.crop_id,
      sorting: data.sorting,
      pest_id: data.pest_id,
      media_id: data.media_id,
      code: data.code,
      mediaText: JSON.stringify(pest_diseases_text),
      organisation: data.organisation,
    };

    var pest_diseases_media = yield call(
      CropService.registerPestDiseasesMedia,
      JSON.stringify(data1)
    );

    if (pest_diseases_media === "Crop Media Saved") {
      yield put({
        type: CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_SUCCESS,
        selected_pest_diseases: data.selected_pest_diseases,
      });

      let crop_id = yield select(getCrop);

      var crop_detail = yield call(CropService.pestDiseasesListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      yield put({
        type: CropActionTypes.PEST_DISEASES_LIST_SUCCESS,
        list: crop_detail,
      });

      var data12 = yield call(CropService.pestDiseasesMediaListRequest, {
        crop_id: crop_id.selected_crop_id,
        pest_id: data.selected_pest_diseases.id,
        organisation: data.organisation,
      });

      if (data12) {
        yield put({
          type: CropActionTypes.PEST_DISEASES_MEDIA_SUCCESS,
          data: data12,
        });

        toast.success(
          "Pest and diseases media has been added/updated successfully",
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          }
        );

        yield delay(2000);

        history.push(`/crop/general`);
        history.push(`/crop/pest_diseases`);
      }
    } else {
      yield put({
        type: CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_FAILURE,
      });
    }
  }
}

export function* deleteCropMedia() {
  while (true) {
    const { media_id, type1, organisation } = yield take(
      CropActionTypes.DELETE_MEDIA_REQUEST
    );

    if (typeof media_id === "object") {
      var media_id_letter = media_id.toString();
      var deleted_media = yield call(CropService.deleteCropMedia, {
        media_id: media_id_letter,
        organisation: organisation,
      });
    } else {
      var deleted_media = yield call(CropService.deleteCropMedia, {
        media_id: media_id,
        organisation: organisation,
      });
    }

    if (deleted_media === "Selected rows has been deleted.") {
      yield put({
        type: CropActionTypes.DELETE_MEDIA_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      var media_list = yield call(CropService.cropMediaListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: organisation,
      });

      yield put({
        type: CropActionTypes.CROP_MEDIA_LIST_SUCCESS,
        media_list: media_list,
      });

      var data = yield call(CropService.pestDiseasesMediaListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: organisation,
      });

      yield put({
        type: CropActionTypes.PEST_DISEASES_MEDIA_SUCCESS,
        data: data,
      });

      toast.success("Media has been deleted successfully.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield delay(2000);

      history.push(`/crop/general`);

      if (type1 === "general") {
        history.push(`/crop/general`);
      } else {
        history.push(`/crop/pest_diseases`);
      }
    } else {
      yield put({
        type: CropActionTypes.DELETE_MEDIA_FAILURE,
      });
    }
  }
}

export function* deleteVariety() {
  while (true) {
    const { variety_id, organisation } = yield take(
      CropActionTypes.DELETE_VARIETY_REQUEST
    );

    if (typeof variety_id === "object") {
      var media_id_letter = variety_id.toString();
      var deleted_variety = yield call(CropService.deleteVarietyMedia, {
        variety_id: media_id_letter,
        organisation: organisation,
      });
    } else {
      var deleted_variety = yield call(CropService.deleteVarietyMedia, {
        variety_id: variety_id,
        organisation: organisation,
      });
    }

    if (deleted_variety === "Selected rows has been deleted.") {
      yield put({
        type: CropActionTypes.DELETE_VARIETY_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: organisation,
      });

      if (selected_crop_id) {
        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop_id,
          selected_crop_id: crop_id.selected_crop_id,
        });
        toast.success("Variety has been deleted successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield delay(2000);

        history.push(`/crop/general`);
        history.push(`/crop/variety`);
      }
    } else {
      yield put({
        type: CropActionTypes.DELETE_VARIETY_FAILURE,
      });
    }
  }
}

export function* deletePestDiseases() {
  while (true) {
    const { data, organisation } = yield take(
      CropActionTypes.DELETE_PEST_DISEASES_REQUEST
    );

    if (typeof data === "object") {
      var media_id_letter = data.toString();
      var deleted_pest_diseases = yield call(CropService.deletePest, {
        pestDisease_id: media_id_letter,
        organisation: organisation,
      });
    } else {
      var deleted_pest_diseases = yield call(CropService.deletePest, {
        pestDisease_id: data,
        organisation: organisation,
      });
    }

    if (deleted_pest_diseases === "Selected row has been deleted.") {
      yield put({
        type: CropActionTypes.DELETE_PEST_DISEASES_SUCCESS,
      });

      var crop_id = yield select(getCrop);
      var crop_detail = yield call(CropService.pestDiseasesListRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: organisation,
      });

      toast.success("Pest and diseases has been deleted successfully.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield put({
        type: CropActionTypes.PEST_DISEASES_LIST_SUCCESS,
        list: crop_detail,
      });
    } else {
      yield put({
        type: CropActionTypes.DELETE_PEST_DISEASES_FAILURE,
      });
    }
  }
}

export function* registerVariety() {
  while (true) {
    const { data } = yield take(CropActionTypes.VARIETY_REGISTER_REQUEST);

    let crop_variety_text = [];
    Object.values(data.cropVarietyText).map((data) => {
      crop_variety_text.push(data);
    });

    var data0 = {
      crop_id: data.crop_id,
      variety_id: "id" in data ? data.id : "",
      variety_code_locale: data.codeLocale,
      variety_centre_locale: data.centreLocale,
      variety_code_origin: data.codeOrigin,
      variety_type: data.type,
      variety_minRendement: data.minRendement,
      variety_maxRendement: data.maxRendement,
      variety_minLifecycle: data.minLifeCycle,
      variety_maxLifecycle: data.maxLifeCycle,
      variety_centre_origin: data.centreOrigin,
      cropVarietyText: JSON.stringify(crop_variety_text),
      organisation: data.organisation,
      min_seed: data.min_seed,
      max_seed: data.max_seed,
    };

    yield delay(1000);

    var crop_variety = yield call(CropService.registerVariety, data0);

    if (crop_variety === "variety is added") {
      yield put({
        type: CropActionTypes.VARIETY_REGISTER_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      if (selected_crop_id) {
        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop_id,
          selected_crop_id: crop_id.selected_crop_id,
        });

        toast.success("Variety has been added/updated successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield delay(2000);

        history.push(`/crop/general`);
        history.push(`/crop/variety`);
      }
    } else {
      yield put({
        type: CropActionTypes.VARIETY_REGISTER_FAILURE,
      });
    }
  }
}

export function* registerFertilizer() {
  while (true) {
    const { data } = yield take(CropActionTypes.REGISTER_FERTILIZER_REQUEST);

    let crop_variety_text = [];
    Object.values(data.fertilizerText).map((data, key) => {
      let data1 = {};
      data1.comms = "comms" in data ? data.comms : "";
      data1.fertilizerUnit =
        "fertilizerUnit" in data ? data.fertilizerUnit : "";
      data1.locale = "locale" in data ? data.locale : "";
      data1.mix1_name = "mix1_name" in data ? data.mix1_name : "";
      data1.mix2_name = "mix2_name" in data ? data.mix2_name : "";
      data1.mix3_name = "mix3_name" in data ? data.mix3_name : "";
      data1.mix4_name = "mix4_name" in data ? data.mix4_name : "";

      data1.fertilizer1_name =
        "fertilizer1_name" in data ? data.fertilizer1_name : "";
      data1.fertilizer2_name =
        "fertilizer2_name" in data ? data.fertilizer2_name : "";
      data1.fertilizer3_name =
        "fertilizer3_name" in data ? data.fertilizer3_name : "";
      data1.fertilizer4_name =
        "fertilizer4_name" in data ? data.fertilizer4_name : "";

      data1.fertilizer1_unit =
        "fertilizer1_unit" in data ? data.fertilizer1_unit : "";
      data1.fertilizer2_unit =
        "fertilizer2_unit" in data ? data.fertilizer2_unit : "";
      data1.fertilizer3_unit =
        "fertilizer3_unit" in data ? data.fertilizer3_unit : "";
      data1.fertilizer4_unit =
        "fertilizer4_unit" in data ? data.fertilizer4_unit : "";

      data1.alternative_fertilizer1_name =
        "alternative_fertilizer1_name" in data
          ? data.alternative_fertilizer1_name
          : "";
      data1.alternative_fertilizer2_name =
        "alternative_fertilizer2_name" in data
          ? data.alternative_fertilizer2_name
          : "";
      data1.alternative_fertilizer3_name =
        "alternative_fertilizer3_name" in data
          ? data.alternative_fertilizer3_name
          : "";
      data1.alternative_fertilizer4_name =
        "alternative_fertilizer4_name" in data
          ? data.alternative_fertilizer4_name
          : "";

      data1.alternative_fertilizer1_unit =
        "alternative_fertilizer1_unit" in data
          ? data.alternative_fertilizer1_unit
          : "";
      data1.alternative_fertilizer2_unit =
        "alternative_fertilizer2_unit" in data
          ? data.alternative_fertilizer2_unit
          : "";
      data1.alternative_fertilizer3_unit =
        "alternative_fertilizer3_unit" in data
          ? data.alternative_fertilizer3_unit
          : "";
      data1.alternative_fertilizer4_unit =
        "alternative_fertilizer4_unit" in data
          ? data.alternative_fertilizer4_unit
          : "";
      crop_variety_text.push(data1);
    });

    var data1 = {
      crop_id: data.crop_id,
      cropFertilizer_id: data.cropFertilizer_id,
      dose: "dose" in data ? data.dose : "",
      year: "year" in data ? data.year : "",
      organisation: data.organisation,

      N: "N" in data ? data.N : "",
      P: "P" in data ? data.P : "",
      K: "K" in data ? data.K : "",

      mix1_kg_are: "mix1_kg_are" in data ? data.mix1_kg_are : "",
      mix2_kg_are: "mix2_kg_are" in data ? data.mix2_kg_are : "",
      mix3_kg_are: "mix3_kg_are" in data ? data.mix3_kg_are : "",
      mix4_kg_are: "mix4_kg_are" in data ? data.mix4_kg_are : "",

      mix1_goblets_inmixture:
        "mix1_goblets_inmixture" in data ? data.mix1_goblets_inmixture : "",
      mix2_goblets_inmixture:
        "mix2_goblets_inmixture" in data ? data.mix2_goblets_inmixture : "",
      mix3_goblets_inmixture:
        "mix3_goblets_inmixture" in data ? data.mix3_goblets_inmixture : "",
      mix4_goblets_inmixture:
        "mix4_goblets_inmixture" in data ? data.mix4_goblets_inmixture : "",

      nbr_units_pockets:
        "nbr_units_pockets" in data ? data.nbr_units_pockets : "",

      fertilizer1_unit_pocket:
        "fertilizer1_unit_pocket" in data ? data.fertilizer1_unit_pocket : "",
      fertilizer2_unit_pocket:
        "fertilizer2_unit_pocket" in data ? data.fertilizer2_unit_pocket : "",
      fertilizer3_unit_pocket:
        "fertilizer3_unit_pocket" in data ? data.fertilizer3_unit_pocket : "",
      fertilizer4_unit_pocket:
        "fertilizer4_unit_pocket" in data ? data.fertilizer4_unit_pocket : "",

      fertilizer1_kg_are:
        "fertilizer1_kg_are" in data ? data.fertilizer1_kg_are : "",
      fertilizer2_kg_are:
        "fertilizer2_kg_are" in data ? data.fertilizer2_kg_are : "",
      fertilizer3_kg_are:
        "fertilizer3_kg_are" in data ? data.fertilizer3_kg_are : "",
      fertilizer4_kg_are:
        "fertilizer4_kg_are" in data ? data.fertilizer4_kg_are : "",

      altrnative_fertilizer1_kg_are:
        "altrnative_fertilizer1_kg_are" in data
          ? data.altrnative_fertilizer1_kg_are
          : "",
      altrnative_fertilizer2_kg_are:
        "altrnative_fertilizer2_kg_are" in data
          ? data.altrnative_fertilizer2_kg_are
          : "",
      altrnative_fertilizer3_kg_are:
        "altrnative_fertilizer3_kg_are" in data
          ? data.altrnative_fertilizer3_kg_are
          : "",
      altrnative_fertilizer4_kg_are:
        "altrnative_fertilizer4_kg_are" in data
          ? data.altrnative_fertilizer4_kg_are
          : "",

      alternative_fertilizer1_unit_pocket:
        "alternative_fertilizer1_unit_pocket" in data
          ? data.alternative_fertilizer1_unit_pocket
          : "",
      alternative_fertilizer2_unit_pocket:
        "alternative_fertilizer2_unit_pocket" in data
          ? data.alternative_fertilizer2_unit_pocket
          : "",
      alternative_fertilizer3_unit_pocket:
        "alternative_fertilizer3_unit_pocket" in data
          ? data.alternative_fertilizer3_unit_pocket
          : "",
      alternative_fertilizer4_unit_pocket:
        "alternative_fertilizer4_unit_pocket" in data
          ? data.alternative_fertilizer4_unit_pocket
          : "",

      fertilizerText: JSON.stringify(crop_variety_text),
    };

    var fertilizer_register = yield call(
      CropService.registerFertilizer,
      JSON.stringify(data1)
    );
    if (fertilizer_register === "Crop Fertilizer is added.") {
      yield put({
        type: CropActionTypes.REGISTER_FERTILIZER_SUCCESS,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: data.organisation,
      });

      if (selected_crop_id) {
        toast.success("Fertilizer has been added/updated successfully.", {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });

        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop_id,
          selected_crop_id: crop_id.selected_crop_id,
        });

        yield delay(2000);

        history.push(`/crop/general`);
        history.push(`/crop/fertilizer`);
      }
    } else {
      if ("error" in fertilizer_register) {
        toast.error(fertilizer_register.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }

      yield put({
        type: CropActionTypes.REGISTER_FERTILIZER_FAILURE,
      });
    }
  }
}

export function* deleteFertilizerRequest() {
  while (true) {
    const { data, organisation } = yield take(
      CropActionTypes.DELETE_FERTILIZER_REQUEST
    );

    if (typeof data === "object") {
      var fertilizer_id_letter = data.toString();
      var deleted_fertilizer = yield call(CropService.deleteFertilizer, {
        fertilizer_id: fertilizer_id_letter,
        organisation: organisation,
      });
    } else {
      var deleted_fertilizer = yield call(CropService.deleteFertilizer, {
        fertilizer_id: data,
        organisation: organisation,
      });
    }

    if (deleted_fertilizer === "Selected row has been deleted.") {
      toast.success("Fertilizer has been deleted successfuly.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield put({
        type: CropActionTypes.DELETE_FERTILIZER_SUCCESS,
        feretilizer_delete_loading: false,
      });

      let crop_id = yield select(getCrop);

      let selected_crop_id = yield call(CropService.cropDetailRequest, {
        crop_id: crop_id.selected_crop_id,
        organisation: organisation,
      });

      if (selected_crop_id) {
        yield put({
          type: CropActionTypes.PARTICULAR_CROP_DETAIL_SUCCESS,
          selected_crop_detail: selected_crop_id,
          selected_crop_id: crop_id.selected_crop_id,
          selected_crop_detail_loading: false,
        });
      }

      yield delay(2000);

      history.push(`/crop/general`);
      history.push(`/crop/fertilizer`);
    } else {
      yield put({
        type: CropActionTypes.DELETE_FERTILIZER_FAILURE,
      });
    }
  }
}

export function* pestDiseasesMediaList() {
  while (true) {
    const { data } = yield take(CropActionTypes.SELECT_PEST_DISEASES);
    let organisation = yield select(getOrganisation);

    var pest_diseases_media_list = yield call(
      CropService.pestDiseasesMediaListRequest,
      {
        crop_id: data.crop_id,
        pest_id: data.id,
        organisation: organisation.organization,
      }
    );

    if (pest_diseases_media_list.length > 0) {
      yield put({
        type: CropActionTypes.PEST_DISEASES_MEDIA_SUCCESS,
        data: pest_diseases_media_list,
      });
    } else {
      yield put({
        type: CropActionTypes.PEST_DISEASES_MEDIA_FAILURE,
        data: pest_diseases_media_list,
      });
    }
  }
}

export function* cropPublishUnpublish() {
  while (true) {
    const { data } = yield take(CropActionTypes.CROP_PUBLISH_UNPUBLISH_REQUEST);

    let crop_id = yield select(getCrop);

    var status = yield call(CropService.cropPublishUnPublish, data);

    if (
      ["The crop is unpublished.", "The crop is published."].includes(
        status
      ) === true
    ) {
      var crop = yield call(CropService.cropRequest, {
        org_name: data.organisation,
      });

      var season_list = crop_id.season_list;

      var grow_area_list = crop_id.grow_area_list;

      if (crop) {
        yield put({
          type: CropActionTypes.CROP_SUCCESS,
          crop_list: crop,
          season_list: season_list,
          grow_area_list: grow_area_list,
          loading: false,
        });
      } else {
        yield put({
          type: CropActionTypes.CROP_FAILURE,
        });
      }

      let message =
        status === "The crop is unpublished."
          ? "Crop has been unpublished successfully."
          : "Crop has been published successfully.";
      yield put({
        type: CropActionTypes.CROP_PUBLISH_UNPUBLISH_SUCCESS,
        publish_message: message,
      });
      toast.success(message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      yield put({
        type: CropActionTypes.CROP_PUBLISH_UNPUBLISH_SUCCESS,
        publish_message: "Error occured",
      });
    }
  }
}

export function* cropCopyRequest() {
  while (true) {
    let { data } = yield take(CropActionTypes.CROP_COPY_REQUEST);

    let crop_data = yield call(CropService.cropSettings, data);

    let crop_id = yield select(getCrop);

    if (
      [
        "Selected crops has been removed from the Organisation.",
        "Selected crops has been linked.",
      ].includes(crop_data) === true
    ) {
      var crop = yield call(CropService.cropRequest, {
        org_name: data.org_name,
      });

      var season_list = crop_id.season_list;

      var grow_area_list = crop_id.grow_area_list;

      if (crop) {
        yield put({
          type: CropActionTypes.CROP_SUCCESS,
          crop_list: crop,
          season_list: season_list,
          grow_area_list: grow_area_list,
          loading: false,
        });
      } else yield put({ type: CropActionTypes.CROP_FAILURE });

      toast.success(crop_data, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield put({ type: CropActionTypes.CROP_COPY_SUCCESS });
    } else if (typeof crop_id === Object) {
      toast.error(crop_data.error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    } else {
      yield put({ type: CropActionTypes.CROP_COPY_SUCCESS });
    }
  }
}

const sanitizeVarietyMediaList = (varietyMediaList) => {
  let tmp_variety_media_list = [];

  varietyMediaList.forEach((tmp) => {
    let index = tmp_variety_media_list.findIndex((i) => {
      return i.code === tmp.code;
    });

    if (index === -1) {
      tmp_variety_media_list.push({
        code: tmp.code,
        org_id: tmp.org_id,
        sorting: tmp.sorting,
        text: [
          {
            description: tmp.description,
            id: tmp.id,
            link: tmp.link,
            media_title: tmp.media_title,
            media_type: tmp.media_type,
            locale: tmp.locale,
          },
        ],
        variety_id: tmp.variety_id,
      });
    } else {
      let code_index = tmp_variety_media_list[index].text.findIndex(
        (i) => i.locale === tmp.locale
      );

      if (code_index === -1) {
        tmp_variety_media_list[index].text.push({
          description: tmp.description,
          id: tmp.id,
          link: tmp.link,
          media_title: tmp.media_title,
          media_type: tmp.media_type,
          locale: tmp.locale,
        });
      }
    }
  });

  tmp_variety_media_list = tmp_variety_media_list.sort((a, b) =>
    a.sorting > b.sorting ? 1 : -1
  );
  return tmp_variety_media_list;
};

export function* varietyMediaListRequest() {
  while (true) {
    let { params } = yield take(CropActionTypes.VARIETY_MEDIA_LIST_REQUEST);

    var variety_media_list = yield call(CropService.varietyMediaList, params);

    if (variety_media_list) {
      variety_media_list = sanitizeVarietyMediaList(variety_media_list);
      yield put({
        type: CropActionTypes.VARIETY_MEDIA_LIST_SUCCESS,
        variety_media_list: variety_media_list,
      });
    }
  }
}

export function* deleteVarietyMediaRequest() {
  while (true) {
    let { params } = yield take(CropActionTypes.DELETE_VARIETY_MEDIA_REQUEST);

    let variety_delete = yield call(CropService.deleteVarietyMediaData, {
      id: params.id.join(","),
    });

    if (variety_delete?.message === "Selected rows have been deleted.") {
      let variety_media_list = yield call(CropService.varietyMediaList, {
        org_id: params.org_id,
        variety_id: params.variety_id,
      });

      if (variety_media_list) {
        variety_media_list = sanitizeVarietyMediaList(variety_media_list);
        yield put({
          type: CropActionTypes.VARIETY_MEDIA_LIST_SUCCESS,
          variety_media_list: variety_media_list,
        });

        toast.success(variety_delete?.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } else {
      let crop_id = yield select(getCrop);

      yield put({
        type: CropActionTypes.VARIETY_MEDIA_LIST_SUCCESS,
        variety_media_list: crop_id.variety_media_list,
      });

      toast.error("Cannot delete variety media.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }
}

export function* registerVarietyRequest() {
  while (true) {
    let { params } = yield take(CropActionTypes.REGISTER_VARIETY_MEDIA_REQUEST);

    let varietyMediaText = [];
    Object.values(params.varietyMediaText).map((tmp) => {
      varietyMediaText.push({ ...tmp });
    });

    var data1 = {
      ...params,
      ...{ varietyMediaText: JSON.stringify(varietyMediaText) },
    };

    var data1 = {
      crop_id: params.crop_id,
      sorting: params.sorting,
      variety_id: params.variety_id,
      code: params.code,
      varietyMediaText: JSON.stringify(varietyMediaText),
      org_id: params.org_id,
      sorting: params.sorting,
    };

    let variety_register = yield call(
      CropService.addVarietyMedia,
      JSON.stringify(data1)
    );

    if (variety_register?.message === "Variety media is added.") {
      let variety_media_list = yield call(CropService.varietyMediaList, {
        variety_id: params.variety_id,
        org_id: params.org_id,
      });

      if (variety_media_list) {
        variety_media_list = sanitizeVarietyMediaList(variety_media_list);
        yield put({
          type: CropActionTypes.REGISTER_VARIETY_MEDIA_SUCCESS,
          variety_media_list: variety_media_list,
        });

        toast.success(variety_register?.message, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
    } else {
      let crop_id = yield select(getCrop);

      yield put({
        type: CropActionTypes.REGISTER_VARIETY_MEDIA_SUCCESS,
        variety_media_list: crop_id.variety_media_list,
      });

      toast.error("Cannot add variety media.", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  }
}

export default function* AuthSaga() {
  yield fork(cropRequest);
  yield fork(particularCropDetailRequest);
  yield fork(registerDescription);
  yield fork(registerDescriptionMedia);
  yield fork(registerCharacteristics);
  yield fork(registerIndicator);
  yield fork(registerPestDiseases);
  yield fork(pestDiseasesListRequest);
  yield fork(cropMediaList);
  yield fork(cropRegister);
  yield fork(pestDiseasesMediaRequest);
  yield fork(registerPestDiseasesMedia);
  yield fork(deleteCropMedia);
  yield fork(deleteVariety);
  yield fork(deletePestDiseases);
  yield fork(registerVariety);
  yield fork(registerFertilizer);
  yield fork(deleteFertilizerRequest);
  yield fork(pestDiseasesMediaList);
  yield fork(cropPublishUnpublish);
  yield fork(baseOrgCropList);
  yield fork(cropCopyRequest);
  yield fork(varietyMediaListRequest);
  yield fork(deleteVarietyMediaRequest);
  yield fork(registerVarietyRequest);
}
