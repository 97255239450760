export const GetToOrganisation = (organisation, offset) => {
  let data = Object.values(organisation).filter((tmp) => {
    return tmp.from === offset;
  });

  if (data.length > 0) {
    return data[0].to;
  } else {
    return "Auxfin Burundi";
  }
};
