export const GetUnitFromSource = (units, offset) => {
  if (![null, undefined, ""].includes(units)) {
    let data = Object.values(units).filter((tmp) => {
      let source = tmp.source.trim();
      return source === offset;
    });

    if (data.length > 0) {
      return data[0].unit;
    } else {
      return "";
    }
  } else return "";
};
