import CropActionTypes from "./actionTypes";

export function cropListRequest(organization) {
  return {
    type: CropActionTypes.CROP_REQUEST,
    organization,
  };
}

export function particularCropListRequest(params) {
  return {
    type: CropActionTypes.PARTICULAR_CROP_DETAIL_REQUEST,
    params,
  };
}

export function clearCropDetail() {
  return {
    type: CropActionTypes.CLEAR_CROP_DETAIL,
  };
}

export function selectVariety(selected_data) {
  return {
    type: CropActionTypes.SELECT_VARIETY_REQUEST,
    selected_data,
  };
}

export function selectFertilizer(selected_data) {
  return {
    type: CropActionTypes.SELECT_FERTILIZER_REQUEST,
    selected_data,
  };
}

export function setDescriptionData(data) {
  return {
    type: CropActionTypes.SET_DESCRIPTION_DATA,
    data,
  };
}

export function removeDescription() {
  return {
    type: CropActionTypes.REMOVE_DESCRIPTION_DATA,
  };
}

export function registerDescription(data) {
  return {
    type: CropActionTypes.REGISTER_DESCRIPTION_REQUEST,
    data,
  };
}

export function registerDescriptionMeida(data) {
  return {
    type: CropActionTypes.REGISTER_DESCRIPTION_MEDIA_REQUEST,
    data,
  };
}

export function setMediaData(data) {
  return {
    type: CropActionTypes.SET_MEDIA_DATA,
    data,
  };
}

export function removeMedia() {
  return {
    type: CropActionTypes.REMOVE_MEDIA_DATA,
  };
}

//characteristics
export function setCharacteristics(data) {
  return {
    type: CropActionTypes.SET_CHARACTERISTICS_DATA,
    data,
  };
}
export function removeCharacteristics() {
  return {
    type: CropActionTypes.REMOVE_CHARACTERISTICS_DATA,
  };
}
export function registerCharacteristicsRequest(data) {
  return {
    type: CropActionTypes.REGISTER_CHARACTERISTICS_DATA_REQUEST,
    data,
  };
}

//indicator
export function setIndicator(data) {
  return {
    type: CropActionTypes.SET_INDICATOR_DATA,
    data,
  };
}
export function removeIndicator() {
  return {
    type: CropActionTypes.REMOVE_INDICATOR_DATA,
  };
}
export function registerIndicatorRequest(data) {
  return {
    type: CropActionTypes.REGISTER_INDICATOR_DATA_REQUEST,
    data,
  };
}

//pest and diseases
export function setPestDiseases(data) {
  return {
    type: CropActionTypes.SET_PEST_DISEASES_DATA,
    data,
  };
}
export function removePestDiseases() {
  return {
    type: CropActionTypes.REMOVE_PEST_DISEASES_DATA,
  };
}
export function registerPestDiseasesRequest(data) {
  return {
    type: CropActionTypes.REGISTER_PEST_DISEASES_DATA_REQUEST,
    data,
  };
}
export function pestDiseasesListRequest(crop_id, organisation) {
  return {
    type: CropActionTypes.PEST_DISEASES_LIST_REQUEST,
    crop_id,
    organisation,
  };
}
export function selectPestDiseases(data) {
  return {
    type: CropActionTypes.SELECT_PEST_DISEASES,
    data,
  };
}
export function pestDiseasesMediaListRequest(crop_id) {
  return {
    type: CropActionTypes.PEST_DISEASES_MEDIA_REQUEST,
    crop_id,
  };
}
export function selectedPestDiseasesMedia(data) {
  return {
    type: CropActionTypes.SELECT_PEST_DISEASES_MEDIA,
    data,
  };
}
export function registerPestDiseasesMedia(data) {
  return {
    type: CropActionTypes.REGISTER_PEST_DISEASES_MEDIA_REQUEST,
    data,
  };
}
export function deletePestDiseasesRequest(data, organisation) {
  return {
    type: CropActionTypes.DELETE_PEST_DISEASES_REQUEST,
    data,
    organisation,
  };
}

//general
export function selectCropMedia(data) {
  return {
    type: CropActionTypes.SELECT_CROP_MEDIA,
    data,
  };
}
export function deleteMedia(media_id, type1, organisation) {
  return {
    type: CropActionTypes.DELETE_MEDIA_REQUEST,
    media_id,
    type1,
    organisation,
  };
}
export function cropMediaListRequest(crop_id, organisation) {
  return {
    type: CropActionTypes.CROP_MEDIA_LIST_REQUEST,
    crop_id,
    organisation,
  };
}

//crop
export function cropRegisterRequest(data) {
  return {
    type: CropActionTypes.CROP_REGISTER_REQUEST,
    data,
  };
}

export function getBaseOrganisationCropList() {
  return {
    type: CropActionTypes.GET_BASE_ORGANISATION_CROP_LIST_REQUEST,
  };
}

export function cropCopyRequest(data) {
  return {
    type: CropActionTypes.CROP_COPY_REQUEST,
    data,
  };
}

//variety
export function deleteVarietyRequest(variety_id, organisation) {
  return {
    type: CropActionTypes.DELETE_VARIETY_REQUEST,
    variety_id,
    organisation,
  };
}
export function registerVariety(data) {
  return {
    type: CropActionTypes.VARIETY_REGISTER_REQUEST,
    data,
  };
}

//fertilizer
export function registerFertilizer(data) {
  return {
    type: CropActionTypes.REGISTER_FERTILIZER_REQUEST,
    data,
  };
}

export function deleteFertilizerRequest(data, organisation) {
  return {
    type: CropActionTypes.DELETE_FERTILIZER_REQUEST,
    data,
    organisation,
  };
}

export function cropPublishUnpublishRequest(data) {
  return {
    type: CropActionTypes.CROP_PUBLISH_UNPUBLISH_REQUEST,
    data,
  };
}

export function cropSelectUnselectRequest(data) {
  return {
    type: CropActionTypes.CROP_SELECT_UNSELECT_REQUEST,
    data,
  };
}

//location
export function setLatitudeLongitudeRequest(params) {
  return {
    type: CropActionTypes.SET_LATITUDE_AND_LONGITUDE,
    params,
  };
}

//variety media
export function varietyMediaListRequest(params) {
  return {
    type: CropActionTypes.VARIETY_MEDIA_LIST_REQUEST,
    params,
  };
}

export function deleteVarietyMediaRequest(params) {
  return {
    type: CropActionTypes.DELETE_VARIETY_MEDIA_REQUEST,
    params,
  };
}

export function registerVarietyMediaRequest(params) {
  return {
    type: CropActionTypes.REGISTER_VARIETY_MEDIA_REQUEST,
    params,
  };
}
