import React, { useState, useEffect } from "react";
import Header from "../../../../common/header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumb from "../../../../common/breadcrumb";
import TextBox from "../../../../common/textBox";
import "./indicator.css";
import { setIndicator, registerIndicatorRequest } from "../../action";
import Spinner from "../../../../common/spinner";
import CustomButton from "../../../../common/button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetToOrganisation } from "../../../lib/getToOrganisation";
import { GetUnitFromSource } from "../../../lib/getUnitFromSource";

const Indicator = (props) => {
  const [data, setData] = useState(
    props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []
  );
  const [indicatorData, setIndiatorData] = useState(props.indicatorData);
  const [yieldUnderOptimalCondition, setYieldUnderOptimalCondiitons] =
    useState("");
  const [organicFertilizerNeeded, setOrganicFertilizerNeeded] = useState("");
  const [chemicalFertilizerNeeded, setChemicalFertilizerNeeded] = useState("");

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      if ("crop_characteristics" in data === true) {
        let crop_characteristics = data.crop_characteristics[0];

        if (crop_characteristics === undefined) {
          var average = 0;

          setYieldUnderOptimalCondiitons(0);

          setOrganicFertilizerNeeded(0);
        } else {
          var average =
            (parseFloat(crop_characteristics.minYeild) +
              parseFloat(crop_characteristics.maxYeild)) /
            2.0;

          setYieldUnderOptimalCondiitons(average);

          setOrganicFertilizerNeeded(crop_characteristics.OF_kg_are);
        }
      }

      if ("crop_fertilizer" in data === true) {
        let crop_fertilizer = data.crop_fertilizer;

        var urea_kg_are = 0;
        var dap_kg_are = 0;
        var kcl_kg_are = 0;
        var mix4_kg_are = 0;
        var last_year = 0;

        if (crop_fertilizer.length > 0) {
          crop_fertilizer.map((tempData) => {
            if (tempData.year > last_year) {
              last_year = tempData.year;
            }
          });
        }

        if (crop_fertilizer.length > 0) {
          crop_fertilizer.map((tempData) => {
            if (tempData.year === last_year) {
              urea_kg_are += !parseFloat(tempData.mix1_kg_are)
                ? 0
                : parseFloat(tempData.mix1_kg_are);
              dap_kg_are += !parseFloat(tempData.mix2_kg_are)
                ? 0
                : parseFloat(tempData.mix2_kg_are);
              kcl_kg_are += !parseFloat(tempData.mix3_kg_are)
                ? 0
                : parseFloat(tempData.mix3_kg_are);
              mix4_kg_are += !parseFloat(tempData.mix4_kg_are)
                ? 0
                : parseFloat(tempData.mix4_kg_are);
            }
          });
        }

        setChemicalFertilizerNeeded(
          urea_kg_are + dap_kg_are + kcl_kg_are + mix4_kg_are
        );
      }
    }
  }, []);

  useEffect(() => {
    props.actions.setIndicator(
      props.selected_crop_detail[0].crop_indicators.length > 0
        ? props.selected_crop_detail[0].crop_indicators[0]
        : []
    );
  }, []);

  useEffect(() => {
    setIndiatorData(props.indicatorData);
  }, [props.indicatorData]);

  useEffect(() => {
    if (props.selected_crop_id !== "") {
      if ("crop_indicators" in data) {
        let data2 = {};
        for (const [value1] of Object.entries(data.crop_indicators)) {
          for (const [key, value] of Object.entries(value1)) {
            if (key === "Biomass") {
              data2["biomass"] = value;
            }
            if (key === "labourNeeded") {
              data2["laborNeeded"] = value;
            }
            data2[key] = value;
          }
        }
        setIndiatorData(data2);
      }
    }
  }, [data, props.selected_crop_id]);

  useEffect(() => {
    setData(data);
  }, [data]);

  const textChange = (name, text) => {
    let data1 = props.indicatorData;
    let data2 = { [name]: text };
    let data3 = { crop_id: props.selected_crop_id };
    let data4 = { ...data2, ...data3 };
    let data5 = { ...data1, ...data4 };

    setIndiatorData(data5);
    props.actions.setIndicator(data5);
  };

  const onSaveButtonClick = () => {
    var organisation = { organisation: props.organisation };

    if (Object.keys(indicatorData).length == 0) {
      let data = { crop_id: props.selected_crop_id };

      let data1 = { ...indicatorData, ...data, ...organisation };

      props.actions.registerIndicatorRequest(data1);
    } else {
      let data1 = { ...indicatorData, ...organisation };
      props.actions.registerIndicatorRequest(data1);
    }
  };

  const NutrientData = () => {
    const data = [];

    for (var i = 0; i <= 19; i++) {
      data.push(<option key={i}>{i}</option>);
    }

    return data;
  };

  let to_org = GetToOrganisation(props.mapping_list, props.auth);

  return (
    <div>
      <Header header={false} displayLanguage={true} />

      <Breadcrumb />

      <div className="generalBody">
        <ToastContainer />
        {props.indicator_loading === true ? (
          <Spinner />
        ) : (
          <div className="formCard">
            <div className="leftFormCard">
              <TextBox
                label="Yield under optimal conditions"
                name="biomass"
                onTextChange={textChange}
                value={yieldUnderOptimalCondition}
                unit1={GetUnitFromSource(
                  props.units,
                  "yield_under_optimal_conditions"
                )}
                disabled={true}
              />

              <TextBox
                label="Energy Value"
                name="energyValue"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].energyValue
                      : undefined
                    : undefined
                }
                unit1={GetUnitFromSource(props.units, "energy_value")}
              />

              <TextBox
                label="Protein Value"
                name="protienValue"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].protienValue
                      : undefined
                    : undefined
                }
                unit1={GetUnitFromSource(props.units, "protein_value")}
              />

              <div className="TextBoxContainer">
                <div className="leftSelectLabelContainer1">
                  <span>Micronutrient value</span>
                </div>

                <div className="rightSelectLabelContainer1">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <select
                        defaultValue={
                          props.selected_crop_id !== ""
                            ? data.crop_indicators.length > 0
                              ? data.crop_indicators[0].nutritionValue
                              : 0
                            : 0
                        }
                        className="selectInput"
                        name="nutritionValue"
                        onChange={(e) => {
                          textChange("nutritionValue", e.target.value);
                        }}
                      >
                        {NutrientData()}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <TextBox
                label="Market Value"
                name="marketValue"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].marketValue
                      : undefined
                    : undefined
                }
                unit1="3 export cash crop, 2 local niche market, 1 local market"
              />

              <TextBox
                label="Chemical fertilizer needed"
                name="inputsNeeded"
                onTextChange={textChange}
                value={chemicalFertilizerNeeded}
                disabled={true}
                unit1={`Sum of the chemical fertilizer needed ${GetUnitFromSource(
                  props.units,
                  "chemical_fertilizer_needed"
                )}`}
              />

              <TextBox
                name="organicValue"
                label="Organic fertilizer needed"
                onTextChange={textChange}
                value={organicFertilizerNeeded}
                unit1={GetUnitFromSource(
                  props.units,
                  "organic_fertilizer_needed"
                )}
                disabled={true}
              />

              <TextBox
                name="laborNeeded"
                label="Labour Needed"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].labourNeeded
                      : undefined
                    : undefined
                }
                unit1={`Indicator${GetUnitFromSource(
                  props.units,
                  "labour_needed"
                )}`}
              />

              <TextBox
                label="Crop Risk"
                name="cropRisk"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].cropRisk
                      : undefined
                    : undefined
                }
                unit1={`Indicator${GetUnitFromSource(
                  props.units,
                  "crop_risk"
                )}`}
              />

              <TextBox
                label="Availability"
                name="availability"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].availability
                      : undefined
                    : undefined
                }
                unit1={`Indicator${GetUnitFromSource(
                  props.units,
                  "availability"
                )}`}
              />
              <TextBox
                label="Perishability"
                name="perishability"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_indicators.length > 0
                      ? data.crop_indicators[0].perishability
                      : undefined
                    : undefined
                }
                unit1={`Indicator${GetUnitFromSource(
                  props.units,
                  "perishability"
                )}`}
              />

              {props.user_role_type !== "Viewer" &&
              props.organisation === to_org ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="leftSelectLabelContainer1"></div>

                  <div className="rightSelectLabelContainer1">
                    <CustomButton
                      name={`Save Crop Indicator`}
                      onSaveButtonClick={onSaveButtonClick}
                    />
                  </div>
                </div>
              ) : (
                props.user_role_type !== "Viewer" &&
                props.umva_id === props.superuser && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="leftSelectLabelContainer1"></div>

                    <div className="rightSelectLabelContainer1">
                      <CustomButton
                        name={`Save Crop Indicator`}
                        onSaveButtonClick={onSaveButtonClick}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_crop_detail: state.crop.selected_crop_detail,
    selected_crop_id: state.crop.selected_crop_id,
    indicatorData: state.crop.indicatorData,
    indicator_loading: state.crop.indicator_loading,
    user_role_type: state.auth.user_role_type,
    organisation: state.auth.organization,
    mapping_list: state.organization.mapping_list,
    auth: state.auth.organization,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    umva_id: state.auth.umva_id,
    units: state.season.units,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setIndicator,
        registerIndicatorRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Indicator);
