import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./variety.css";
import {
  selectVariety,
  deleteVarietyRequest,
  registerVariety,
  registerVarietyMediaRequest,
} from "../../action";
import { languageUpdate } from "../../../../trans/actions";
import "react-toastify/dist/ReactToastify.css";
import TextBox from "../../../../common/textBox";
import TextArea from "../../../../common/textArea";
import CustomButton from "../../../../common/button";
import VarietyTable from "./varietyTable";
import { ToastContainer } from "react-toastify";
import Spinner from "../../../../common/spinner";

const VarietyMedia = (props) => {
  const [code, setCode] = useState("");
  const [mediaSorting, setMediaSorting] = useState([]);
  const [sorting, setSorting] = useState("");
  const [codeError, setCodeError] = useState(false);
  const [mediaData, setMediaData] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedVarietyMedia, setSlectedVarietyMedia] = useState({});
  const [selectedMedia, setSelectedMedia] = useState("");

  useEffect(() => {
    if (props.variety_media_list.length > 0) {
      let data0 = [];
      props.variety_media_list.forEach((variety) => {
        var defaultLanguageExists = Object.values(variety.text).filter(
          (tempData) => {
            return tempData.locale === props.languageContent;
          }
        );
        let data = [];

        Object.values(variety.text).forEach((temp_data, i) => {
          let data1 = [];
          if (Object.keys(defaultLanguageExists).length > 0) {
            if (defaultLanguageExists[0].locale === temp_data.locale) {
              data1.id = variety.sorting;
              data1.value = temp_data.media_title;
              data = data1;
              data0.push(data);
            }
          } else {
            data1.id = variety.sorting;
            data1.value = temp_data.media_title;
            data = data1;
            data0.push(data);
          }
        });
      });
      setMediaSorting(data0);
    }
  }, [props.variety_media_list, selectedVarietyMedia]);

  useEffect(() => {
    let temp_locale = [];
    props.language_list.map((tmp, key) => {
      temp_locale.push(tmp.locale);

      if (key === Object.values(props.language_list).length - 1) {
        setLanguages(temp_locale);
      }
    });
  }, []);

  useEffect(() => {
    setMediaData([]);
    setSlectedVarietyMedia([]);
    setMediaSorting([]);
    setCode("");
    setSorting("");
  }, []);

  useEffect(() => {
    setMediaData([]);
    setSlectedVarietyMedia([]);
    setMediaSorting([]);
    setCode("");
    setSorting("");
  }, [props.variety_media_list_loading, props.variety_media_register_loading]);

  const emptyFormField = () => {
    document.getElementById("media_title").value = "";
    document.getElementById("media_description").value = "";
  };

  const getMediaLanguage = () => {
    let index = Object.values(mediaData).findIndex((temp_data) => {
      return props.languageContent === temp_data.locale;
    });
    return index;
  };

  const textChange1 = (name, text) => {
    if (name === "sorting") {
      setSorting(text);
    } else if (name === "code") {
      setCode(text);
      setCodeError(false);
    } else {
      let data1 = { [name]: text };
      let data2 =
        getMediaLanguage() in mediaData ? mediaData[getMediaLanguage()] : [];
      let language = { locale: props.languageContent };
      let media = "id" in props.selectedVariety ? "" : "";
      let media_id = { media_id: media };
      let data3 = { ...media_id, ...data2, ...data1, ...language };
      let language2 =
        getMediaLanguage() === -1
          ? Object.keys(mediaData).length
          : getMediaLanguage();
      let data4 = { [language2]: data3 };
      let data = { ...mediaData, ...data4 };
      setMediaData(data);
    }
  };

  const getMediaLanguage1 = () => {
    let index = Object.values(mediaData).findIndex((temp_data) => {
      return temp_data.locale === "en_EN";
    });
    return index;
  };

  const onAddMoreButtonClick = () => {
    let crop_id = { crop_id: props.selected_crop_id };
    let sorting1 =
      sorting === "" ? { sorting: getLastIndex() } : { sorting: sorting };
    let variety_id = {
      variety_id: !props.selectedVariety.id ? "" : props.selectedVariety.id,
    };

    if (code === "") {
      setCodeError(true);
    } else {
      setCodeError(false);
      if (Object.keys(mediaData).length > 0) {
        let added_language = languages;

        let media_type = [];

        for (let i = 0; i < added_language.length; i++) {
          let tempData = [];

          let local_index = Object.values(mediaData).findIndex((tmp) => {
            return tmp.locale === added_language[i];
          });

          if (local_index === -1) {
            tempData.media_title = "";
            tempData.description = "";
            tempData.media_type = "image/jpeg";
            tempData.locale = added_language[i];
          } else {
            tempData.media_title = [null, "", undefined].includes(
              mediaData[local_index].title
            )
              ? ""
              : mediaData[local_index].title;
            tempData.description = [null, "", undefined].includes(
              mediaData[local_index].description
            )
              ? ""
              : mediaData[local_index].description;
            tempData.media_type = [null, "", undefined].includes(
              mediaData[local_index].media_type
            )
              ? "image/jpeg"
              : mediaData[local_index].media_type;
            tempData.locale = mediaData[local_index].locale;
            if ("id" in mediaData[i]) {
              tempData.id = mediaData[local_index].id;
            }
          }

          media_type.push(tempData);
        }
        let organisation = { org_id: props.org_id };
        let code_data = { code: +code };
        let data = {
          ...crop_id,
          ...sorting1,
          ...variety_id,
          ...{ varietyMediaText: media_type },
          ...organisation,
          ...code_data,
        };
        props.actions.registerVarietyMediaRequest(data);
        emptyFormField();
        window.scrollTo(0, 0);
      }
    }
  };

  const selectVarietyMedia = (offset) => {
    setMediaData([]);
    if (Object.values(offset.text).length > 0) {
      let tmp_media_data = [];

      Object.values(offset.text).forEach((tmp, i) => {
        tmp_media_data.push({
          ...tmp,
          ...{ title: tmp.media_title },
          ...{ locale: tmp.locale },
        });
      });

      setSlectedVarietyMedia(offset);
      setCode(offset.code);
      setMediaData(tmp_media_data);
      setSelectedMedia(+offset.sorting);
      setSorting(+offset.sorting);
    }
  };

  const getLastMediaSorting = (mediaSortingData) => {
    if (mediaSortingData.length > 0) {
      return mediaSortingData[mediaSortingData.length - 1].id;
    } else {
      return 0;
    }
  };

  const getLastIndex = () => {
    if (props.variety_media_list.length > 0) {
      let sort = props.variety_media_list.sort((a, b) =>
        a.sorting > b.sorting ? 1 : -1
      );
      if (sort.length > 0) {
        return sort[sort.length - 1].sorting + 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  return (
    <>
      <div className="activityContainer">
        <ToastContainer />
        <div className="leftOffset"></div>
        <div className="mainContent">
          {props.languageLength.map((lang, key) => {
            return (
              props.langContent === lang && (
                <div key={key}>
                  {"id" in props.selectedVariety ? (
                    <div>
                      <TextBox
                        label="Media Title"
                        id="media_title"
                        name="title"
                        onTextChange1={textChange1}
                        value={
                          getMediaLanguage() in mediaData
                            ? mediaData[getMediaLanguage()].title
                            : "id" in mediaData
                            ? key in mediaData
                              ? mediaData.media_text[key].title
                              : undefined
                            : undefined
                        }
                        value1={
                          props.languageContent !== "en_EN"
                            ? getMediaLanguage1() in mediaData
                              ? mediaData[getMediaLanguage1()].title
                              : "id" in mediaData
                              ? key in mediaData
                                ? mediaData.media_text[key].title
                                : undefined
                              : undefined
                            : undefined
                        }
                      />
                      <TextArea
                        label="Media Description"
                        id="media_description"
                        name="description"
                        onTextChange1={textChange1}
                        value={
                          getMediaLanguage() in mediaData
                            ? mediaData[getMediaLanguage()].description
                            : "id" in mediaData
                            ? getMediaLanguage() in mediaData
                              ? mediaData.media_text[getMediaLanguage()]
                                  .description
                              : undefined
                            : undefined
                        }
                        value1={
                          props.languageContent !== "en_EN"
                            ? getMediaLanguage1() in mediaData
                              ? mediaData[getMediaLanguage1()].description
                              : "id" in mediaData
                              ? key in mediaData
                                ? mediaData.media_text[key].description
                                : undefined
                              : undefined
                            : undefined
                        }
                      />
                    </div>
                  ) : (
                    <div>
                      <TextBox
                        label="Media Title"
                        id="media_title"
                        name="title"
                        onTextChange1={textChange1}
                        value={
                          getMediaLanguage() in mediaData
                            ? mediaData[getMediaLanguage()].title
                            : undefined
                        }
                        value1={
                          props.languageContent !== "en_EN"
                            ? getMediaLanguage1() in mediaData
                              ? mediaData[getMediaLanguage1()].title
                              : undefined
                            : undefined
                        }
                      />
                      <TextArea
                        label="Media Description"
                        id="media_description"
                        name="description"
                        onTextChange1={textChange1}
                        value={
                          getMediaLanguage() in mediaData
                            ? mediaData[getMediaLanguage()].description
                            : undefined
                        }
                        value1={
                          props.languageContent !== "en_EN"
                            ? getMediaLanguage1() in mediaData
                              ? mediaData[getMediaLanguage1()].description
                              : undefined
                            : undefined
                        }
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}

          <TextBox
            label="Code"
            name="code"
            id="code"
            value={code}
            error={codeError === true && "Code is required."}
            onTextChange={textChange1}
          />

          {"id" in props.selectedVariety ? (
            <TextBox
              label="Media Link"
              id="media_link"
              value={
                getMediaLanguage() in mediaData
                  ? mediaData[getMediaLanguage()].link
                  : undefined
              }
              disabled={true}
            />
          ) : (
            <TextBox label="Media Link" disabled={true} />
          )}

          <div className="TextBoxContainer">
            <div className="leftSelectLabelContainer1">
              <span>Add before</span>
            </div>

            <div className="rightSelectLabelContainer1">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <select
                  className="selectInput"
                  id="sorting"
                  name="sorting"
                  value={
                    Object.keys(selectedVarietyMedia).length == 0
                      ? sorting == ""
                        ? getLastIndex()
                        : sorting
                      : getLastMediaSorting(mediaSorting) < selectedMedia
                      ? parseInt(selectedMedia) + 1
                      : selectedMedia !== ""
                      ? parseInt(selectedMedia) + 1
                      : sorting
                  }
                  onChange={(e) => {
                    textChange1("sorting", parseInt(e.target.value));
                    setSelectedMedia("");
                  }}
                  defaultValue={getLastIndex()}
                >
                  {mediaSorting.map((data, key) => {
                    return (
                      <option key={key} value={data.id}>
                        {data.value}
                      </option>
                    );
                  })}
                  {Object.keys(selectedVarietyMedia).length == 0 ? (
                    <option value={getLastIndex()}>{`End`}</option>
                  ) : getLastMediaSorting(mediaSorting) < selectedMedia ? (
                    <option value={getLastIndex()}>{`End`}</option>
                  ) : parseInt(selectedVarietyMedia.sorting) + 1 ==
                    getLastIndex() ? (
                    <option value={getLastIndex()}>{`End`}</option>
                  ) : null}
                </select>
              </div>
            </div>
          </div>

          {props.languageLength.map((lang, key) => {
            return (
              props.langContent === lang && (
                <div key={key}>
                  {getMediaLanguage() in mediaData ? (
                    <div className="TextBoxContainer">
                      <div className="leftSelectLabelContainer1">
                        <span>Media Type</span>
                      </div>

                      <div className="rightSelectLabelContainer1">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <select
                            className="selectInput"
                            value={
                              ![null, undefined, ""].includes(
                                mediaData[getMediaLanguage()].media_type
                              )
                                ? mediaData[getMediaLanguage()].media_type
                                : "NULL"
                            }
                            name="media_type"
                            onChange={(e) => {
                              textChange1("media_type", e.target.value);
                            }}
                          >
                            <option value={"image/jpeg"}>{`image/jpeg`}</option>
                            <option value={"video/mp4"}>{`video/mp4`}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="TextBoxContainer">
                      <div className="leftSelectLabelContainer1">
                        <span>Media Type</span>
                      </div>

                      <div className="rightSelectLabelContainer1">
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <select
                            className="selectInput"
                            name="media_type"
                            onChange={(e) => {
                              textChange1("media_type", e.target.value);
                            }}
                          >
                            <option value={"image/jpeg"}>{`image/jpeg`}</option>
                            <option value={"video/mp4"}>{`video/mp4`}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            );
          })}

          {props.user_role_type !== "Viewer" &&
          props.organisation === props.to_org ? (
            <div className="activityContainer">
              <div className="leftOffset"></div>
              <div className="mainContent1">
                <CustomButton
                  name={`Save Media Item`}
                  onSaveButtonClick={onAddMoreButtonClick}
                />
              </div>
            </div>
          ) : (
            props.user_role_type !== "Viewer" &&
            props.umva_id === props.superuser && (
              <div className="activityContainer">
                <div className="leftOffset"></div>
                <div className="mainContent1">
                  <CustomButton
                    name={`Save Media Item`}
                    onSaveButtonClick={onAddMoreButtonClick}
                  />
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {props.variety_media_register_loading ||
      props.variety_media_list_loading ? (
        <Spinner />
      ) : (
        <VarietyTable
          to_org={props.to_org}
          languageContent={props.languageContent}
          selectVarietyMedia={selectVarietyMedia}
          code={code}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user_role_type: state.auth.user_role_type,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    umva_id: state.auth.umva_id,
    selected_crop_id: state.crop.selected_crop_id,
    language_list: state.lang.language_list,
    org_id: state.auth.uid,
    variety_media_list: state.crop.variety_media_list,
    variety_media_list_loading: state.crop.variety_media_list_loading,
    variety_media_register_loading: state.crop.variety_media_register_loading,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectVariety,
        deleteVarietyRequest,
        registerVariety,
        languageUpdate,
        registerVarietyMediaRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(VarietyMedia);
