import React, { useState, useEffect } from "react";
import Header from "../../../../common/header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumb from "../../../../common/breadcrumb";
import TextBox from "../../../../common/textBox";
import CustomButton from "../../../../common/button";
import {
  selectFertilizer,
  registerFertilizer,
  deleteFertilizerRequest,
} from "../../action";
import "../variety/variety.css";
import "./fertilizerGeneral.css";
import "../../../../components/index.scss";
import Spinner from "../../../../common/spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import I18n from "../../../../trans/i18n";
import { GetToOrganisation } from "../../../lib/getToOrganisation";
import { GetUnitFromSource } from "../../../lib/getUnitFromSource";

import { languageUpdate } from "../../../../trans/actions";

import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const FertilizerGeneral = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  var languageListLength =
    props.language_list.length > 0 ? props.language_list.length : 1;
  var languageLength = [];

  for (let i = 0; i < languageListLength; i++) {
    languageLength.push(i);
  }

  const [fertilizerData, setFertilizerData] = useState([]);
  const [fertilizerDataText, setFertilizerDataText] = useState([]);
  const [selectedFertilizer, setSelectedFertilizer] = useState([
    props.selected_fertilizer,
  ]);
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState(
    props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []
  );
  const [year, setYear] = useState();
  const [dose, setDose] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedDose, setSelectedDose] = useState();
  const [errorDose, setDoseError] = useState(false);
  const [errorYear, setYearError] = useState(false);
  const [fertilizerAdvice, setFertilizerAdvice] = useState("fertilizer_advice");
  const [cropFertilizer, setCropFertilizer] = useState(
    "crop_fertilizer" in props.selected_crop_detail[0]
      ? props.selected_crop_detail[0].crop_fertilizer
      : []
  );

  const getLangContent = (language) => {
    let data = props.language_list.findIndex((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data !== -1) {
      return data;
    } else {
      return 0;
    }
  };

  const getLanguageContent = (language) => {
    let data = props.language_list.filter((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data.length > 0) {
      return data[0].locale;
    } else {
      return "en_EN";
    }
  };

  useEffect(() => {
    setDoseError(errorDose);
    setYearError(errorYear);
    setFertilizerAdvice(fertilizerAdvice);
  }, [errorDose, errorYear, fertilizerAdvice]);

  useEffect(() => {
    setDose();
    setYear();
  }, []);

  useEffect(() => {
    setSelectedYear(selectedYear);
    setSelectedDose(selectedDose);
  }, [selectedYear, selectedDose]);

  useEffect(() => {
    setYear(year);
    setDose(dose);
  }, [year, dose]);

  useEffect(() => {
    let temp_year = [];
    let temp_dose = [];
    if ("crop_fertilizer" in props.selected_crop_detail[0]) {
      props.selected_crop_detail[0].crop_fertilizer.map((fertilizer, key) => {
        if (temp_year.includes(fertilizer.year) === false)
          temp_year.push(fertilizer.year);
        if (temp_dose.includes(key) === false) temp_dose.push(key);
      });
    } else {
      temp_year.push(1);
      temp_dose.push(1);
    }

    setYear(temp_year.length + 1);
    setDose(temp_dose.length + 1);
  }, []);

  useEffect(() => {
    let temp_dose1 = [];
    if ("id" in selectedFertilizer) {
      if ("crop_fertilizer" in props.selected_crop_detail[0]) {
        props.selected_crop_detail[0].crop_fertilizer.map((fertilizer) => {
          if (fertilizer.year === selectedFertilizer.year) {
            if (temp_dose1.includes(fertilizer.dose) === false)
              temp_dose1.push(fertilizer.dose);
          }
        });
      }
      setDose(temp_dose1.length + 1);
    }
  }, [selectedFertilizer, props.feretilizer_delete_loading]);

  useEffect(() => {
    setCheckedData(checkedData);
  }, [checkedData]);

  useEffect(() => {
    setData(data);
  }, [data]);

  useEffect(() => {
    let a = { ...cropFertilizer };
    let data = Object.values(a).sort((a, b) => {
      return a.year - b.year || a.dose - b.dose;
    });
    setCropFertilizer(data);
  }, []);

  useEffect(() => {
    if ("id" in selectedFertilizer) {
      let crop_text_data = [];

      let data = {};
      for (const [key, value] of Object.entries(selectedFertilizer)) {
        if (key !== "crop_fertilizer_text") {
          data[key] = value;
        }
      }
      setFertilizerData(data);

      selectedFertilizer.crop_fertilizer_text.map((crop_data_text) => {
        let data2 = {};
        for (const [key, value] of Object.entries(crop_data_text)) {
          data2[key] = value;
        }
        crop_text_data.push(data2);
      });
      setFertilizerDataText(crop_text_data);
    }
  }, [selectedFertilizer]);

  useEffect(() => {
    setFertilizerData(fertilizerData);
  }, [fertilizerData]);

  useEffect(() => {
    setFertilizerDataText(fertilizerDataText);
  }, [fertilizerDataText]);

  useEffect(() => {
    setSelectedFertilizer(props.selected_fertilizer);
  }, [props.selected_fertilizer]);

  const textChange = (name, text) => {
    if (name === "year") {
      setYearError(false);
    }
    if (name === "dose") {
      setDoseError(false);
    }
    let crop = { crop_id: props.selected_crop_id };
    let data1 = { [name]: text };
    let data2 =
      langContent in fertilizerData ? fertilizerData[langContent] : [];

    let language = { locale: languageContent };

    let data3 = { ...crop, ...data2, ...data1, ...language };

    let data = { ...fertilizerData, ...data3 };

    setFertilizerData(data);
  };

  const textChange1 = (name, text) => {
    let data1 = { [name]: text };
    let data2 =
      getLanguage() in fertilizerDataText
        ? fertilizerDataText[getLanguage()]
        : [];

    let language = { locale: languageContent };

    let comms = { comms: "" };
    let data3 = { ...data2, ...comms, ...data1, ...language };

    let language2 =
      getLanguage() === -1
        ? Object.keys(fertilizerDataText).length
        : getLanguage();

    let data4 = { [language2]: data3 };

    let data = { ...fertilizerDataText, ...data4 };

    setFertilizerDataText(data);
  };

  if (props.selected_crop_detail.length > 0) {
    if ("crop_fertilizer" in props.selected_crop_detail[0] == true) {
      if (props.selected_crop_detail[0].crop_fertilizer.length > 0) {
        props.selected_crop_detail[0].crop_fertilizer.sort((a, b) => {
          return a.dose - b.dose || a.year - b.dose;
        });
      }
    }
  }

  const onAddMoreButtonClick = () => {
    window.scrollTo(0, 0);

    let data1 = { fertilizerText: fertilizerDataText };

    if (Object.keys(selectedFertilizer).length > 0) {
      let cropFertilizer_id = { cropFertilizer_id: selectedFertilizer.id };
      let organisation = { organisation: props.organisation };
      let data = {
        ...cropFertilizer_id,
        ...fertilizerData,
        ...data1,
        ...organisation,
      };

      if ("dose" in data === true && "year" in data === true) {
        if (data.dose !== "Choose" && data.year !== "Choose") {
          setYearError(false);
          setDoseError(false);
          props.actions.registerFertilizer(data);
        } else {
          if (data.dose === "Choose") {
            setDoseError(true);
          }
          if (data.year === "Choose") {
            setYearError(true);
          }
        }
      } else {
        if ("dose" in data === true) {
          if (data.dose === "Choose") {
            setDoseError(true);
          }
        }
        if ("year" in data === true) {
          if (data.year === "Choose") {
            setYearError(true);
          }
        }
        if ("dose" in data === false && "year" in data === false) {
          setYearError(true);
          setDoseError(true);
        }
      }
    } else {
      let cropFertilizer_id = { cropFertilizer_id: "" };
      let organisation = { organisation: props.organisation };
      let data = {
        ...cropFertilizer_id,
        ...fertilizerData,
        ...data1,
        ...organisation,
      };

      if ("dose" in data === true && "year" in data === true) {
        if (data.dose !== "Choose" && data.year !== "Choose") {
          setYearError(false);
          setDoseError(false);

          props.actions.registerFertilizer(data);
        } else {
          if (data.dose === "Choose") {
            setDoseError(true);
          }
          if (data.year === "Choose") {
            setYearError(true);
          }
        }
      } else {
        if ("dose" in data === true) {
          if (data.dose === "Choose") {
            setDoseError(true);
          }
        }
        if ("year" in data === true) {
          if (data.year === "Choose") {
            setYearError(true);
          }
        }
        if ("dose" in data === false && "year" in data === false) {
          setYearError(true);
          setDoseError(true);
        }
      }
    }
  };

  const getLanguage = () => {
    let data0 = Object.values(fertilizerDataText).findIndex((temp_data) => {
      return temp_data.locale === languageContent;
    });
    return data0;
  };

  const getLanguage1 = () => {
    let data0 = Object.values(fertilizerDataText).findIndex((temp_data) => {
      return temp_data.locale === "en_EN";
    });
    return data0;
  };

  const getFertilizerLanguage = (data) => {
    let data0 = Object.values(data.crop_fertilizer_text).findIndex(
      (temp_data) => {
        return temp_data.locale === languageContent;
      }
    );
    return data0;
  };

  useEffect(() => {
    setSelectedFertilizer([]);
  }, []);

  useEffect(() => {
    setFertilizerDataText([]);
  }, [
    props.feretilizer_delete_loading,
    props.selected_crop_detail_loading,
    props.fertilizer_register_loading,
  ]);

  const getYear = () => {
    let temp_year = [];

    for (let index = 1; index <= year; index++) {
      temp_year.push(index);
    }
    return temp_year;
  };
  const getDose = () => {
    let temp_dose = [];

    for (let index = 1; index <= dose; index++) {
      temp_dose.push(index);
    }
    return temp_dose;
  };

  const editFertilizer = (fertilizer) => {
    document.getElementById("fertilizer_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizerUnit
        : "";
    document.getElementById("fomi1_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer1_name
        : "";
    document.getElementById("fomi1_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer1_unit
        : "";
    document.getElementById("fomi2_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer2_name
        : "";
    document.getElementById("fomi2_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer2_unit
        : "";
    document.getElementById("fomi3_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer3_name
        : "";
    document.getElementById("fomi3_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer3_unit
        : "";
    document.getElementById("fomi4_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer4_name
        : "";
    document.getElementById("fomi4_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent].fertilizer4_unit
        : "";

    document.getElementById("alternative_fertilizer1_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer1_name
        : "";
    document.getElementById("alternative_fertilizer1_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer1_unit
        : "";
    document.getElementById("alternative_fertilizer2_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer2_name
        : "";
    document.getElementById("alternative_fertilizer2_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer2_unit
        : "";
    document.getElementById("alternative_fertilizer3_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer3_name
        : "";
    document.getElementById("alternative_fertilizer3_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer3_unit
        : "";
    document.getElementById("alternative_fertilizer4_name").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer4_name
        : "";
    document.getElementById("alternative_fertilizer4_unit").value =
      langContent in fertilizer.crop_fertilizer_text
        ? fertilizer.crop_fertilizer_text[langContent]
            .alternative_fertilizer4_unit
        : "";

    document.getElementById("N").value = fertilizer.N;
    document.getElementById("P").value = fertilizer.P;
    document.getElementById("K").value = fertilizer.K;
    document.getElementById("urea_kg_are").value = fertilizer.mix1_kg_are;
    document.getElementById("dap_kg_are").value = fertilizer.mix2_kg_are;
    document.getElementById("kcl_kg_are").value = fertilizer.mix3_kg_are;
    document.getElementById("mix4_kg_are").value = fertilizer.mix4_kg_are;
    document.getElementById("nbr_urea_goblets").value =
      fertilizer.mix1_goblets_inmixture;
    document.getElementById("nbr_dap_goblets").value =
      fertilizer.mix2_goblets_inmixture;
    document.getElementById("nbr_kcl_goblets").value =
      fertilizer.mix3_goblets_inmixture;
    document.getElementById("mix4_goblets_inmixture").value =
      fertilizer.mix4_goblets_inmixture;
    document.getElementById("nbr_units_pockets").value =
      fertilizer.nbr_units_pockets;

    document.getElementById("fomi1_kg_are").value =
      fertilizer.fertilizer1_kg_are;
    document.getElementById("fomi1_unit_pocket").value =
      fertilizer.fertilizer1_unit_pocket;
    document.getElementById("fomi2_kg_are").value =
      fertilizer.fertilizer2_kg_are;
    document.getElementById("fomi2_unit_pocket").value =
      fertilizer.fertilizer2_unit_pocket;
    document.getElementById("fomi3_kg_are").value =
      fertilizer.fertilizer3_kg_are;
    document.getElementById("fomi3_unit_pocket").value =
      fertilizer.fertilizer3_unit_pocket;
    document.getElementById("fomi4_kg_are").value =
      fertilizer.fertilizer4_kg_are;
    document.getElementById("fomi4_unit_pocket").value =
      fertilizer.fertilizer4_unit_pocket;

    document.getElementById("alternative_fertilizer1_kg_are").value =
      fertilizer.altrnative_fertilizer1_kg_are;
    document.getElementById("alternative_fertilizer1_unit_pocket").value =
      fertilizer.alternative_fertilizer1_unit_pocket;

    document.getElementById("alternative_fertilizer2_kg_are").value =
      fertilizer.altrnative_fertilizer2_kg_are;
    document.getElementById("alternative_fertilizer2_unit_pocket").value =
      fertilizer.alternative_fertilizer2_unit_pocket;

    document.getElementById("alternative_fertilizer3_kg_are").value =
      fertilizer.altrnative_fertilizer3_kg_are;
    document.getElementById("alternative_fertilizer3_unit_pocket").value =
      fertilizer.alternative_fertilizer3_unit_pocket;

    document.getElementById("alternative_fertilizer4_kg_are").value =
      fertilizer.altrnative_fertilizer4_kg_are;
    document.getElementById("alternative_fertilizer4_unit_pocket").value =
      fertilizer.alternative_fertilizer4_unit_pocket;
  };

  var langContent = getLangContent(props.lang);
  var languageContent = getLanguageContent(props.lang);

  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  let to_org = GetToOrganisation(props.mapping_list, props.auth);

  const renderModal = () => {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          closeModal();
        }}
        style={customStyles}
      >
        <h4>Do you really want to delete selected fertilizer?</h4>
        <div className="modalBox">
          <div className="leftModal">
            <div
              className="modalButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeModal();
                props.actions.deleteFertilizerRequest(
                  props.selected_fertilizer.id,
                  props.organisation
                );
              }}
            >
              Yes
            </div>
          </div>

          <div className="rightModal">
            <div
              className="modalButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeModal();
              }}
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderFertilizer = (fertilizer) => {
    return (
      <>
        <td
          rowSpan="9"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <img
            src={require("../../../../images/edit.png")}
            alt="edit"
            style={{ width: 20, height: 20, cursor: "pointer" }}
            onClick={() => {
              document.getElementById("fertilizer_unit").value = "";
              document.getElementById("fomi1_name").value = "";
              document.getElementById("fomi1_unit").value = "";
              document.getElementById("fomi2_name").value = "";
              document.getElementById("fomi2_unit").value = "";
              document.getElementById("fomi3_name").value = "";
              document.getElementById("fomi3_unit").value = "";
              document.getElementById("fomi4_name").value = "";
              document.getElementById("fomi4_unit").value = "";
              document.getElementById("N").value = "";
              document.getElementById("P").value = "";
              document.getElementById("K").value = "";
              document.getElementById("urea_kg_are").value = "";
              document.getElementById("dap_kg_are").value = "";
              document.getElementById("kcl_kg_are").value = "";
              document.getElementById("nbr_urea_goblets").value = "";
              document.getElementById("nbr_dap_goblets").value = "";
              document.getElementById("nbr_kcl_goblets").value = "";
              document.getElementById("nbr_units_pockets").value = "";
              document.getElementById("fomi1_name").value = "";
              document.getElementById("fomi1_kg_are").value = "";
              document.getElementById("fomi1_unit_pocket").value = "";
              document.getElementById("fomi2_kg_are").value = "";
              document.getElementById("fomi2_unit_pocket").value = "";
              document.getElementById("fomi2_kg_are").value = "";
              document.getElementById("fomi3_unit_pocket").value = "";
              document.getElementById("fomi4_kg_are").value = "";
              document.getElementById("fomi4_unit_pocket").value = "";

              document.getElementById("alternative_fertilizer1_name").value =
                "";
              document.getElementById("alternative_fertilizer1_unit").value =
                "";
              document.getElementById("alternative_fertilizer2_name").value =
                "";
              document.getElementById("alternative_fertilizer2_unit").value =
                "";
              document.getElementById("alternative_fertilizer3_name").value =
                "";
              document.getElementById("alternative_fertilizer3_unit").value =
                "";
              document.getElementById("alternative_fertilizer4_name").value =
                "";
              document.getElementById("alternative_fertilizer4_unit").value =
                "";

              document.getElementById("alternative_fertilizer1_kg_are").value =
                "";
              document.getElementById(
                "alternative_fertilizer1_unit_pocket"
              ).value = "";
              document.getElementById("alternative_fertilizer2_kg_are").value =
                "";
              document.getElementById(
                "alternative_fertilizer2_unit_pocket"
              ).value = "";
              document.getElementById("alternative_fertilizer3_kg_are").value =
                "";
              document.getElementById(
                "alternative_fertilizer3_unit_pocket"
              ).value = "";
              document.getElementById("alternative_fertilizer4_kg_are").value =
                "";
              document.getElementById(
                "alternative_fertilizer4_unit_pocket"
              ).value = "";

              setDoseError(false);
              setYearError(false);
              setSelectedDose(fertilizer.dose);
              editFertilizer(fertilizer);
              setSelectedYear(fertilizer.year);
              props.actions.selectFertilizer(fertilizer);
              window.scrollTo(0, 0);
            }}
          />
        </td>
        <td
          rowSpan="9"
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <img
            src={require("../../../../images/delete.png")}
            alt="delete"
            style={{ width: 20, height: 20, cursor: "pointer" }}
            onClick={() => {
              setDoseError(false);
              setYearError(false);
              setSelectedFertilizer([]);
              setSelectedYear("");
              setSelectedDose("");
              props.actions.selectFertilizer(fertilizer);
              openModal();
            }}
          />
        </td>
      </>
    );
  };

  return (
    <div className="mediaContainer">
      <Header header={false} />

      <Breadcrumb />

      <div className="generalBody">
        <ToastContainer />
        {props.feretilizer_delete_loading === true ||
        props.selected_crop_detail_loading === true ||
        props.fertilizer_register_loading === true ? (
          <Spinner />
        ) : (
          <div className="formCard1">
            <div className="fomiContainer">
              <div className="leftFormCard">
                <div className="TextBoxContainer">
                  <div className="leftSelectLabelContainer2">
                    <span>Year</span>
                  </div>

                  <div className="rightSelectLabelContainer2">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {errorYear === true && (
                        <span className="errorText">Year is required.</span>
                      )}
                      <select
                        className="selectInput"
                        name="year"
                        value={selectedYear}
                        onChange={(e) => {
                          let temp_dose = [];
                          if (
                            "crop_fertilizer" in props.selected_crop_detail[0]
                          ) {
                            props.selected_crop_detail[0].crop_fertilizer.map(
                              (fertilizer) => {
                                if (fertilizer.year == e.target.value) {
                                  if (
                                    temp_dose.includes(fertilizer.dose) ===
                                    false
                                  ) {
                                    temp_dose.push(fertilizer.dose);
                                  }
                                }
                              }
                            );
                            if (temp_dose.length > 0) {
                              setDose(temp_dose.length + 1);
                            } else {
                              setDose(1);
                            }
                          }
                          setSelectedYear(e.target.value);
                          textChange("year", e.target.value);
                        }}
                      >
                        <option>Choose</option>
                        {getYear().map((index, key) => {
                          return (
                            <option key={index} value={index}>
                              {index}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="TextBoxContainer">
                  <div className="leftSelectLabelContainer2">
                    <span>Dose</span>
                  </div>

                  <div className="rightSelectLabelContainer2">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {errorDose === true && (
                        <span className="errorText">Dose is required.</span>
                      )}
                      <select
                        className="selectInput"
                        name="dose"
                        value={selectedDose}
                        onChange={(e) => {
                          setSelectedDose(e.target.value);
                          textChange("dose", e.target.value);
                        }}
                      >
                        <option>Choose</option>
                        {getDose().map((index, key) => {
                          return (
                            <option key={index} value={index}>
                              {index}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rightFormCard">
                <TextBox
                  id="N"
                  label="N"
                  name="N"
                  onTextChange={textChange}
                  fertilizer={true}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.N
                      : undefined
                  }
                />
                <TextBox
                  id="P"
                  label="P"
                  name="P"
                  onTextChange={textChange}
                  fertilizer={true}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.P
                      : undefined
                  }
                />
                <TextBox
                  id="K"
                  label="K"
                  name="K"
                  onTextChange={textChange}
                  fertilizer={true}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.K
                      : undefined
                  }
                />
              </div>
            </div>

            <div className="fomiContainer">
              <div className="leftFormCard1">
                {languageLength.map((lang, key) => {
                  return (
                    langContent === lang && (
                      <div key={key}>
                        {"id" in selectedFertilizer ? (
                          <TextBox
                            id="mix1_name"
                            label="Mix 1 name"
                            name="mix1_name"
                            onTextChange1={textChange1}
                            fertilizer_mix={true}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix1_name
                                : "id" in props.selected_fertilizer
                                ? getLanguage() in
                                  props.selected_fertilizer.crop_fertilizer_text
                                  ? selectedFertilizer.crop_fertilizer_text[
                                      getLanguage()
                                    ].mix1_name
                                  : undefined
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix1_name
                                  : "id" in props.selected_fertilizer
                                  ? key in
                                    props.selected_fertilizer
                                      .crop_fertilizer_text
                                    ? props.selected_fertilizer
                                        .crop_fertilizer_text[key].mix1_name
                                    : undefined
                                  : undefined
                                : undefined
                            }
                          />
                        ) : (
                          <TextBox
                            id="mix1_name"
                            label="Mix 1 name"
                            fertilizer_mix={true}
                            name="mix1_name"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix1_name
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix1_name
                                  : undefined
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )
                  );
                })}

                {languageLength.map((lang, key) => {
                  return (
                    langContent === lang && (
                      <div key={key}>
                        {"id" in selectedFertilizer ? (
                          <TextBox
                            id="mix2_name"
                            label="Mix 2 name"
                            name="mix2_name"
                            onTextChange1={textChange1}
                            fertilizer_mix={true}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix2_name
                                : "id" in props.selected_fertilizer
                                ? getLanguage() in
                                  props.selected_fertilizer.crop_fertilizer_text
                                  ? selectedFertilizer.crop_fertilizer_text[
                                      getLanguage()
                                    ].mix2_name
                                  : undefined
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix2_name
                                  : "id" in props.selected_fertilizer
                                  ? key in
                                    props.selected_fertilizer
                                      .crop_fertilizer_text
                                    ? props.selected_fertilizer
                                        .crop_fertilizer_text[key].mix2_name
                                    : undefined
                                  : undefined
                                : undefined
                            }
                          />
                        ) : (
                          <TextBox
                            id="mix2_name"
                            label="Mix 2 name"
                            fertilizer_mix={true}
                            name="mix2_name"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix2_name
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix2_name
                                  : undefined
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )
                  );
                })}

                {languageLength.map((lang, key) => {
                  return (
                    langContent === lang && (
                      <div key={key}>
                        {"id" in selectedFertilizer ? (
                          <TextBox
                            id="mix3_name"
                            label="Mix 3 name"
                            name="mix3_name"
                            onTextChange1={textChange1}
                            fertilizer_mix={true}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix3_name
                                : "id" in props.selected_fertilizer
                                ? getLanguage() in
                                  props.selected_fertilizer.crop_fertilizer_text
                                  ? selectedFertilizer.crop_fertilizer_text[
                                      getLanguage()
                                    ].mix3_name
                                  : undefined
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix3_name
                                  : "id" in props.selected_fertilizer
                                  ? key in
                                    props.selected_fertilizer
                                      .crop_fertilizer_text
                                    ? props.selected_fertilizer
                                        .crop_fertilizer_text[key].mix3_name
                                    : undefined
                                  : undefined
                                : undefined
                            }
                          />
                        ) : (
                          <TextBox
                            id="mix3_name"
                            label="Mix 3 name"
                            fertilizer_mix={true}
                            name="mix3_name"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix3_name
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix3_name
                                  : undefined
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )
                  );
                })}

                {languageLength.map((lang, key) => {
                  return (
                    langContent === lang && (
                      <div key={key}>
                        {"id" in selectedFertilizer ? (
                          <TextBox
                            id="mix4_name"
                            label="Mix 4 name"
                            name="mix4_name"
                            onTextChange1={textChange1}
                            fertilizer_mix={true}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix4_name
                                : "id" in props.selected_fertilizer
                                ? getLanguage() in
                                  props.selected_fertilizer.crop_fertilizer_text
                                  ? selectedFertilizer.crop_fertilizer_text[
                                      getLanguage()
                                    ].mix4_name
                                  : undefined
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix4_name
                                  : "id" in props.selected_fertilizer
                                  ? key in
                                    props.selected_fertilizer
                                      .crop_fertilizer_text
                                    ? props.selected_fertilizer
                                        .crop_fertilizer_text[key].mix4_name
                                    : undefined
                                  : undefined
                                : undefined
                            }
                          />
                        ) : (
                          <TextBox
                            id="mix4_name"
                            label="Mix 4 name"
                            fertilizer_mix={true}
                            name="mix4_name"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()].mix4_name
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()].mix4_name
                                  : undefined
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )
                  );
                })}
              </div>

              <div className="middleFormCard1">
                <TextBox
                  id="urea_kg_are"
                  label={`Mix 1 ${GetUnitFromSource(props.units, "mix_1")}`}
                  name="mix1_kg_are"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix1_kg_are
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="dap_kg_are"
                  label={`Mix 1 ${GetUnitFromSource(props.units, "mix_2")}`}
                  name="mix2_kg_are"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix2_kg_are
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="kcl_kg_are"
                  label={`Mix 1 ${GetUnitFromSource(props.units, "mix_3")}`}
                  name="mix3_kg_are"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix3_kg_are
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="mix4_kg_are"
                  label={`Mix 1 ${GetUnitFromSource(props.units, "mix_4")}`}
                  name="mix4_kg_are"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix4_kg_are
                      : undefined
                  }
                  fertilizer_mix={true}
                />
              </div>

              <div className="rightFormCard1">
                <TextBox
                  id="nbr_urea_goblets"
                  label="Mix 1 goblets in mixture"
                  name="mix1_goblets_inmixture"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix1_goblets_in_mixture
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="nbr_dap_goblets"
                  label="Mix 2 goblets in mixture"
                  name="mix2_goblets_inmixture"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix2_goblets_in_mixture
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="nbr_kcl_goblets"
                  label="Mix 3 goblets in mixture"
                  name="mix3_goblets_inmixture"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix3_goblets_in_mixture
                      : undefined
                  }
                  fertilizer_mix={true}
                />

                <TextBox
                  id="mix4_goblets_inmixture"
                  label="Mix 4 goblets in mixture"
                  name="mix4_goblets_inmixture"
                  onTextChange={textChange}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.mix4_goblets_in_mixture
                      : undefined
                  }
                  fertilizer_mix={true}
                />
              </div>
            </div>

            <div className="fomiContainer">
              <div className="leftFormCard">
                {languageLength.map((lang, key) => {
                  return (
                    langContent === lang && (
                      <div key={key}>
                        {"id" in selectedFertilizer ? (
                          <TextBox
                            label="Fertilizer Unit"
                            id="fertilizer_unit"
                            fertilizer={true}
                            name="fertilizerUnit"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()]
                                    .fertilizerUnit
                                : "id" in props.selected_fertilizer
                                ? getLanguage() in
                                  props.selected_fertilizer.crop_fertilizer_text
                                  ? selectedFertilizer.crop_fertilizer_text[
                                      getLanguage()
                                    ].fertilizerUnit
                                  : undefined
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()]
                                      .fertilizerUnit
                                  : "id" in props.selected_fertilizer
                                  ? key in
                                    props.selected_fertilizer
                                      .crop_fertilizer_text
                                    ? props.selected_fertilizer
                                        .crop_fertilizer_text[key]
                                        .fertilizerUnit
                                    : undefined
                                  : undefined
                                : undefined
                            }
                          />
                        ) : (
                          <TextBox
                            label="Fertilizer Unit"
                            id="fertilizer_unit"
                            fertilizer={true}
                            name="fertilizerUnit"
                            onTextChange1={textChange1}
                            value={
                              getLanguage() in fertilizerDataText
                                ? fertilizerDataText[getLanguage()]
                                    .fertilizerUnit
                                : undefined
                            }
                            value1={
                              languageContent !== "en_EN"
                                ? getLanguage1() in fertilizerDataText
                                  ? fertilizerDataText[getLanguage1()]
                                      .fertilizerUnit
                                  : undefined
                                : undefined
                            }
                          />
                        )}
                      </div>
                    )
                  );
                })}
              </div>

              <div className="rightFormCard">
                <TextBox
                  id="nbr_units_pockets"
                  label="Nbr of units in pocket"
                  name="nbr_units_pockets"
                  onTextChange={textChange}
                  fertilizer={true}
                  value={
                    "id" in selectedFertilizer
                      ? selectedFertilizer.nbr_units_pockets
                      : undefined
                  }
                />
              </div>
            </div>

            {
              <>
                <div className="fertilizerFomi" style={{ paddingTop: "20px" }}>
                  <div className="leftFertilizerFomi">
                    <span className="leftFertilizerFomiText1"></span>
                    <span className="leftFertilizerFomiText">
                      Fertilizer 1 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Fertilizer 2 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Fertilizer 3 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Fertilizer 4 Name
                    </span>
                  </div>

                  <div className="rightFertilizerFomi">
                    {/* Fomi1 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  id="fomi1_name"
                                  label="Fomi1 Name"
                                  type="fertilizer"
                                  unit="Name"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="fertilizer1_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer1_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        props.selected_fertilizer
                                          .crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer1_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer1_name
                                        : "id" in props.selected_fertilizer
                                        ? key in
                                          props.selected_fertilizer
                                            .crop_fertilizer_text
                                          ? props.selected_fertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer1_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  id="fomi1_name"
                                  label="Fertilizer 1 Name"
                                  type="fertilizer"
                                  unit="Name"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="fertilizer1_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer1_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer1_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="fomi1_kg_are"
                        label="Fomi1 kg/Are"
                        name="fertilizer1_kg_are"
                        onTextChange={textChange}
                        type="fertilizer"
                        unit={GetUnitFromSource(
                          props.units,
                          "fertilizer_1_name"
                        )}
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer1_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer1_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fomi1 Unit"
                                  id="fomi1_unit"
                                  name="fertilizer1_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  unit="Unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer1_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer1_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer1_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer1_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi1 Unit"
                                  id="fomi1_unit"
                                  name="fertilizer1_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  unit="Unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer1_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer1_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="fomi1_unit_pocket"
                        label="Fomi1 Unit/Pocket"
                        type="fertilizer"
                        name="fertilizer1_unit_pocket"
                        onTextChange={textChange}
                        unit={GetUnitFromSource(
                          props.units,
                          "fertilizer_1_unit"
                        )}
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer1_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer1_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi2 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fertilizer 2 Name"
                                  id="fomi2_name"
                                  name="fertilizer2_name"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer2_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer2_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer2_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer2_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi2 Name"
                                  id="fomi2_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="fertilizer2_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer2_name
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="fomi2_kg_are"
                        label="Fomi2 kg/Are"
                        name="fertilizer2_kg_are"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer2_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer2_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fomi2 Unit"
                                  type="fertilizer"
                                  name="fertilizer2_unit"
                                  id="fomi2_unit"
                                  onTextChange1={textChange1}
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer2_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer2_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer2_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer2_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi2 Unit"
                                  type="fertilizer"
                                  name="fertilizer2_unit"
                                  id="fomi2_unit"
                                  onTextChange1={textChange1}
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer2_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer2_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="fomi2_unit_pocket"
                        label="Fomi2 Unit/Pocket"
                        name="fertilizer2_unit_pocket"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer2_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer2_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi3 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fertilizer 3 Name"
                                  name="fertilizer3_name"
                                  id="fomi3_name"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer3_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer3_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer3_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer3_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi3 Name"
                                  name="fertilizer3_name"
                                  id="fomi3_name"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer3_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer3_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="fomi3_kg_are"
                        label="Fomi3 kg/Are"
                        name="fertilizer3_kg_are"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer3_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer3_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fomi3 Unit"
                                  name="fertilizer3_unit"
                                  id="fomi3_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer3_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer3_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer3_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer3_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi3 Unit"
                                  name="fertilizer3_unit"
                                  id="fomi3_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer3_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer3_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="fomi3_unit_pocket"
                        label="Fomi3 Unit/Pocket"
                        name="fertilizer3_unit_pocket"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer3_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer3_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi4 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fertilizer 4 Name"
                                  name="fertilizer4_name"
                                  id="fomi4_name"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer4_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer4_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    props.langauge1 !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer4_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer4_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi4 Name"
                                  name="fertilizer4_name"
                                  id="fomi4_name"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer4_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer4_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="fomi4_kg_are"
                        label="Fomi4 kg/Are"
                        name="fertilizer4_kg_are"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer4_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer4_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Fomi4 Unit"
                                  name="fertilizer4_unit"
                                  id="fomi4_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer4_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .fertilizer4_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer4_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .fertilizer4_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Fomi4 Unit"
                                  name="fertilizer4_unit"
                                  id="fomi4_unit"
                                  onTextChange1={textChange1}
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .fertilizer4_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .fertilizer4_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="fomi4_unit_pocket"
                        label="Fomi4 Unit/Pocket"
                        name="fertilizer4_unit_pocket"
                        onTextChange={textChange}
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.fertilizer4_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.fertilizer4_unit_pocket
                            : undefined
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="fertilizerFomi" style={{ paddingTop: "20px" }}>
                  <div className="leftFertilizerFomi">
                    <span className="leftFertilizerFomiText1"></span>
                    <span className="leftFertilizerFomiText">
                      Alternative Fertilizer 1 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Alternative Fertilizer 2 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Alternative Fertilizer 3 Name
                    </span>
                    <span className="leftFertilizerFomiText">
                      Alternative Fertilizer 4 Name
                    </span>
                  </div>

                  <div className="rightFertilizerFomi">
                    {/* Fomi1 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  id="alternative_fertilizer1_name"
                                  label="Alternative Fertilizer1 Name"
                                  type="fertilizer"
                                  unit="Name"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="alternative_fertilizer1_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer1_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer1_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer1_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer1_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  id="alternative_fertilizer1_name"
                                  label="Alternative Fertilizer1 Name"
                                  type="fertilizer"
                                  unit="Name"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="alternative_fertilizer1_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer1_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer1_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="alternative_fertilizer1_kg_are"
                        label="Alternative Fertilizeri1 kg/Are"
                        name="altrnative_fertilizer1_kg_are"
                        type="fertilizer"
                        unit={GetUnitFromSource(
                          props.units,
                          "alternative_mix_1"
                        )}
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer1_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer1_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer1 Unit"
                                  id="alternative_fertilizer1_unit"
                                  name="alternative_fertilizer1_unit"
                                  type="fertilizer"
                                  unit="Unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer1_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer1_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer1_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer1_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer1 Unit"
                                  id="alternative_fertilizer1_unit"
                                  name="alternative_fertilizer1_unit"
                                  type="fertilizer"
                                  unit="Unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer1_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer1_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="alternative_fertilizer1_unit_pocket"
                        label="Alternative Fertilizer1 Unit/Pocket"
                        type="fertilizer"
                        name="alternative_fertilizer1_unit_pocket"
                        unit={GetUnitFromSource(
                          props.units,
                          "fertilizer_1_unit"
                        )}
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer1_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer1_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi2 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer 2 Name"
                                  id="alternative_fertilizer2_name"
                                  name="alternative_fertilizer2_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer2_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer2_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer2_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer2_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer 2 Name"
                                  id="alternative_fertilizer2_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  name="alternative_fertilizer2_name"
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer2_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer2_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="alternative_fertilizer2_kg_are"
                        label="Alternative Fertilizer2 kg/Are"
                        name="altrnative_fertilizer2_kg_are"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer2_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer2_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer2 Unit"
                                  type="fertilizer"
                                  name="alternative_fertilizer2_unit"
                                  id="alternative_fertilizer2_unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer2_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer2_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer2_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer2_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer2 Unit"
                                  type="fertilizer"
                                  name="alternative_fertilizer2_unit"
                                  id="alternative_fertilizer2_unit"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer2_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer2_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="alternative_fertilizer2_unit_pocket"
                        label="Alternative Fertilizer2 Unit/Pocket"
                        name="alternative_fertilizer2_unit_pocket"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer2_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer2_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi3 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer 3 Name"
                                  name="alternative_fertilizer3_name"
                                  id="alternative_fertilizer3_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer3_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer3_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer3_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer3_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer3 Name"
                                  name="alternative_fertilizer3_name"
                                  id="alternative_fertilizer3_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer3_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer3_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="alternative_fertilizer3_kg_are"
                        label="Alternative Fertilizer3 kg/Are"
                        name="altrnative_fertilizer3_kg_are"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer3_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer3_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer3 Unit"
                                  name="alternative_fertilizer3_unit"
                                  id="alternative_fertilizer3_unit"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer3_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer3_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer3_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer3_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer3 Unit"
                                  name="alternative_fertilizer3_unit"
                                  id="alternative_fertilizer3_unit"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer3_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer3_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="alternative_fertilizer3_unit_pocket"
                        label="Alternative Fertilizer3 Unit/Pocket"
                        name="alternative_fertilizer3_unit_pocket"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer3_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer3_unit_pocket
                            : undefined
                        }
                      />
                    </div>

                    {/* Fomi4 */}
                    <div className="fomiTextBoxContainer">
                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer4 Name"
                                  name="alternative_fertilizer4_name"
                                  id="alternative_fertilizer4_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer4_name
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer4_name
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer4_name
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer4_name
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer4 Name"
                                  name="alternative_fertilizer4_name"
                                  id="alternative_fertilizer4_name"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer4_name
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer4_name
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}
                      <TextBox
                        id="alternative_fertilizer4_kg_are"
                        label="ALternative Fertilizer4 kg/Are"
                        name="altrnative_fertilizer4_kg_are"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer4_kg_are
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer4_kg_are
                            : undefined
                        }
                      />

                      {languageLength.map((lang, key) => {
                        return (
                          langContent === lang && (
                            <div key={key}>
                              {"id" in selectedFertilizer ? (
                                <TextBox
                                  label="Alternative Fertilizer4 Unit"
                                  name="alternative_fertilizer4_unit"
                                  id="alternative_fertilizer4_unit"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer4_unit
                                      : "id" in selectedFertilizer
                                      ? getLanguage() in
                                        selectedFertilizer.crop_fertilizer_text
                                        ? selectedFertilizer
                                            .crop_fertilizer_text[getLanguage()]
                                            .alternative_fertilizer4_unit
                                        : undefined
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer4_unit
                                        : "id" in selectedFertilizer
                                        ? key in
                                          selectedFertilizer.crop_fertilizer_text
                                          ? selectedFertilizer
                                              .crop_fertilizer_text[key]
                                              .alternative_fertilizer4_unit
                                          : undefined
                                        : undefined
                                      : undefined
                                  }
                                />
                              ) : (
                                <TextBox
                                  label="Alternative Fertilizer4 Unit"
                                  name="alternative_fertilizer4_unit"
                                  id="alternative_fertilizer4_unit"
                                  type="fertilizer"
                                  isFirst={true}
                                  fomi1={false}
                                  fomiContent={true}
                                  onTextChange1={textChange1}
                                  value={
                                    getLanguage() in fertilizerDataText
                                      ? fertilizerDataText[getLanguage()]
                                          .alternative_fertilizer4_unit
                                      : undefined
                                  }
                                  value1={
                                    languageContent !== "en_EN"
                                      ? getLanguage1() in fertilizerDataText
                                        ? fertilizerDataText[getLanguage1()]
                                            .alternative_fertilizer4_unit
                                        : undefined
                                      : undefined
                                  }
                                />
                              )}
                            </div>
                          )
                        );
                      })}

                      <TextBox
                        id="alternative_fertilizer4_unit_pocket"
                        label="Alternative Fertilizer4 Unit/Pocket"
                        name="alternative_fertilizer4_unit_pocket"
                        type="fertilizer"
                        isFirst={true}
                        fomi1={false}
                        onTextChange={textChange}
                        value={
                          "id" in selectedFertilizer
                            ? selectedFertilizer.alternative_fertilizer4_unit_pocket
                            : "crop_id" in fertilizerData
                            ? fertilizerData.alternative_fertilizer4_unit_pocket
                            : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            }

            {props.user_role_type !== "Viewer" &&
            props.organisation === to_org ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="leftSelectLabelContainer1"></div>

                <div className="rightSelectLabelContainer1">
                  <CustomButton
                    name={`Save Fertilizer`}
                    onSaveButtonClick={onAddMoreButtonClick}
                  />
                </div>
              </div>
            ) : (
              props.user_role_type !== "Viewer" &&
              props.umva_id === props.superuser && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="leftSelectLabelContainer1"></div>

                  <div className="rightSelectLabelContainer1">
                    <CustomButton
                      name={`Save Fertilizer`}
                      onSaveButtonClick={onAddMoreButtonClick}
                    />
                  </div>
                </div>
              )
            )}
            <table
              className="tableContainer"
              cellSpacing="0"
              style={{ width: "100%" }}
            >
              {"crop_fertilizer" in props.selected_crop_detail[0] &&
                cropFertilizer.map((fertilizer, key) => {
                  return (
                    <>
                      {key === 0 && (
                        <thead>
                          <tr>
                            <th>Year</th>
                            <th>Dose</th>
                            <th></th>
                            <th></th>
                            <th> </th>
                            <th colSpan="2"></th>
                            {props.user_role_type !== "Viewer" &&
                            props.organisation === to_org ? (
                              <>
                                <th className="stickyCol">
                                  <span>Edit</span>
                                </th>
                                <th className="stickyCol">
                                  <span>Delete</span>
                                </th>
                              </>
                            ) : (
                              props.user_role_type !== "Viewer" &&
                              props.umva_id === props.superuser && (
                                <>
                                  <th className="stickyCol">
                                    <span>Edit</span>
                                  </th>
                                  <th className="stickyCol">
                                    <span>Delete</span>
                                  </th>
                                </>
                              )
                            )}
                          </tr>
                        </thead>
                      )}
                      <tbody
                        key={key}
                        className={
                          "id" in fertilizer
                            ? fertilizer.id === selectedFertilizer.id
                              ? "selectedRow"
                              : ""
                            : ""
                        }
                      >
                        <tr>
                          <td>{fertilizer.year}</td>
                          <td>{fertilizer.dose}</td>
                          <td>
                            {fertilizer.mix1_kg_are !== null &&
                            fertilizer.mix1_kg_are !== "NULL" &&
                            fertilizer.mix1_kg_are !== "0"
                              ? fertilizer.mix1_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_1_name"
                                )}`
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].mix1_name !== "" &&
                                fertilizer.mix1_kg_are !== null &&
                                fertilizer.mix1_kg_are !== "NULL" &&
                                fertilizer.mix1_kg_are !== "0"
                                ? ` of ${
                                    fertilizer.crop_fertilizer_text[
                                      getFertilizerLanguage(fertilizer)
                                    ].mix1_name
                                  }`
                                : undefined
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.mix2_kg_are !== null &&
                            fertilizer.mix2_kg_are !== "NULL" &&
                            fertilizer.mix2_kg_are !== "0"
                              ? fertilizer.mix2_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_2_name"
                                )}`
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].mix2_name !== "" &&
                                fertilizer.mix2_kg_are !== null &&
                                fertilizer.mix2_kg_are !== "NULL" &&
                                fertilizer.mix2_kg_are !== "0"
                                ? ` of ${
                                    fertilizer.crop_fertilizer_text[
                                      getFertilizerLanguage(fertilizer)
                                    ].mix2_name
                                  }`
                                : undefined
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.mix3_kg_are !== null &&
                            fertilizer.mix3_kg_are !== "NULL" &&
                            fertilizer.mix3_kg_are !== "0"
                              ? fertilizer.mix3_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_3_name"
                                )}`
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].mix3_name !== "" &&
                                fertilizer.mix3_kg_are !== null &&
                                fertilizer.mix3_kg_are !== "NULL" &&
                                fertilizer.mix3_kg_are !== "0"
                                ? ` of ${
                                    fertilizer.crop_fertilizer_text[
                                      getFertilizerLanguage(fertilizer)
                                    ].mix3_name
                                  }`
                                : undefined
                              : undefined}
                          </td>
                          <td colSpan="2">
                            {fertilizer.mix4_kg_are !== null &&
                            fertilizer.mix4_kg_are !== "NULL" &&
                            fertilizer.mix4_kg_are !== "0"
                              ? fertilizer.mix4_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_4_name"
                                )}`
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].mix4_name !== "" &&
                                fertilizer.mix4_kg_are !== null &&
                                fertilizer.mix4_kg_are !== "NULL" &&
                                fertilizer.mix4_kg_are !== "0"
                                ? ` of ${
                                    fertilizer.crop_fertilizer_text[
                                      getFertilizerLanguage(fertilizer)
                                    ].mix4_name
                                  }`
                                : undefined
                              : undefined}
                          </td>
                          {props.user_role_type !== "Viewer" &&
                          props.organisation === to_org
                            ? renderFertilizer(fertilizer)
                            : props.user_role_type !== "Viewer" &&
                              props.umva_id === props.superuser &&
                              renderFertilizer(fertilizer)}
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                          <td>
                            {fertilizer.mix1_goblets_inmixture !== null &&
                            fertilizer.mix1_goblets_inmixture !== "NULL" &&
                            fertilizer.mix1_goblets_inmixture !== "0"
                              ? fertilizer.mix1_goblets_inmixture +
                                I18n.t("Bean goblets")
                              : null}
                          </td>
                          <td>
                            {fertilizer.mix1_goblets_inmixture !== null &&
                            fertilizer.mix2_goblets_inmixture !== "NULL" &&
                            fertilizer.mix2_goblets_inmixture !== "0"
                              ? fertilizer.mix2_goblets_inmixture +
                                I18n.t("Bean goblets")
                              : null}
                          </td>
                          <td>
                            {fertilizer.mix3_goblets_inmixture !== null &&
                            fertilizer.mix3_goblets_inmixture !== "NULL" &&
                            fertilizer.mix3_goblets_inmixture !== "0"
                              ? fertilizer.mix3_goblets_inmixture +
                                I18n.t("Bean goblets")
                              : null}
                          </td>
                          <td>
                            {fertilizer.mix4_goblets_inmixture !== null &&
                            fertilizer.mix4_goblets_inmixture !== "NULL" &&
                            fertilizer.mix4_goblets_inmixture !== "0"
                              ? fertilizer.mix4_goblets_inmixture +
                                I18n.t("Bean goblets")
                              : null}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="3"></td>
                          <td colSpan="3">
                            {fertilizer.nbr_units_pockets !== null &&
                            fertilizer.nbr_units_pockets !== "NULL" &&
                            fertilizer.nbr_units_pockets !== "0"
                              ? fertilizer.nbr_units_pockets + " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizerUnit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizerUnit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizerUnit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizerUnit
                                : undefined
                              : undefined}
                          </td>
                        </tr>

                        {/* Fertilizer advice */}
                        <tr>
                          <td colSpan="2">Fertilizer Advice</td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer1_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer2_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer3_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer4_name
                                : null
                              : undefined}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                          <td>
                            {fertilizer.fertilizer1_kg_are !== null &&
                            fertilizer.fertilizer1_kg_are !== "NULL" &&
                            fertilizer.fertilizer1_kg_are !== "0"
                              ? fertilizer.fertilizer1_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_1_name"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.fertilizer2_kg_are !== null &&
                            fertilizer.fertilizer2_kg_are !== "NULL" &&
                            fertilizer.fertilizer2_kg_are !== "0"
                              ? fertilizer.fertilizer2_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_2_name"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.fertilizer3_kg_are !== null &&
                            fertilizer.fertilizer3_kg_are !== "NULL" &&
                            fertilizer.fertilizer3_kg_are !== "0"
                              ? fertilizer.fertilizer3_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_3_name"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.fertilizer4_kg_are !== null &&
                            fertilizer.fertilizer4_kg_are !== "NULL" &&
                            fertilizer.fertilizer4_kg_are !== "0"
                              ? fertilizer.fertilizer4_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "fertilizer_4_name"
                                )}`
                              : null}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                          <td>
                            {fertilizer.fertilizer1_unit_pocket !== null &&
                            fertilizer.fertilizer1_unit_pocket !== "NULL" &&
                            fertilizer.fertilizer1_unit_pocket !== "0"
                              ? fertilizer.fertilizer1_unit_pocket + " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer1_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer1_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.fertilizer2_unit_pocket !== null &&
                            fertilizer.fertilizer2_unit_pocket !== "NULL" &&
                            fertilizer.fertilizer2_unit_pocket !== "0"
                              ? fertilizer.fertilizer2_unit_pocket + " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer2_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer2_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.fertilizer3_unit_pocket !== null &&
                            fertilizer.fertilizer3_unit_pocket !== "NULL" &&
                            fertilizer.fertilizer3_unit_pocket !== "0"
                              ? (fertilizer.fertilizer3_unit_pocket = " ")
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer3_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer3_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.fertilizer4_unit_pocket !== null &&
                            fertilizer.fertilizer4_unit_pocket !== "NULL" &&
                            fertilizer.fertilizer4_unit_pocket !== "0"
                              ? fertilizer.fertilizer4_unit_pocket + " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].fertilizer4_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].fertilizer4_unit
                                : null
                              : undefined}
                          </td>
                        </tr>

                        {/* Traditional Fertilizer advice */}
                        <tr>
                          <td colSpan="2">Traditional Fertilizer Advice</td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer1_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_ertilizer2_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer2_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer2_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer2_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer3_name
                                : null
                              : undefined}
                          </td>
                          <td>
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_name !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_name !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_name !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer4_name
                                : null
                              : undefined}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                          <td>
                            {fertilizer.altrnative_fertilizer1_kg_are !==
                              null &&
                            fertilizer.altrnative_fertilizer1_kg_are !==
                              "NULL" &&
                            fertilizer.altrnative_fertilizer1_kg_are !== "0"
                              ? fertilizer.altrnative_fertilizer1_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "alternative_mix_1"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.altrnative_fertilizer2_kg_are !==
                              null &&
                            fertilizer.altrnative_fertilizer2_kg_are !==
                              "NULL" &&
                            fertilizer.altrnative_fertilizer2_kg_are !== "0"
                              ? fertilizer.altrnative_fertilizer2_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "alternative_mix_2"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.altrnative_fertilizer3_kg_are !==
                              null &&
                            fertilizer.altrnative_fertilizer3_kg_are !==
                              "NULL" &&
                            fertilizer.altrnative_fertilizer3_kg_are !== "0"
                              ? fertilizer.altrnative_fertilizer3_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "alternative_mix_3"
                                )}`
                              : null}
                          </td>
                          <td>
                            {fertilizer.altrnative_fertilizer4_kg_are !==
                              null &&
                            fertilizer.altrnative_fertilizer4_kg_are !==
                              "NULL" &&
                            fertilizer.altrnative_fertilizer4_kg_are !== "0"
                              ? fertilizer.altrnative_fertilizer4_kg_are +
                                ` ${GetUnitFromSource(
                                  props.units,
                                  "alternative_mix_4"
                                )}`
                              : null}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="2"></td>
                          <td>
                            {fertilizer.alternative_fertilizer1_unit_pocket !==
                              null &&
                            fertilizer.alternative_fertilizer1_unit_pocket !==
                              "NULL" &&
                            fertilizer.alternative_fertilizer1_unit_pocket !==
                              "0"
                              ? fertilizer.alternative_fertilizer1_unit_pocket +
                                " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer1_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer1_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.alternative_fertilizer2_unit_pocket !==
                              null &&
                            fertilizer.alternative_fertilizer2_unit_pocket !==
                              "NULL" &&
                            fertilizer.alternative_fertilizer2_unit_pocket !==
                              "0"
                              ? fertilizer.alternative_fertilizer2_unit_pocket +
                                " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer2_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer2_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer2_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer2_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.alternative_fertilizer3_unit_pocket !==
                              null &&
                            fertilizer.alternative_fertilizer3_unit_pocket !==
                              "NULL" &&
                            fertilizer.alternative_fertilizer3_unit_pocket !==
                              "0"
                              ? (fertilizer.alternative_fertilizer3_unit_pocket =
                                  " ")
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer3_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer3_unit
                                : null
                              : undefined}
                          </td>
                          <td>
                            {fertilizer.alternative_fertilizer4_unit_pocket !==
                              null &&
                            fertilizer.alternative_fertilizer4_unit_pocket !==
                              "NULL" &&
                            fertilizer.alternative_fertilizer4_unit_pocket !==
                              "0"
                              ? fertilizer.alternative_fertilizer4_unit_pocket +
                                " "
                              : null}
                            {getFertilizerLanguage(fertilizer) in
                            fertilizer.crop_fertilizer_text
                              ? fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_unit !== null &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_unit !== "NULL" &&
                                fertilizer.crop_fertilizer_text[
                                  getFertilizerLanguage(fertilizer)
                                ].alternative_fertilizer4_unit !== "0"
                                ? fertilizer.crop_fertilizer_text[
                                    getFertilizerLanguage(fertilizer)
                                  ].alternative_fertilizer4_unit
                                : null
                              : undefined}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="10"></td>
                        </tr>
                      </tbody>
                    </>
                  );
                })}
            </table>
            {props.user_role_type !== "Viewer" && props.organisation === to_org
              ? renderModal()
              : props.user_role_type !== "Viewer" &&
                props.umva_id === props.superuser &&
                renderModal()}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang.language,
    selected_crop_detail: state.crop.selected_crop_detail,
    selected_crop_id: state.crop.selected_crop_id,
    selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
    selected_fertilizer: state.crop.selected_fertilizer,
    feretilizer_delete_loading: state.crop.feretilizer_delete_loading,
    fertilizer_register_loading: state.crop.fertilizer_register_loading,
    selected_crop_detail_loading: state.crop.selected_crop_detail_loading,
    user_role_type: state.auth.user_role_type,
    language_list: state.lang.language_list,
    organisation: state.auth.organization,
    mapping_list: state.organization.mapping_list,
    auth: state.auth.organization,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    umva_id: state.auth.umva_id,
    units: state.season.units,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        selectFertilizer,
        registerFertilizer,
        deleteFertilizerRequest,
        languageUpdate,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(FertilizerGeneral);
