import SeasonActionTypes from "./actionTypes";

const INITIAL_STATE = {
  season_list: {},
  season_list_loading: false,
  selected_season: {},
  season_add_loading: false,
  season_delete_loading: false,
  units: {},
  unit_loading: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SeasonActionTypes.SEASON_LIST_REQUEST:
      return {
        ...state,
        season_list_loading: true,
      };
    case SeasonActionTypes.SEASON_LIST_SUCCESS:
      return {
        ...state,
        season_list_loading: false,
        season_list: action.season_list,
      };
    case SeasonActionTypes.SEASON_LIST_FAILURE:
      return {
        ...state,
        season_list_loading: false,
        season_list: {},
      };
    case SeasonActionTypes.SEASON_SELECT_REQUEST:
      return {
        ...state,
        selected_season: action.selected_season,
      };
    case SeasonActionTypes.REMOVE_SELECTED_SEASON:
      return {
        ...state,
        selected_season: {},
      };

    case SeasonActionTypes.ADD_SEASON_REQUEST:
      return {
        ...state,
        season_add_loading: true,
      };
    case SeasonActionTypes.ADD_SEASON_SUCCESS:
    case SeasonActionTypes.ADD_SEASON_FAILURE:
      return {
        ...state,
        season_add_loading: false,
      };

    case SeasonActionTypes.DELETE_SEASON_REQUEST:
      return {
        ...state,
        season_delete_loading: true,
      };

    case SeasonActionTypes.DELETE_SEASON_SUCCESS:
    case SeasonActionTypes.DELETE_SEASON_FAILURE:
      return {
        ...state,
        season_delete_loading: false,
      };
    case SeasonActionTypes.UNIT_CONFIG_REQUEST:
      return {
        ...state,
        unit_loading: true,
      };
    case SeasonActionTypes.UNIT_CONFIG_SUCCESS:
      return {
        ...state,
        units: action.units,
        unit_loading: false,
      };
    default:
      return state;
  }
}
