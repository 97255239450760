import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./variety.css";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../../common/spinner";
import Modal from "react-modal";
import { deleteVarietyMediaRequest } from "../../action";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const VarietyTable = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedVarietyMedia, setSelectedVarietyMedia] = useState([]);

  const getLanguageForVarietyTable = (data) => {
    let index = Object.values(data).findIndex((temp_data) => {
      return temp_data.locale === props.languageContent;
    });
    return index;
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const renderModal = () => {
    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={(e) => {
          e.stopPropagation();
          e.preventDefault();
          closeModal();
        }}
        style={customStyles}
      >
        <h4>Do you really want to delete selected variety media ?</h4>
        <div className="modalBox">
          <div className="leftModal">
            <div
              className="modalButton"
              onClick={(e) => {
                let params = [];
                let selected_text = selectedVarietyMedia.text;

                if (selected_text.length > 0) {
                  selected_text.forEach((i) => {
                    params.push(+i.id);
                  });
                }

                e.stopPropagation();
                e.preventDefault();
                closeModal();

                props.actions.deleteVarietyMediaRequest({
                  id: params,
                  variety_id: selectedVarietyMedia?.variety_id,
                  org_id: selectedVarietyMedia?.org_id,
                });
              }}
            >
              Yes
            </div>
          </div>

          <div className="rightModal">
            <div
              className="modalButton"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeModal();
              }}
            >
              No
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <table style={{ width: "100%" }} className="tableContainer" cellSpacing="0">
      <thead>
        <tr>
          <th>Code</th>
          <th>Sorting</th>
          <th>Media Title</th>
          <th>Media Link</th>
          <th>Media Type</th>
          {props.user_role_type !== "Viewer" &&
          props.organisation === props.to_org ? (
            <>
              <th>
                <span>Edit</span>
              </th>
              <th>
                <span>Delete</span>
              </th>
            </>
          ) : (
            props.user_role_type !== "Viewer" &&
            props.umva_id === props.superuser && (
              <>
                <th>
                  <span>Edit</span>
                </th>
                <th>
                  <span>Delete</span>
                </th>
              </>
            )
          )}
        </tr>
      </thead>

      {props.variety_media_list_loading ? (
        <Spinner />
      ) : (
        props.variety_media_list.length > 0 &&
        props.variety_media_list.map((media, key) => {
          return (
            <tbody
              key={key}
              className={media.code === props.code ? "selectedRow" : ""}
            >
              <tr>
                <td className="boldContent">{media.code}</td>
                <td className="boldContent">{media.sorting}</td>
                <td className="boldContent">
                  {Object.values(media.text).length > 0 &&
                  getLanguageForVarietyTable(media.text) != -1
                    ? Object.values(media.text)[
                        getLanguageForVarietyTable(media.text)
                      ].media_title
                    : undefined}
                </td>
                <td className="boldContent">
                  {Object.values(media.text).length > 0 &&
                  getLanguageForVarietyTable(media.text) != -1
                    ? Object.values(media.text)[
                        getLanguageForVarietyTable(media.text)
                      ].link
                    : undefined}
                </td>
                <td className="boldContent">
                  {Object.values(media.text).length > 0 &&
                  getLanguageForVarietyTable(media.text) != -1
                    ? Object.values(media.text)[
                        getLanguageForVarietyTable(media.text)
                      ].media_type
                    : undefined}
                </td>
                {props.user_role_type !== "Viewer" &&
                props.organisation === props.to_org ? (
                  <>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={require("../../../../images/edit.png")}
                        alt="edit"
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.selectVarietyMedia(media);
                        }}
                      />
                    </td>
                    <td
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={require("../../../../images/delete.png")}
                        alt="delete"
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          openModal();
                          setSelectedVarietyMedia(media);
                        }}
                      />
                    </td>
                  </>
                ) : (
                  props.user_role_type !== "Viewer" &&
                  props.umva_id === props.superuser && (
                    <>
                      <td
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require("../../../../images/edit.png")}
                          alt="edit"
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            props.selectVarietyMedia(media);
                          }}
                        />
                      </td>
                      <td
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={require("../../../../images/delete.png")}
                          alt="delete"
                          style={{
                            width: 20,
                            height: 20,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            openModal();
                            setSelectedVarietyMedia(media);
                          }}
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
              {
                <tr>
                  <td colSpan="11">
                    {Object.values(media.text).length > 0 &&
                    getLanguageForVarietyTable(media.text) != -1
                      ? Object.values(media.text)[
                          getLanguageForVarietyTable(media.text)
                        ].description
                      : undefined}
                  </td>
                </tr>
              }
            </tbody>
          );
        })
      )}
      {props.user_role_type !== "Viewer" && props.organisation === props.to_org
        ? renderModal()
        : props.user_role_type !== "Viewer" &&
          props.umva_id === props.superuser &&
          renderModal()}
    </table>
  );
};

const mapStateToProps = (state) => {
  return {
    user_role_type: state.auth.user_role_type,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    variety_media_list: state.crop.variety_media_list,
    umva_id: state.auth.umva_id,
    variety_media_list_loading: state.crop.variety_media_list_loading,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        deleteVarietyMediaRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(VarietyTable);
