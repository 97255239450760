import CropCalendarActionTypes from "./actionTypes";

import { fork, take, call, put, delay, select } from "redux-saga/effects";

import { CropCalendarService } from "./service";

import history from "../../../../config/history";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getOrganisation = (state) => state.auth;

export function* getCropCalendarActivity() {
  while (true) {
    const { data } = yield take(
      CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_REQUEST
    );

    let org = yield select(getOrganisation);

    if (data.crop_id !== 0) {
      var crop_calendar = yield call(CropCalendarService.activityList, data);
    } else {
      var crop_calendar = yield call(CropCalendarService.genericActivityList, {
        crop_id: 0,
        organisation: org.organization,
      });
    }

    if (crop_calendar.length > 0) {
      var added_activity = [];
      var crop_calendar_data = [];

      crop_calendar.map((tempData) => {
        let temp_data = [];
        if (!added_activity.includes(tempData.activity_id)) {
          temp_data.activity_id = tempData.activity_id;
          temp_data.crop_id = tempData.crop_id;
          temp_data.sorting =
            tempData.activity.length > 0 ? tempData.activity[0].sorting : 0;
          temp_data.activity = tempData.activity;
          temp_data.season_suitability = tempData.season_suitability;
          temp_data.seasons = [
            {
              season: tempData.season_key,
              weeksAfter: tempData.weeksAfter,
              duration: tempData.duration,
              cropactivity_id: tempData.cropactivity_id,
              season_suitability: tempData.season_suitability,
            },
          ];
          crop_calendar_data.push(temp_data);
          added_activity.push(tempData.activity_id);
        } else {
          added_activity.push(tempData.activity_id);
          Object.values(crop_calendar_data)
            .filter((data) => {
              return data.activity_id === tempData.activity_id;
            })
            .map((result) => {
              result.seasons.push({
                season: tempData.season_key,
                weeksAfter: tempData.weeksAfter,
                duration: tempData.duration,
                cropactivity_id: tempData.cropactivity_id,
                season_suitability: tempData.season_suitability,
              });
            });
        }
      });

      if (crop_calendar_data.length > 0) {
        crop_calendar_data = Object.values(crop_calendar_data).sort((a, b) => {
          return parseInt(a.sorting) < parseInt(b.sorting) ? -1 : 1;
        });
      }

      yield put({
        type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
        crop_calendar_activity: crop_calendar_data,
      });
    } else {
      yield put({
        type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
        crop_calendar_activity: crop_calendar,
      });
    }
  }
}

export function* getSelectedMedia() {
  while (true) {
    yield take(CropCalendarActionTypes.SELECTED_CROP_CALENDAR_MEDIA);
    history.push(`/crop/crop_calendars/media`);
  }
}

export function* getSelectedActivity() {
  while (true) {
    yield take(CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_EDIT);
    history.push(`/crop/crop_calendars/edit`);
  }
}

export function* registerCropCalendarRequest() {
  while (true) {
    const { data, route } = yield take(
      CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_REQUEST
    );
    var registered_crop_calendar = yield call(
      CropCalendarService.registerCropCalendar,
      data
    );

    if (
      registered_crop_calendar === "Activity is added to crop." ||
      registered_crop_calendar === "Activity has been added to crop."
    ) {
      let org = yield select(getOrganisation);

      var crop_calendar = yield call(CropCalendarService.activityList, {
        crop_id: data.crop_id,
        organisation: org.organization,
      });

      if (crop_calendar.length > 0) {
        var added_activity = [];
        var crop_calendar_data = [];
        crop_calendar.map((tempData) => {
          let temp_data = [];

          if (added_activity.includes(tempData.activity_id) === false) {
            temp_data.activity_id = tempData.activity_id;
            temp_data.crop_id = tempData.crop_id;
            temp_data.sorting =
              tempData.activity.length > 0 ? tempData.activity[0].sorting : 0;
            temp_data.season_suitability = tempData.season_suitability;
            temp_data.activity = tempData.activity;
            temp_data.seasons = [
              {
                season: tempData.season_key,
                weeksAfter: tempData.weeksAfter,
                duration: tempData.duration,
                cropactivity_id: tempData.cropactivity_id,
                season_suitability: tempData.season_suitability,
              },
            ];
            crop_calendar_data.push(temp_data);
            added_activity.push(tempData.activity_id);
          } else {
            added_activity.push(tempData.activity_id);
            Object.values(crop_calendar_data)
              .filter((data) => {
                return data.activity_id === tempData.activity_id;
              })
              .map((result) => {
                result.seasons.push({
                  season: tempData.season_key,
                  weeksAfter: tempData.weeksAfter,
                  duration: tempData.duration,
                  cropactivity_id: tempData.cropactivity_id,
                  season_suitability: tempData.season_suitability,
                });
              });
          }
        });

        if (crop_calendar_data.length > 0) {
          crop_calendar_data = Object.values(crop_calendar_data).sort(
            (a, b) => {
              return parseInt(a.sorting) < parseInt(b.sorting) ? -1 : 1;
            }
          );
        }

        yield put({
          type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
          crop_calendar_activity: crop_calendar_data,
        });
      } else {
        yield put({
          type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
          crop_calendar_activity: crop_calendar,
        });
      }

      yield put({
        type: CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_SUCCESS,
      });

      toast.success("Crop calendar has been added/updated successfully.", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });

      yield delay(2000);

      if (route === "update") {
        history.push(`/crop/crop_calendars`);
      } else {
        history.push(`/crop/activity_media`);
        history.push(`/crop/crop_calendars`);
      }
    } else {
      if ("error" in registered_crop_calendar) {
        toast.error(registered_crop_calendar.error.message, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        });
      }
      yield put({
        type: CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_SUCCESS,
      });
    }
  }
}

export function* deleteCropCalendar() {
  while (true) {
    const { data, crop_id } = yield take(
      CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_REQUEST
    );
    let cropActivityId = [];

    if ("seasons" in data) {
      data.seasons.map((tempSeason) => {
        cropActivityId.push(tempSeason.cropactivity_id);
      });
    }

    var deleted_data = yield call(CropCalendarService.deleteCropActivity, {
      cropActivity_id: cropActivityId.join(),
    });

    if (deleted_data === "Selected rows has been deleted.") {
      let org = yield select(getOrganisation);

      var crop_calendar = yield call(CropCalendarService.activityList, {
        crop_id: crop_id,
        organisation: org.organization,
      });

      if (crop_calendar.length > 0) {
        var added_activity = [];
        var crop_calendar_data = [];
        crop_calendar.map((tempData) => {
          let temp_data = [];
          if (added_activity.includes(tempData.activity_id) === false) {
            temp_data.activity_id = tempData.activity_id;
            temp_data.crop_id = tempData.crop_id;
            temp_data.activity = tempData.activity;
            temp_data.sorting =
              tempData.activity.length > 0 ? tempData.activity[0].sorting : 0;
            temp_data.season_suitability = tempData.season_suitability;
            temp_data.seasons = [
              {
                season: tempData.season_key,
                weeksAfter: tempData.weeksAfter,
                duration: tempData.duration,
                cropactivity_id: tempData.cropactivity_id,
                season_suitability: tempData.season_suitability,
              },
            ];
            crop_calendar_data.push(temp_data);
            added_activity.push(tempData.activity_id);
          } else {
            added_activity.push(tempData.activity_id);
            Object.values(crop_calendar_data)
              .filter((data) => {
                return data.activity_id === tempData.activity_id;
              })
              .map((result) => {
                result.seasons.push({
                  season: tempData.season_key,
                  weeksAfter: tempData.weeksAfter,
                  duration: tempData.duration,
                  cropactivity_id: tempData.cropactivity_id,
                  season_suitability: tempData.season_suitability,
                });
              });
          }
        });

        if (crop_calendar_data.length > 0) {
          crop_calendar_data = Object.values(crop_calendar_data).sort(
            (a, b) => {
              return parseInt(a.sorting) < parseInt(b.sorting) ? -1 : 1;
            }
          );
        }

        yield put({
          type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
          crop_calendar_activity: crop_calendar_data,
        });
      } else {
        yield put({
          type: CropCalendarActionTypes.CROP_CALENDAR_ACTIVITY_SUCCESS,
          crop_calendar_activity: crop_calendar,
        });
      }

      yield put({
        type: CropCalendarActionTypes.REGISTER_CROP_CALENDAR_ACTIVITY_SUCCESS,
      });

      yield put({
        type: CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_SUCCESS,
      });
    } else {
      yield put({
        type: CropCalendarActionTypes.DELETE_ACTIVITY_MEDIA_SUCCESS,
      });
    }
  }
}

export function* genericActivitiesRequest() {
  while (true) {
    yield take(CropCalendarActionTypes.GENERIC_ACTIVITIES_REQUEST);
    let org = yield select(getOrganisation);

    var genericActivities = yield call(CropCalendarService.genericActivities, {
      organisation: org.organization,
    });

    if (genericActivities.length > 0) {
      yield put({
        type: CropCalendarActionTypes.GENERIC_ACTIVITIES_SUCCESS,
        genericActivities: genericActivities,
      });
    }
  }
}

export function* activityCalenderList() {
  while (true) {
    const { crop_id, organisation } = yield take(
      CropCalendarActionTypes.ACTIVITY_CALENDAR_LIST_REQUEST
    );

    var activity_calender_list = yield call(
      CropCalendarService.activityCalenderList,
      { crop_id, organisation }
    );

    if (activity_calender_list.length > 0) {
      yield put({
        type: CropCalendarActionTypes.ACTIVITY_CALENDAR_LIST_SUCCESS,
        activityCalenderList: activity_calender_list,
      });
    }
  }
}

export default function* CropCalendarSaga() {
  yield fork(getCropCalendarActivity);
  yield fork(getSelectedMedia);
  yield fork(getSelectedActivity);
  yield fork(registerCropCalendarRequest);
  yield fork(deleteCropCalendar);
  yield fork(genericActivitiesRequest);
  yield fork(activityCalenderList);
}
