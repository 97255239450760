const CropActionTypes = {
  CROP_REQUEST: "crop_request",
  CROP_SUCCESS: "crop_success",
  CROP_FAILURE: "crop_failure",
  PARTICULAR_CROP_DETAIL_REQUEST: "particular_crop_detail_request",
  PARTICULAR_CROP_DETAIL_SUCCESS: "particular_crop_detail_success",
  CLEAR_CROP_DETAIL: "clear_crop_detail",
  SELECT_VARIETY_REQUEST: "select_variety_request",
  SELECT_FERTILIZER_REQUEST: "select_fertilizer_request",

  SET_DESCRIPTION_DATA: "set_description_data",
  REMOVE_DESCRIPTION_DATA: "remove_description_data",

  SET_MEDIA_DATA: "set_media_data",
  REMOVE_MEDIA_DATA: "remove_media_data",

  REGISTER_DESCRIPTION_REQUEST: "register_description_request",
  REGISTER_DESCRIPTION_SUCCESS: "register_description_success",
  REGISTER_DESCRIPTION_FAILURE: "register_description_failure",

  REGISTER_DESCRIPTION_MEDIA_REQUEST: "register_description_media_request",
  REGISTER_DESCRIPTION_MEDIA_SUCCESS: "register_description_media_success",
  REGISTER_DESCRIPTION_MEDIA_FAILURE: "register_description_media_failure",

  //characteristics
  SET_CHARACTERISTICS_DATA: "set_characteristics_data",
  REMOVE_CHARACTERISTICS_DATA: "remove_characteristics_data",
  REGISTER_CHARACTERISTICS_DATA_REQUEST: "register_characteristics_request",
  REGISTER_CHARACTERISTICS_DATA_SUCCESS: "register_characteristics_success",
  REGISTER_CHARACTERISTICS_DATA_FAILURE: "register_characteristics_failure",

  //indicator
  SET_INDICATOR_DATA: "set_indicator_data",
  REMOVE_INDICATOR_DATA: "remove_indicator_data",
  REGISTER_INDICATOR_DATA_REQUEST: "register_indicator_data_request",
  REGISTER_INDICATOR_DATA_SUCCESS: "register_indicator_data_success",
  REGISTER_INDICATOR_DATA_FAILURE: "register_indicator_data_failure",

  //pest and diseases
  PEST_DISEASES_LIST_REQUEST: "pest_diseases_list_request",
  PEST_DISEASES_LIST_SUCCESS: "pest_diseases_list_success",
  SET_PEST_DISEASES_DATA: "set_pest_diseases_data",
  REMOVE_PEST_DISEASES_DATA: "remove_pest_diseases_data",
  REGISTER_PEST_DISEASES_DATA_REQUEST: "register_pest_diseases_data_request",
  REGISTER_PEST_DISEASES_DATA_SUCCESS: "register_pest_diseases_data_success",
  REGISTER_PEST_DISEASES_DATA_FAILURE: "register_pest_diseases_data_failure",
  SELECT_PEST_DISEASES: "select_pest_diseases",
  PEST_DISEASES_MEDIA_REQUEST: "pest_diseases_media_request",
  PEST_DISEASES_MEDIA_SUCCESS: "pest_diseases_media_success",
  PEST_DISEASES_MEDIA_FAILURE: "pest_diseases_media_failure",
  SELECT_PEST_DISEASES_MEDIA: "select_pest_diseases_media",
  REGISTER_PEST_DISEASES_MEDIA_REQUEST: "register_pest_diseases_media_request",
  REGISTER_PEST_DISEASES_MEDIA_SUCCESS: "register_pest_diseases_media_success",
  REGISTER_PEST_DISEASES_MEDIA_FAILURE: "register_pest_diseases_media_failure",
  DELETE_PEST_DISEASES_REQUEST: "delete_pest_diseases_request",
  DELETE_PEST_DISEASES_SUCCESS: "delete_pest_diseases_success",
  DELETE_PEST_DISEASES_FAILURE: "delete_pest_diseases_failure",

  //general
  CROP_MEDIA_LIST_REQUEST: "crop_media_list_request",
  CROP_MEDIA_LIST_SUCCESS: "crop_media_list_success",
  DELETE_MEDIA_REQUEST: "delete_media_request",
  DELETE_MEDIA_SUCCESS: "delete_media_success",
  DELETE_MEDIA_FAILUTE: "delete_media_success",
  SELECT_CROP_MEDIA: "select_crop_media",

  //crop
  CROP_REGISTER_REQUEST: "crop_register_request",
  CROP_REGISTER_SUCCESS: "crop_register_success",
  CROP_REGISTER_FAILURE: "crop_register_failure",
  GET_BASE_ORGANISATION_CROP_LIST_REQUEST:
    "get_base_organisation_crop_list_request",
  GET_BASE_ORGANISATION_CROP_LIST_SUCCESS:
    "get_base_organisation_crop_list_success",
  CROP_COPY_REQUEST: "crop_copy_request",
  CROP_COPY_SUCCESS: "crop_copy_success",

  //variety
  VARIETY_REGISTER_REQUEST: "variety_register_request",
  VARIETY_REGISTER_SUCCESS: "variety_register_success",
  VARIETY_REGISTER_FAILURE: "variety_register_failure",
  DELETE_VARIETY_REQUEST: "delete_variety_request",
  DELETE_VARIETY_SUCCESS: "delete_variety_success",
  DELETE_VARIETY_FAILURE: "delete_variety_failure",

  //fertilizer
  REGISTER_FERTILIZER_REQUEST: "register_fertilizer_request",
  REGISTER_FERTILIZER_SUCCESS: "register_fertilizer_success",
  REGISTER_FERTILIZER_FAILURE: "register_fertilizer_failure",
  DELETE_FERTILIZER_REQUEST: "delete_fertilizer_request",
  DELETE_FERTILIZER_SUCCESS: "delete_fertilizer_success",
  DELETE_FERTILIZER_FAILURE: "delete_fertilizer_failure",

  //publish unpublish
  CROP_PUBLISH_UNPUBLISH_REQUEST: "crop_publish_unpublish_request",
  CROP_PUBLISH_UNPUBLISH_SUCCESS: "crop_publish_unpublish_success",

  CROP_SELECT_UNSELECT_REQUEST: "crop_select_unselect_request",

  //location
  SET_LATITUDE_AND_LONGITUDE: "set_latitude_longitude",

  //variety media
  VARIETY_MEDIA_LIST_REQUEST: "variety_media_list_request",
  VARIETY_MEDIA_LIST_SUCCESS: "variety_media_list_success",
  DELETE_VARIETY_MEDIA_REQUEST: "delete_variety_media_request",
  DELETE_VARIETY_MEDIA_SUCCESS: "delete_variety_media_success",
  REGISTER_VARIETY_MEDIA_REQUEST: "register_variety_media_request",
  REGISTER_VARIETY_MEDIA_SUCCESS: "register_variety_media_success",
};
export default CropActionTypes;
