import React, { useState, useEffect } from "react";
import Header from "../../../../common/header";
import Breadcrumb from "../../../../common/breadcrumb";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./characteristics.css";
import TextBox from "../../../../common/textBox";
import Switch from "../../../../common/switch";
import "../../../index.scss";
import {
  setCharacteristics,
  registerCharacteristicsRequest,
} from "../../action";
import Spinner from "../../../../common/spinner";
import CustomButton from "../../../../common/button";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetToOrganisation } from "../../../lib/getToOrganisation";
import { GetUnitFromSource } from "../../../lib/getUnitFromSource";

const Characteristics = (props) => {
  var languageListLength =
    props.language_list.length > 0 ? props.language_list.length : 1;
  var languageLength = [];

  for (let i = 0; i < languageListLength; i++) {
    languageLength.push(i);
  }

  const [data, setData] = useState(
    props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []
  );
  const [characteristicsData, setCharacteristicsData] = useState(
    props.characteristicsData
  );
  const [mediaData, setMediaData] = useState([]);

  const getLangContent = (language) => {
    let data = props.language_list.findIndex((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data !== -1) {
      return data;
    } else {
      return 0;
    }
  };

  const getLanguageContent = (language) => {
    let data = props.language_list.filter((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data.length > 0) {
      return data[0].locale;
    } else {
      return "en_EN";
    }
  };

  useEffect(() => {
    setData(props.selected_crop_id !== "" ? props.selected_crop_detail[0] : []);
  }, [props, props.selected_crop_id]);

  useEffect(() => {
    setCharacteristicsData(props.characteristicsData);
  }, [props.characteristicsData]);

  useEffect(() => {
    setMediaData(mediaData);
  }, [mediaData]);

  useEffect(() => {
    if (props.selected_crop_id !== "") {
      if ("crop_characteristics" in data) {
        let data2 = {};
        var ofUnitPocket = "";
        for (const [key1, value1] of Object.entries(
          data.crop_characteristics
        )) {
          for (const [key, value] of Object.entries(value1)) {
            if (key === "minCyclength") {
              data2["minCycleLength"] = value;
            } else if (key === "maxCycleLength") {
              data2["maxCycleLenght"] = value;
            } else if (key === "maxSlopeMeasures") {
              data2["maxSlope"] = value;
            } else if (key === "OF_kg_are") {
              data2["of_kg_are"] = value;
            } else if (key === "OF_heaps_are") {
              data2["of_heaps_are"] = value;
            } else if (key === "OF_unit_pocket") {
              data2["of_unit_pocket"] = value;
              ofUnitPocket = value;
            } else if (key === "netonextgrowingstage") {
              data2["netonext_growing_stage"] = value;
            }
            data2[key] = value;
          }

          let OF_unit_data = [];
          data.crop_text.map((temp_data) => {
            let data = [];
            data.locale = temp_data.locale;
            data.of_unit = temp_data.of_unit;
            data.of_unit_pocket = ofUnitPocket;
            OF_unit_data.push(data);
          });
          data2["cropText"] = OF_unit_data;
          setMediaData(OF_unit_data);
        }

        setCharacteristicsData(data2);
      }
    }
  }, []);

  const textChange1 = (name, text) => {
    let data1 = { [name]: text };
    let data2 = getLanguage() in mediaData ? mediaData[getLanguage()] : [];

    let language = { locale: languageContent };

    let language2 =
      getLanguage() === -1 ? Object.keys(mediaData).length : getLanguage();

    let data3 = { ...data2, ...data1, ...language };
    let data4 = { [language2]: data3 };
    let data = { ...mediaData, ...data4 };

    setMediaData(data);
  };

  const textChange = (name, text) => {
    let data1 =
      0 in characteristicsData ? characteristicsData[0] : characteristicsData;
    let data2 = { [name]: text };
    let data3 = { crop_id: props.selected_crop_id };
    let data4 = { ...data2, ...data3 };
    let data = { ...data1, ...data4 };

    props.actions.setCharacteristics(data);
  };

  const onSaveButtonClick = () => {
    let data0 = { ["cropText"]: mediaData };
    let data1 = { crop_id: props.selected_crop_id };
    let organisation = { organisation: props.organisation };
    let data = { ...characteristicsData, ...data0, ...data1, ...organisation };

    props.actions.registerCharacteristicsRequest(data);
  };

  const getLanguage = () => {
    return Object.values(mediaData).findIndex((temp_data) => {
      return temp_data.locale === languageContent;
    });
  };

  var languageContent = getLanguageContent(props.lang);
  var langContent = getLangContent(props.lang);

  const getCurrentLanguage = (locale) => {
    let data = Object.values(locale).findIndex((tempData) => {
      return tempData.locale.includes(props.lang) == true;
    });
    if (data !== -1) {
      return data;
    } else {
      return -1;
    }
  };

  const getEnglishLanguage = (locale) => {
    let data = Object.values(locale).findIndex((tempData) => {
      return tempData.locale.includes("en_EN") == true;
    });
    if (data !== -1) {
      return data;
    } else {
      return -1;
    }
  };

  let to_org = GetToOrganisation(props.mapping_list, props.auth);

  return (
    <div>
      <Header header={false} />

      <Breadcrumb />

      <div className="generalBody">
        <ToastContainer />
        {props.characteristics_loading === true ? (
          <Spinner />
        ) : (
          <div className="formCard">
            <div className="flexDirectionColumn">
              <TextBox
                label="Cycle Length"
                type="min_max"
                unit={GetUnitFromSource(props.units, "cycle_length")}
                name1={"minCycleLength"}
                name2={"maxCycleLenght"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minCyclength
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxCycleLength
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Yield"
                type="min_max"
                unit={GetUnitFromSource(props.units, "yield")}
                name1={"minYeild"}
                name2={"maxYeild"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minYeild
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxYeild
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Temperature Range"
                type="min_max"
                unit={GetUnitFromSource(props.units, "temperature_range")}
                name1={"minTemperature"}
                name2={"maxTemperature"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minTemperature
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxTemperature
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Optimal Temperature"
                type="min_max"
                unit={GetUnitFromSource(props.units, "optimal_temperature")}
                name1={"minOptTemperature"}
                name2={"maxOptTemperature"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minOptTemperature
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxOptTemperature
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Precipition Range"
                type="min_max"
                unit={GetUnitFromSource(props.units, "precipition_range")}
                name1={"minPrecipition"}
                name2={"maxPrecipition"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minPrecipition
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxPrecipition
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Optimal Precipition"
                type="min_max"
                unit={GetUnitFromSource(props.units, "optimal_precipitation")}
                name1={"minOptPrecipition"}
                name2={"maxOptPrecipition"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minOptPrecipition
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxOptPrecipition
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Slope Measure"
                type="min_max"
                unit={GetUnitFromSource(props.units, "slope_measure")}
                name1={"minSlope"}
                name2={"maxSlope"}
                text1={"(Max slope with no measures (%))"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minSlope
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxSlopeMeasures
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label=""
                type="min_max"
                name1={"minSlope"}
                name2={"maxSlope"}
                text1={"(Max slope with anti erosion measures (%))"}
                onTextChange={textChange}
              />
              <TextBox
                label="Row Distance"
                type="min_max"
                unit={GetUnitFromSource(props.units, "row_distance")}
                name1={"minRowDistance"}
                name2={"maxRowDistance"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minRowDistance
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxRowDistance
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Pocket Distance"
                type="min_max"
                unit={GetUnitFromSource(props.units, "pocket_distance")}
                name1={"minPocketDistance"}
                name2={"maxPocketDistance"}
                onTextChange={textChange}
                value1={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].minPocketDistance
                      : undefined
                    : undefined
                }
                value2={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].maxPocketDistance
                      : undefined
                    : undefined
                }
              />

              <TextBox
                label={`Organic Fertilizer ${GetUnitFromSource(
                  props.units,
                  "organic_fertilizer_kg_are"
                )}`}
                type="small"
                name={"of_kg_are"}
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].OF_kg_are
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Organic Fertilizer"
                unit={`heaps${GetUnitFromSource(
                  props.units,
                  "organic_fertilizer"
                )}`}
                type="small"
                name={"of_heaps_are"}
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].OF_heaps_are
                      : undefined
                    : undefined
                }
              />

              {languageLength.map((lang, key) => {
                return (
                  langContent === lang && (
                    <div key={key}>
                      {props.selected_crop_id !== "" ? (
                        <TextBox
                          label="Nbr of OF"
                          type="min_max_temp_value"
                          name1={"of_unit_pocket"}
                          name2={"of_unit"}
                          unit={GetUnitFromSource(props.units, "nbr_of_of")}
                          label1={"Of Unit"}
                          onTextChange={textChange1}
                          value1={
                            Object.keys(mediaData).length > 0
                              ? mediaData[0].of_unit_pocket
                              : props.selected_crop_id !== ""
                              ? data.crop_characteristics.length > 0
                                ? data.crop_characteristics[0].OF_unit_pocket
                                : undefined
                              : undefined
                          }
                          value={
                            getEnglishLanguage(mediaData) in mediaData === true
                              ? mediaData[getEnglishLanguage(mediaData)].of_unit
                              : undefined
                          }
                          value2={
                            getCurrentLanguage(mediaData) in mediaData === true
                              ? mediaData[getCurrentLanguage(mediaData)].of_unit
                              : "crop_id" in data.crop_text
                              ? data.crop_text[getLanguage()].name
                              : undefined
                          }
                        />
                      ) : (
                        <TextBox
                          label="Nbr of OF"
                          type="min_max_temp_value"
                          name1={"of_unit_pocket"}
                          name2={"of_unit"}
                          unit={GetUnitFromSource(props.units, "nbf_of_of")}
                          label1={"Of Unit"}
                          onTextChange={textChange1}
                          value={
                            getEnglishLanguage(mediaData) in mediaData === true
                              ? mediaData[getEnglishLanguage(mediaData)].of_unit
                              : undefined
                          }
                          value1={
                            Object.keys(mediaData).length > 0
                              ? mediaData[0].of_unit_pocket
                              : props.selected_crop_id !== ""
                              ? data.crop_characteristics.length > 0
                                ? data.crop_characteristics[0].OF_unit_pocket
                                : undefined
                              : undefined
                          }
                          value2={
                            getCurrentLanguage(mediaData) in mediaData === true
                              ? mediaData[getCurrentLanguage(mediaData)].of_unit
                              : "crop_id" in data.crop_text
                              ? data.crop_text[getLanguage()].name
                              : undefined
                          }
                        />
                      )}
                    </div>
                  )
                );
              })}

              <TextBox
                label="comms"
                name="Comms"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? "crop_fertilizer_text" in data
                      ? langContent in data.crop_fertilizer_text &&
                        data.crop_fertilizer_text[langContent].comms
                      : undefined
                    : undefined
                }
              />

              <Switch
                label="Deep Rooting"
                name="deep_rooting"
                onTextChange={textChange}
                selected={
                  "deep_rooting" in characteristicsData
                    ? data.crop_characteristics.length > 0
                      ? characteristicsData.deep_rooting
                      : undefined
                    : props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].deep_rooting
                        ? 1
                        : 0
                      : 0
                    : 0
                }
              />
              <Switch
                label="Heavy Feeder"
                name="heavy_feeder"
                onTextChange={textChange}
                selected={
                  "heavy_feeder" in characteristicsData
                    ? characteristicsData.heavy_feeder
                    : props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].heavy_feeder
                        ? 1
                        : 0
                      : 0
                    : 0
                }
              />
              <TextBox
                label="Rotation Group"
                name={"rotating_group"}
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].Rotationgroup
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Weeks Till Crop Ends"
                name="netonext_growing_stage"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].netonextgrowingstage
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Weight of 1000 Seeds"
                name="weight_of_1000_seeds"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].replacedBy
                      : undefined
                    : undefined
                }
              />
              <TextBox
                label="Min Return Time"
                name="min_return_time"
                onTextChange={textChange}
                value={
                  props.selected_crop_id !== ""
                    ? data.crop_characteristics.length > 0
                      ? data.crop_characteristics[0].replacedBy
                      : undefined
                    : undefined
                }
              />

              {props.user_role_type !== "Viewer" &&
              props.organisation === to_org ? (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="leftSelectLabelContainer1"></div>

                  <div className="rightSelectLabelContainer1">
                    <CustomButton
                      name={`Save Crop Characteristics`}
                      onSaveButtonClick={onSaveButtonClick}
                    />
                  </div>
                </div>
              ) : (
                props.user_role_type !== "Viewer" &&
                props.umva_id === props.superuser && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="leftSelectLabelContainer1"></div>

                    <div className="rightSelectLabelContainer1">
                      <CustomButton
                        name={`Save Crop Characteristics`}
                        onSaveButtonClick={onSaveButtonClick}
                      />
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    selected_crop_detail: state.crop.selected_crop_detail,
    selected_crop_id: state.crop.selected_crop_id,
    characteristicsData: state.crop.characteristicsData,
    characteristics_loading: state.crop.characteristics_loading,
    lang: state.lang.language,
    user_role_type: state.auth.user_role_type,
    language_list: state.lang.language_list,
    organisation: state.auth.organization,
    mapping_list: state.organization.mapping_list,
    auth: state.auth.organization,
    organisation: state.auth.organization,
    superuser: state.auth.superuser,
    umva_id: state.auth.umva_id,
    units: state.season.units,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setCharacteristics,
        registerCharacteristicsRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchtoProps)(Characteristics);
