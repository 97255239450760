import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "../../../index.scss";
import {
  cropCalendarListRequest,
  selectedMedia,
  cropCalendarActivityEdit,
  deleteActivityMedia,
} from "./action";
import moment from "moment";
import Modal from "react-modal";
import ReactTooltip from "react-tooltip";
import TextBox from "../../../../common/textBox";
import CustomButton from "../../../../common/button";
import { seasonListRequest } from "../../../season/action";
import Spinner from "../../../../common/spinner";
import getLatitudeLongitude from "../../../../common/getLatitudeLongitude";
import { setLatitudeLongitudeRequest } from "../../../crop/action";
import getIntersection from "../../../lib/getIntersection";
import filterIntersectionData from "../../../lib/filterIntersectionData";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const CropCalendarTimeline = (props) => {
  var totalWeeks = moment().isoWeeksInYear();
  var months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const [nextGrowStage, setNextGrowStage] = useState(0);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [selectedCropActivity, setSelectedCropActivity] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [selectedGenericActivity, setSelectedGenericActivity] = useState("");
  const [cropCalendarList, setCropCalendarList] = useState(
    props.crop_calendar_list
  );
  const [latLng, setLatLng] = useState({});
  const { latitude, longitude } = getLatitudeLongitude(
    props.organization_list,
    props.organisation
  );

  const getStartWeekOfSeason = (offset_season) => {
    let data = Object.values(props.season_list).filter((tmp) => {
      return tmp.key === offset_season;
    });

    if (data.length > 0) {
      return data[0].startWeek;
    }
  };

  const getMonthsData = (current_year, year, weeksAfter, month, startMonth) => {
    let months_data = [];

    for (let i = current_year; i <= year; i++) {
      if (i === current_year) {
        for (let j = startMonth; j <= 11; j++) {
          months_data.push(months[j]);
        }
      } else {
        for (let j = 0; j < 12; j++) {
          if (i == new moment().isoWeek(weeksAfter).format("YY")) {
            if (parseInt(month) - 1 >= j) {
              months_data.push(months[j]);
            }
          } else {
            months_data.push(months[j]);
          }
        }
      }
    }
    return months_data;
  };

  const getTimeline = (startWeek, endWeek, season) => {
    let total_years = parseInt(endWeek / totalWeeks);
    let j = total_years;
    let data = [];
    if (total_years >= 2 || season === "M") {
      for (let i = startWeek; i < totalWeeks; i++) {
        data.push(i);
      }
      var k = 1;
      while (j !== 0) {
        for (let i = totalWeeks * k; i < totalWeeks + totalWeeks * k; i++) {
          if (i <= endWeek) {
            data.push(i);
          }
        }
        j--;
        k++;
      }
    } else {
      for (let i = startWeek; i <= endWeek; i++) {
        data.push(i);
      }
    }
    return data;
  };

  const getTempMonths = (season_key) => {
    let selected_crop_detail = { ...props.selected_crop_detail };

    if (Object.values(selected_crop_detail).length > 0) {
      var weeksAfter = 0;
      var weeksAfterArray = [];

      var defaultPlantDate = selected_crop_detail[0].crop_season.filter(
        (tempData) => {
          return tempData.season_key === season_key;
        }
      );

      if (defaultPlantDate.length > 0) {
        var week_data = 0;

        selected_crop_detail[0].activity_crop.map((tempData) => {
          if (tempData.season_key === season_key) {
            week_data =
              defaultPlantDate[0].defaultPlantDate +
              tempData.weeksAfter +
              tempData.duration;

            weeksAfterArray.push(week_data);
          }
        });

        weeksAfter = week_data;
      }

      if (weeksAfter > 0) {
        let year = new moment()
          .isoWeek(Math.max(...weeksAfterArray))
          .format("YY");
        let month = new moment()
          .isoWeek(Math.max(...weeksAfterArray))
          .format("M");
        let current_year = new moment().format("YY");
        let startWeek = getStartWeekOfSeason(season_key);
        startWeek = startWeek === null ? 0 : startWeek;

        let startMonth = moment()
          .isoWeek(startWeek)
          .startOf("week")
          .format("M");
        let months = getMonthsData(
          current_year,
          year,
          Math.max(...weeksAfterArray),
          month,
          parseInt(startMonth)
        );

        let timeline = getTimeline(
          startWeek,
          Math.max(...weeksAfterArray),
          season_key
        );

        return [months, timeline];
      } else {
        return [[], []];
      }
    } else {
      return [[], []];
    }
  };

  const getLangContent = (language) => {
    let data = props.language_list.findIndex((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data !== -1) {
      return data;
    } else {
      return 0;
    }
  };

  const getLanguageContent = (language) => {
    let data = props.language_list.filter((tempData) => {
      return tempData.locale.includes(language) === true;
    });
    if (data.length > 0) {
      return data[0].locale;
    } else {
      return "en_EN";
    }
  };

  useEffect(() => {
    let { latitude, longitude } = getLatitudeLongitude(
      props.organization_list,
      props.organisation
    );
    setLatLng({
      latitude: latitude,
      longitude: longitude,
    });

    props.actions.setLatitudeLongitudeRequest({
      latitude: props.latitude !== "" ? props.latitude : latitude,
      longitude: props.longitude !== "" ? props.longitude : longitude,
    });
  }, []);

  useEffect(() => {
    if (props.selected_crop_detail.length > 0) {
      if (props.selected_crop_detail[0].crop_characteristics.length > 0) {
        setNextGrowStage(
          props.selected_crop_detail[0].crop_characteristics[0]
            .netonextgrowingstage
        );
      }
    }
  }, []);

  useEffect(() => {
    setSelectedGenericActivity(selectedGenericActivity);
  }, [selectedGenericActivity]);

  useEffect(() => {
    setSelectedCropActivity(selectedCropActivity);
  }, [selectedCropActivity]);

  useEffect(() => {
    for (let i = 1; i <= totalWeeks; i++) {
      let a = weeks;
      a.push(i);
      setWeeks(a);
    }
  }, []);

  useEffect(() => {
    props.actions.cropCalendarListRequest({
      crop_id: props.selected_crop_id,
      organisation: props.organisation,
    });
  }, []);

  const closeModal = () => {
    setIsOpen(false);
  };

  const getSeasonStartWeek = (week, activity_id) => {
    let selected_crop_detail = [...props.selected_crop_detail];

    if (props.season_list.length > 0) {
      let data = props.season_list.findIndex((temp) => {
        return temp.key === week;
      });

      if (data !== -1) {
        if (
          [null, "NULL", undefined].includes(
            props.season_list[data].defaultPlantDate
          )
        ) {
          if ([45, 46, 47].includes(activity_id)) {
            return props.season_list[data].startWeek;
          } else {
            if (selected_crop_detail.length > 0) {
              var defaultPlantDate = selected_crop_detail[0].crop_season.filter(
                (tempData) => {
                  return tempData.season_key === week;
                }
              );

              if (defaultPlantDate.length > 0) {
                if ("defaultPlantDate" in defaultPlantDate[0]) {
                  return defaultPlantDate[0].defaultPlantDate;
                } else {
                  return props.season_list[data].startWeek;
                }
              } else {
                return props.season_list[data].startWeek;
              }
            } else {
              return props.season_list[data].startWeek;
            }
          }
        } else return props.season_list[data].startWeek;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const getplantingWeek = (crop, activity_id, type) => {
    if (crop.duration === 0 && crop.duration === 0) {
      return [];
    } else {
      var check =
        Object.values(props.selected_season_list).length > 0
          ? props.selected_season_list.some((tmp) => {
              return tmp.defaultPlantDate != null;
            })
          : false;
      var dates = [];
      var defaultPlantDate;
      let selected_crop_detail = { ...props.selected_crop_detail };

      if (check) {
        var defaultPlantDate = props.selected_season_list.filter((tempData) => {
          return tempData.key === crop.season;
        });
      } else {
        if (Object.values(selected_crop_detail).length > 0) {
          if ([45, 46, 47].includes(activity_id)) {
            defaultPlantDate = undefined;
          } else {
            defaultPlantDate = selected_crop_detail[0].crop_season.filter(
              (tempData) => {
                return tempData.season_key === crop.season;
              }
            );
          }
        }
      }

      if (defaultPlantDate != undefined) {
        if (defaultPlantDate.length > 0) {
          if ("defaultPlantDate" in defaultPlantDate[0]) {
            defaultPlantDate = defaultPlantDate[0].defaultPlantDate;
          } else {
            defaultPlantDate = getSeasonStartWeek(crop.season, activity_id);
          }
        } else {
          defaultPlantDate = getSeasonStartWeek(crop.season, activity_id);
        }
      } else {
        defaultPlantDate = getSeasonStartWeek(crop.season, activity_id);
      }

      if (defaultPlantDate === 0) {
        var startPlantingWeek = defaultPlantDate + crop.weeksAfter;
      } else {
        if (crop.weeksAfter === 0) {
          var startPlantingWeek = +defaultPlantDate + +crop.weeksAfter;
        } else {
          var startPlantingWeek = +defaultPlantDate + +crop.weeksAfter;
        }

        if (startPlantingWeek <= 0) {
          startPlantingWeek = +totalWeeks + +startPlantingWeek;
        }
      }
      var endPlantingWeek = +startPlantingWeek + +crop.duration - 1;

      if (startPlantingWeek === totalWeeks) {
        if (type === "a") {
          startPlantingWeek = +startPlantingWeek;
        } else {
          startPlantingWeek = +totalWeeks;
        }
      } else if (startPlantingWeek >= totalWeeks) {
        if (type === "a") {
          startPlantingWeek = +startPlantingWeek - +totalWeeks;
        } else {
          startPlantingWeek = +startPlantingWeek;
        }
      }

      if (endPlantingWeek === totalWeeks) {
        endPlantingWeek = endPlantingWeek;
      } else if (endPlantingWeek >= totalWeeks) {
        if (type === "a") {
          endPlantingWeek = +endPlantingWeek - +totalWeeks;
        } else {
          endPlantingWeek = +endPlantingWeek;
        }
      }

      if (startPlantingWeek >= endPlantingWeek) {
        if (startPlantingWeek === endPlantingWeek) {
          if (type === "a") {
            dates.push(startPlantingWeek);
          } else {
            dates.push(startPlantingWeek);
          }
        } else {
          for (let i = startPlantingWeek; i <= totalWeeks; i++) {
            if (type === "a") {
              dates.push(i);
            } else {
              dates.push(i);
            }
          }
          for (let i = 1; i <= endPlantingWeek; i++) {
            if (type === "a") {
              dates.push(i);
            } else {
              dates.push(i);
            }
          }
        }
      } else {
        for (
          startPlantingWeek;
          startPlantingWeek <= endPlantingWeek;
          startPlantingWeek++
        ) {
          if (type === "a") {
            dates.push(startPlantingWeek);
          } else {
            dates.push(startPlantingWeek);
          }
        }
      }
      return dates;
    }
  };

  if (props.selected_season_list.length > 0) {
    if (props.selected_crop_id === 0) {
      var selected_season_list = props.selected_season_list.filter(
        (tempData) => {
          return (
            !tempData.key.includes("w") &&
            !tempData.key.includes("i") &&
            !tempData.key.includes("M") &&
            !tempData.key.includes("G")
          );
        }
      );
    } else {
      let suitable_season = props.selected_crop_detail[0]?.crop_season.filter(
        (season) => {
          return season.suitability === "1";
        }
      );

      let tmp_intersection = getIntersection(
        props.selected_season_list,
        //props.selected_crop_detail[0]?.crop_season,
        suitable_season,
        "key",
        "season_key"
      );
      let intersection = filterIntersectionData(tmp_intersection);

      var selected_season_list = props.selected_season_list.filter(
        (tempData) => {
          //return (!tempData.key.includes("w") && !tempData.key.includes("i"));
          return intersection.includes(tempData.key);
        }
      );
    }
  } else {
    var selected_season_list = [];
  }

  useEffect(() => {
    setCropCalendarList(props.crop_calendar_list);
  }, [props.crop_calendar_list]);

  useEffect(() => {
    setCropCalendarList(cropCalendarList);
  }, [cropCalendarList]);

  const selectedGenericActivityId = (activity_id) => {
    if (activity_id !== "all") {
      setSelectedGenericActivity(activity_id);
      let crop_calendar_list1 = props.crop_calendar_list.filter((tempData) => {
        return tempData.activity_id == activity_id;
      });
      setCropCalendarList(crop_calendar_list1);
    } else {
      setCropCalendarList(props.crop_calendar_list);
    }
  };

  const getYearList = (months) => {
    var year_list = [];

    months.map((temp, key) => {
      if (key !== 0) {
        year_list.push(Math.ceil((key + 1) / 12));
      } else {
        year_list.push(1);
      }
    });
    return year_list;
  };

  const monthMap = (months, type) => {
    var previous_month = 0;
    var year_list = getYearList(months);
    var start_key = 0;
    return months.map((month, key) => {
      let year_number = Math.ceil(key / 12);
      let count_occurance = year_list.reduce(
        (a, v) => (v === year_number ? a + 1 : a),
        0
      );
      count_occurance = Math.floor(count_occurance / 2);

      if (key !== 0) {
        previous_month = Math.ceil(key / 12);
      }
      if (type === "a" && previous_month !== Math.ceil((key + 1) / 12)) {
        start_key = key;
      }

      return type === "a" ? (
        <div
          key={key}
          style={{ display: "flex", flex: 1, flexDirection: "column" }}
        >
          {count_occurance + start_key - 1 === key && key !== 0 ? (
            <div
              className="activityWeekHeader"
              style={
                key !== 0 || key !== months.length - 1
                  ? {
                      borderRightColor: "transparent",
                      borderLeftColor: "transparent",
                    }
                  : {}
              }
            >
              <span className="activityMonth1" style={{ position: "absolute" }}>
                Year {Math.ceil((key + 1) / 12)}
              </span>
            </div>
          ) : key !== 0 && months.length - 1 !== key ? (
            year_list[key - 1] !== year_list[key] ? (
              <div
                className="activityWeekHeader"
                style={{ borderRightColor: "transparent" }}
              ></div>
            ) : (
              <div
                className="activityWeekHeader"
                style={
                  key === 0
                    ? {
                        borderLeftColor: "#bdbdbd",
                        borderRightColor: "transparent",
                      }
                    : key === months.length - 1
                    ? {
                        borderRightColor: "#bdbdbd",
                        borderLeftColor: "transparent",
                      }
                    : {
                        borderLeftColor: "transparent",
                        borderRightColor: "transparent",
                      }
                }
              ></div>
            )
          ) : (
            <div
              className="activityWeekHeader"
              style={
                key === 0
                  ? {
                      borderLeftColor: "#bdbdbd",
                      borderRightColor: "transparent",
                    }
                  : key === months.length - 1
                  ? {
                      borderRightColor: "#bdbdbd",
                      borderLeftColor: "transparent",
                    }
                  : {
                      borderLeftColor: "transparent",
                      borderRightColor: "transparent",
                    }
              }
            ></div>
          )}
          <div className="activityWeekHeader">
            <span className="activityMonth">{month}</span>
          </div>
        </div>
      ) : (
        <div className="activityWeekHeader">
          <span className="activityMonth">{month}</span>
        </div>
      );
    });
  };

  const getSeasonTimeline = (weeks, tempData, season_key, type) => {
    return Object.values(weeks).map((temp_weeks, key) => {
      return (
        <div key={key} className="activityDataCell" style={{ height: 50 }}>
          {tempData.seasons.map((temp_crop, key1) => {
            if (temp_crop.season === season_key) {
              return getplantingWeek(
                temp_crop,
                tempData.activity_id,
                type
              )[0] === temp_weeks &&
                getplantingWeek(temp_crop, tempData.activity_id, type)
                  .length === 1 ? (
                <div key={key1}>
                  <div
                    className="timeline1 startTimeLine endTimeLine absolutePosition"
                    data-tip={
                      "Week number: " +
                      (getplantingWeek(
                        temp_crop,
                        tempData.activity_id,
                        type
                      )[0] %
                        totalWeeks) +
                      " to "
                    }
                  ></div>
                  <ReactTooltip backgroundColor="#4cba6a" multiline={true} />
                </div>
              ) : getplantingWeek(temp_crop, tempData.activity_id, type)[0] ===
                temp_weeks ? (
                <div key={key1}>
                  <div
                    className="timeline1 startTimeLine absolutePosition"
                    data-tip={
                      "Week number: " +
                      (getplantingWeek(
                        temp_crop,
                        tempData.activity_id,
                        type
                      )[0] %
                        totalWeeks) +
                      " " +
                      " to " +
                      (getplantingWeek(temp_crop, tempData.activity_id, type)[
                        getplantingWeek(temp_crop, tempData.activity_id, type)
                          .length - 1
                      ] %
                        totalWeeks) +
                      " <br /> "
                    }
                  ></div>
                  <ReactTooltip backgroundColor="#4cba6a" multiline={true} />
                </div>
              ) : getplantingWeek(temp_crop, tempData.activity_id, type)[
                  getplantingWeek(temp_crop, tempData.activity_id, type)
                    .length - 1
                ] === temp_weeks ? (
                <div key={key1}>
                  <div
                    className="
                      timeline1
                      endTimeLine
                      absolutePosition
                    "
                    data-tip={
                      "Week number: " +
                      (getplantingWeek(
                        temp_crop,
                        tempData.activity_id,
                        type
                      )[0] %
                        totalWeeks) +
                      " " +
                      " to " +
                      (getplantingWeek(temp_crop, tempData.activity_id, type)[
                        getplantingWeek(temp_crop, tempData.activity_id, type)
                          .length - 1
                      ] %
                        totalWeeks) +
                      " <br /> "
                    }
                  ></div>
                  <ReactTooltip backgroundColor="#4cba6a" multiline={true} />
                </div>
              ) : getplantingWeek(
                  temp_crop,
                  tempData.activity_id,
                  type
                ).includes(temp_weeks) === true ? (
                <div key={key1}>
                  <div
                    className="timeline1 absolutePosition"
                    data-tip={
                      "Week number: " +
                      (getplantingWeek(
                        temp_crop,
                        tempData.activity_id,
                        type
                      )[0] %
                        totalWeeks) +
                      " " +
                      " to " +
                      (getplantingWeek(temp_crop, tempData.activity_id, type)[
                        getplantingWeek(temp_crop, tempData.activity_id, type)
                          .length - 1
                      ] %
                        totalWeeks) +
                      " <br /> "
                    }
                  ></div>
                  <ReactTooltip backgroundColor="#4cba6a" multiline={true} />
                </div>
              ) : (
                <div key={key1}></div>
              );
            }
          })}
        </div>
      );
    });
  };

  //var langContent = getLangContent(props.lang);
  var languageContent = getLanguageContent(props.lang);

  const getSeasonSuitability = (selected_crop_detail, season_key) => {
    let data = selected_crop_detail.filter((tempData) => {
      return tempData.key === season_key || tempData.key === season_key + "w";
    });

    if (data.length > 0) {
      let returnData = [];
      data.map((tempData) => {
        if (tempData.suitability === "0") {
          returnData.push(false);
        } else {
          returnData.push(true);
        }
      });
      if (returnData.includes(true) == true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getLanguageData = (data) => {
    let tempData = Object.values(data).findIndex((temp_data) => {
      return temp_data.locale === languageContent;
    });
    return tempData;
  };

  const getSeasonSuitabilityData = (data, season) => {
    if (data !== undefined) {
      var data1 = data.filter((tempData) => {
        return season == tempData.season;
      });
      return data1;
    } else {
      return [];
    }
  };

  const changeLatitudeLongitude = (name, value) => {
    setLatLng((lat_lng) => ({ ...lat_lng, [name]: value }));
  };

  const submitLatitudeLongitude = () => {
    let params = {
      ...{
        organisation: props.organisation,
        crop_id: props.selected_crop_id,
      },
      ...{
        latitude: "latitude" in latLng ? latLng.latitude : 0,
        longitude: "longitude" in latLng ? latLng.longitude : 0,
      },
    };

    props.actions.seasonListRequest(params);
    props.actions.setLatitudeLongitudeRequest({
      latitude: "latitude" in latLng ? latLng.latitude : 0,
      longitude: "longitude" in latLng ? latLng.longitude : 0,
    });
  };

  const checkSeasonExists = (season_letter) => {
    if (props.selected_crop_id !== 0) {
      let check = props.crop_season_list.findIndex((tmp) => {
        return tmp.season_key === season_letter;
      });
      return check;
    } else {
      if (Object.values(selected_season_list).length > 0) {
        let check = selected_season_list.findIndex((tmp) => {
          return tmp.key === season_letter;
        });
        return check;
      } else {
        return -1;
      }
    }
  };

  const getLanguageIndex = (offset) => {
    let index = Object.values(offset).findIndex((tmp) => {
      return tmp.locale.includes(props.lang);
    });
    if (index === -1) {
      return Object.values(offset).findIndex((tmp) => {
        return tmp.locale === "en_EN";
      });
    } else {
      return index;
    }
  };

  const displaySeason = (season_key) => {
    if (props.selected_crop_detail.length > 0) {
      let data = props.selected_crop_detail[0].crop_season.filter(
        (tempData) => {
          return (
            tempData.season_key === season_key ||
            tempData.season_key === season_key + "w"
          );
        }
      );

      if (data.length > 0) {
        let returnData = [];
        data.map((tempData) => {
          if (tempData.suitability === "0") {
            returnData.push(false);
          } else {
            returnData.push(true);
          }
        });
        if (returnData.includes(true) == true) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      if (props.selected_crop_id === 0) return true;
      else return false;
    }
  };

  return (
    <div className="mainActivityContainer">
      {
        <form
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TextBox
            id="latitude"
            name="latitude"
            onTextChange={changeLatitudeLongitude}
            placeholder={"Latitude"}
            type="latLng"
            value={props.latitude}
          />
          <TextBox
            id="longitude"
            name="longitude"
            onTextChange={changeLatitudeLongitude}
            placeholder={"Longitude"}
            type="latLng"
            value={props.longitude}
          />

          <CustomButton
            name="Change Location"
            image=""
            styles={{ width: "20%" }}
            onSaveButtonClick={submitLatitudeLongitude}
          />
        </form>
      }
      {props.selected_crop_id === 0 && (
        <div
          className="TextBoxContainer"
          style={{ width: "30%", marginTop: "2%" }}
        >
          <div className="leftSelectLabelContainer1">
            <span>Category</span>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <select
              className="selectInput"
              name="category"
              value={selectedGenericActivity}
              onChange={(e) => {
                selectedGenericActivityId(e.target.value);
              }}
            >
              <option>Choose</option>
              {props.generic_category.length > 0 &&
                props.generic_category.map((tempData, key) => {
                  return (
                    (tempData.status || tempData.status === null) && (
                      <option key={key} value={tempData.id}>
                        {tempData.activity_text.length > 0
                          ? tempData.activity_text[
                              getLanguageIndex(tempData.activity_text)
                            ].name
                          : undefined}
                      </option>
                    )
                  );
                })}
              <option value="all">All</option>
            </select>
          </div>
        </div>
      )}
      {props.season_list_loading === true ? (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Spinner style={{ position: "relative" }} />
        </div>
      ) : (
        selected_season_list.length > 0 &&
        selected_season_list.map((selectedSeasonList, key12) => {
          if (displaySeason(selectedSeasonList.key)) {
            if (
              getSeasonSuitability(
                props.selected_season_list,
                selectedSeasonList.key
              ) === true ||
              props.selected_crop_id === 0
            ) {
              var monthMapSeason = getTempMonths(selectedSeasonList.key)[0];
              var monthMapTimeline = getTempMonths(selectedSeasonList.key)[1];
              var checkSeasonExist = checkSeasonExists(selectedSeasonList.key);

              if (checkSeasonExist !== -1) {
                return (
                  <div key={key12}>
                    <div className="seasonTitle">
                      {selectedSeasonList.key !== "M" ? (
                        <span>
                          Crop calendar for season {selectedSeasonList.key}
                        </span>
                      ) : (
                        <span>Crop calendar for mature crop</span>
                      )}
                    </div>

                    <div className="activityHeader">
                      <div className="activityLeftPart"></div>

                      <div className="activityRightPart">
                        {nextGrowStage > totalWeeks
                          ? monthMap(monthMapSeason, "a")
                          : monthMap(months, "b")}
                      </div>
                    </div>

                    <div className="activityBodyContainer">
                      {props.crop_calendar_list !== undefined &&
                        props.crop_calendar_list.length > 0 &&
                        Object.values(cropCalendarList).map((tempData, key) => {
                          return (
                            tempData.activity !== undefined &&
                            Object.values(tempData.activity).length > 0 && (
                              <div className="activityDataRow" key={key}>
                                <div
                                  className="activityLeftPart"
                                  style={
                                    getSeasonSuitabilityData(
                                      tempData.seasons,
                                      selectedSeasonList.key
                                    ).length > 0
                                      ? getSeasonSuitabilityData(
                                          tempData.seasons,
                                          selectedSeasonList.key
                                        )[0].season_suitability == false
                                        ? { flex: 0.55, opacity: 0.5 }
                                        : { flex: 0.55 }
                                      : { flex: 0.55, opacity: 0.5 }
                                  }
                                >
                                  <div
                                    style={
                                      tempData.activity.id !==
                                      tempData.activity.category
                                        ? {}
                                        : {}
                                    }
                                  >
                                    <img
                                      src={require("../../../../images/edit.png")}
                                      alt="edit"
                                      style={{
                                        cursor: "pointer",
                                        paddingRight: 10,
                                      }}
                                      onClick={() => {
                                        props.actions.cropCalendarActivityEdit(
                                          tempData
                                        );
                                      }}
                                    />
                                    {![null, ""].includes(
                                      tempData.activity[0].image
                                    ) && (
                                      <span>
                                        <img
                                          src={`data:image/png;base64, ${atob(
                                            tempData.activity[0].image
                                          )}`}
                                          width={"30"}
                                          height={"30"}
                                          style={{
                                            paddingRight: "5px",
                                            marginBottom: "-10px",
                                          }}
                                        />
                                      </span>
                                    )}
                                    {tempData.activity[0].activity_text[
                                      getLanguageData(
                                        tempData.activity[0].activity_text
                                      )
                                    ] !== undefined
                                      ? tempData.activity[0].activity_text[
                                          getLanguageData(
                                            tempData.activity[0].activity_text
                                          )
                                        ].name
                                      : null}
                                  </div>
                                  <div>
                                    {"media" in tempData.activity[0] &&
                                    tempData.activity[0].media.length > 0 ? (
                                      <img
                                        src={require("../../../../images/baseline-visibility-24px.png")}
                                        alt="edit"
                                        style={{
                                          width: 22,
                                          height: 24,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          props.actions.selectedMedia(tempData);
                                        }}
                                      />
                                    ) : undefined}
                                  </div>
                                </div>

                                <div
                                  className="activityRightPart"
                                  style={
                                    getSeasonSuitabilityData(
                                      tempData.seasons,
                                      selectedSeasonList.key
                                    ).length > 0
                                      ? getSeasonSuitabilityData(
                                          tempData.seasons,
                                          selectedSeasonList.key
                                        )[0].season_suitability == false
                                        ? { opacity: 0.5 }
                                        : {}
                                      : { opacity: 0.5 }
                                  }
                                >
                                  {nextGrowStage > totalWeeks
                                    ? getSeasonTimeline(
                                        monthMapTimeline,
                                        tempData,
                                        selectedSeasonList.key,
                                        "b"
                                      )
                                    : getSeasonTimeline(
                                        weeks,
                                        tempData,
                                        selectedSeasonList.key,
                                        "a"
                                      )}
                                </div>
                              </div>
                            )
                          );
                        })}
                      {
                        <Modal
                          isOpen={modalIsOpen}
                          onRequestClose={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            closeModal();
                          }}
                          style={customStyles}
                        >
                          <h4>
                            Do you really want to delete{" "}
                            {selectedCropActivity.activity_name} ?
                          </h4>
                          <div className="modalBox">
                            <div className="leftModal">
                              <div
                                className="modalButton"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  props.actions.deleteActivityMedia(
                                    selectedCropActivity.tempData,
                                    props.selected_crop_id
                                  );
                                }}
                              >
                                Yes
                              </div>
                            </div>

                            <div className="rightModal">
                              <div
                                className="modalButton"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  closeModal();
                                }}
                              >
                                No
                              </div>
                            </div>
                          </div>
                        </Modal>
                      }
                    </div>
                  </div>
                );
              }
            }
          }
        })
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang.language,
    selected_crop_detail: state.crop.selected_crop_detail,
    generic_category: state.activity.generic_category,
    selected_crop_id: state.crop.selected_crop_id,
    crop_calendar_list: state.cropCalendar.crop_calendar_activity,
    selected_season_list: state.season.season_list,
    language_list: state.lang.language_list,
    season_list: state.season.season_list,
    organisation: state.auth.organization,
    organization_list: state.organization.organization_list,
    season_list_loading: state.season.season_list_loading,
    crop_season_list:
      0 in state.crop.selected_crop_detail
        ? state.crop.selected_crop_detail[0].crop_season
        : state.crop.selected_crop_detail,
    latitude: state.crop.latitude,
    longitude: state.crop.longitude,
  };
};
function mapDispatchtoProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        cropCalendarListRequest,
        selectedMedia,
        cropCalendarActivityEdit,
        deleteActivityMedia,
        seasonListRequest,
        setLatitudeLongitudeRequest,
      },
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchtoProps
)(CropCalendarTimeline);
