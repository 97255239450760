import React, { useState, useEffect } from "react";
import "./index.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import I18n from "../../trans/i18n";
import { languageUpdate } from "../../trans/actions";
import { logout } from "../../components/login/action";
import { Link } from "react-router-dom";
import history from "../../config/history";
import {
  offlineOrganizationRequest,
  organisationMappingList,
} from "../organization/action";
import { seasonListRequest } from "../season/action";
import { growAreaListRequest } from "../growArea/action";
import { organizationResetRequest } from "../login/action";

const Index = (props) => {
  const [showDropDown, setShowDropDown] = useState();
  const [showLanguageDropDown, setShowLanguageDropDown] = useState();

  const showMenu = (e) => {
    e.stopPropagation();
    setShowDropDown(true);
    setShowLanguageDropDown(false);
  };

  const showLanguage = () => {
    setShowLanguageDropDown(true);
    setShowDropDown(false);
  };

  useEffect(() => {
    if (Object.values(props.organization_list).length > 0) {
      let index = props.organization_list.findIndex((tmp) => {
        return tmp.organisation === props.organization;
      });

      let params = {
        organisation: props.organization,
        crop_id: "",
      };
      if (index !== -1) {
        const { latitude, longitude } = props.organization_list[index];
        if (latitude == null && longitude == null) {
          params = { ...params, ...{ latitude: -3.3731, longitude: 29.9189 } };
        } else {
          params = {
            ...params,
            ...{ latitude: latitude, longitude: longitude },
          };
        }
      } else {
        params = { ...params, ...{ latitude: -3.3731, longitude: 29.9189 } };
      }

      props.actions.seasonListRequest(params);
    }
  }, [props.actions]);

  useEffect(() => {
    props.actions.growAreaListRequest({ organisation: props.organization });
    props.actions.offlineOrganizationRequest(
      JSON.parse(localStorage.getItem("organization"))
    );
    props.actions.organisationMappingList();
  }, [props.actions]);

  const changeLanguage = (lang) => {
    localStorage.setItem("i18nextLng", lang);
    I18n.changeLanguage(localStorage.getItem("i18nextLng"));
    props.actions.languageUpdate(lang);
  };

  return (
    <div
      className="landingContainer"
      onClick={(e) => {
        e.stopPropagation();
        setShowDropDown(false);
        setShowLanguageDropDown(false);
      }}
    >
      <div className="leftContainer">
        <div className="cardContainerBody">
          <img
            style={{ width: "85%" }}
            className="landingImage"
            src={require("../../images/agricoach-bg.png")}
            alt="homeImage"
          />
          <span className="iconContainer2">{props.organization}</span>
          <img
            className="iconContainer1"
            src={require("../../images/agricoach_logo.png")}
            alt="logo"
          />
        </div>
      </div>

      <div className="rightContainer12">
        <div className="headerContainer">
          <div className="userContainer">
            <div className="usernameContainer">
              <div className="userImageContainer">
                {props.umva_photo !== null &&
                  props.umva_photo !== undefined &&
                  props.umva_photo !== "" && (
                    <img
                      src={`data:image/png;base64,${props.umva_photo}`}
                      alt="user image"
                      width="50"
                      height="50"
                      className="userImage"
                    />
                  )}
              </div>
              <span className="date">
                Welcome, {props.user_role_type}. {props.umva_id}
              </span>
            </div>
          </div>

          <div className="languageContainer">
            <img
              src={require("../../images/setting.png")}
              alt="dropdown"
              onClick={(e) => {
                showMenu(e);
              }}
              className="pointer"
            />
            {showDropDown === true && (
              <div className="toogleLangauge">
                <div className="toggleLanguage">
                  {props.user_role_type === "Superuser" && (
                    <div
                      onClick={() => {
                        history.push("/setting/organization");
                      }}
                      className="languageToogleContainer"
                    >
                      <span>&#8226; Organization</span>
                    </div>
                  )}

                  <div
                    onClick={() => {
                      history.push("/setting/assign_role");
                    }}
                    className="languageToogleContainer"
                  >
                    <span>&#8226; Role</span>
                  </div>

                  <div
                    onClick={() => {
                      props.actions.organizationResetRequest();
                    }}
                    className="languageToogleContainer"
                  >
                    <span>&#8226; Reset</span>
                  </div>

                  <div
                    className="languageToogleContainer"
                    onClick={() => {
                      props.actions.logout();
                    }}
                  >
                    <span>&#8226; Logout</span>
                  </div>
                </div>
              </div>
            )}

            <span className="selectedLangauge">{"EN"}</span>

            <img
              src={require("../../images/dropDownDown.png")}
              alt="dropdown"
              onClick={(e) => {
                showLanguage(e);
              }}
              className="pointer"
            />
            {showLanguageDropDown === true && (
              <div className="toogleLangauge">
                <div
                  className="toggleLanguage"
                  style={
                    props.lang === "en" ? { backgroundColor: "#E0E0E0" } : {}
                  }
                >
                  <div
                    onClick={() => {
                      changeLanguage("en");
                    }}
                    className="languageToogleContainer"
                  >
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          flex: 8,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        English
                      </div>
                      <div
                        style={{
                          flex: 2,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        EN
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="titleContainer"></div>

        <div className="menuContainer">
          <Link className="cardContainer" to="/crop">
            <div className="cardContainerBody">
              <img
                src={require("../../images/crop_background.png")}
                alt="background"
              />
              <img
                className="iconContainer"
                src={require("../../images/crop_icon.png")}
                alt="background_crop"
              />
            </div>

            <div className="cardContainerName">
              <span>{"Crops"}</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/season">
            <div className="cardContainerBody">
              <img
                src={require("../../images/crop_background.png")}
                alt="background"
              />
              <img
                className="iconContainer"
                src={require("../../images/icon_weather.png")}
                alt="background_crop"
              />
            </div>

            <div className="cardContainerName">
              <span>{"Seasons"}</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/grow_area">
            <div>
              <img
                src={require("../../images/grow_area_icon.png")}
                alt="background"
              />
            </div>

            <div className="cardContainerName">
              <span>Grow Area</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/activity_names_and_categories">
            <div>
              <img
                src={require("../../images/activity_icon.png")}
                alt="background_crop"
              />
            </div>

            <div className="cardContainerName">
              <span>Activity names and Categories</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/scale_value">
            <div>
              <img
                src={require("../../images/scale_value.png")}
                alt="background"
              />
            </div>

            <div className="cardContainerName">
              <span>Scale Value</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/guide">
            <div style={{ position: "relative" }}>
              <img src={require("../../images/crop_background.png")} />
            </div>
            <div style={{ position: "absolute", marginTop: -20 }}>
              <img src={require("../../images/usage.png")} alt="background" />
            </div>

            <div className="cardContainerName">
              <span>Guide</span>
            </div>
          </Link>

          <Link className="cardContainer" to="/message">
            <div style={{ position: "relative" }}>
              <img src={require("../../images/crop_background.png")} />
            </div>
            <div style={{ position: "absolute", marginTop: -20 }}>
              <img src={require("../../images/message.png")} alt="background" />
            </div>

            <div className="cardContainerName">
              <span>Message</span>
            </div>
          </Link>

          {
            //props.user_role_type === "Superuser" &&
            <Link className="cardContainer" to="/report/weather">
              <div style={{ position: "relative" }}>
                <img src={require("../../images/crop_background.png")} />
              </div>
              <div style={{ position: "absolute", marginTop: -20 }}>
                <img
                  src={require("../../images/report.png")}
                  alt="background"
                />
              </div>

              <div className="cardContainerName">
                <span>Report</span>
              </div>
            </Link>
          }
        </div>

        <div className="bottomContainer"></div>
      </div>

      <div className="emptyContainer"></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.lang.language,
    umva_id: state.auth.umva_id,
    user_role_type: state.auth.user_role_type,
    umva_photo: state.auth.umva_photo,
    organization: state.auth.organization,
    organization_list: state.organization.organization_list,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        growAreaListRequest,
        seasonListRequest,
        languageUpdate,
        logout,
        offlineOrganizationRequest,
        organisationMappingList,
        organizationResetRequest,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
