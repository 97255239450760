const filterIntersectionData = (intersection_data) => {
  let tmp = [];
  intersection_data.forEach((x, i) => {
    let first_char = x.charAt(0);
    let index = tmp.findIndex((y) => y.key == first_char);
    if (index == -1)
      tmp.push({ key: first_char, count: 1, index: [i], name: x });
    else {
      tmp[index].count += 1;
      tmp[index].index.push(i);
    }
  });
  if (tmp.length > 0) {
    let season_lists = [];
    tmp.forEach((x) => season_lists.push(x.name));
    return season_lists;
  } else return tmp;
};

export default filterIntersectionData;
